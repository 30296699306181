import React, { ButtonHTMLAttributes } from "react";

import { useTracking } from "@origin-digital/ods-core";
import styled from "styled-components";

import { CloseIcon } from "../Svg/Close";
import { Menu as MenuIcon } from "../Svg/MenuIcon";

export const StyledCloseIcon = styled(CloseIcon)`
  fill: ${(p) => p.theme.colors.primary};
`;

const IconButton = styled.button`
  all: unset;

  /*
  ** removes 4px padding from bottom of icons
  ** https://stackoverflow.com/questions/8600393/there-is-a-4px-gap-below-canvas-video-audio-elements-in-html5
  */
  svg {
    display: block;
  }

  &:focus,
  &:focus-visible {
    outline: revert;
  }

  &:hover {
    cursor: pointer;
  }
`;

type TrackedIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  trackingLabel: string;
  "data-id": string;
};

export const TrackedIconButton = ({
  onClick,
  children,
  trackingLabel,
  "data-id": dataId,
  ...rest
}: TrackedIconButtonProps): JSX.Element => {
  const { onClickCapture, ref } = useTracking({
    children: trackingLabel,
    "data-id": dataId,
    type: "Button",
  });

  return (
    <IconButton
      {...rest}
      aria-label={trackingLabel}
      onClick={(e) => {
        onClickCapture?.();
        onClick(e);
      }}
      ref={ref}
      data-id={dataId}
    >
      {children}
    </IconButton>
  );
};

export const Hamburger = styled(TrackedIconButton)`
  margin: auto 24px auto 0;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
  svg {
    fill: ${(p) => p.theme.colors.grey500};
    width: 32px;
    height: 32px;
  }
`;

export const TrackedCloseButtonWrapper = styled(TrackedIconButton)`
  margin: auto 24px auto 0px;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

export const HamburgerButton = (props) => (
  <Hamburger {...props}>
    <MenuIcon />
  </Hamburger>
);

export const CloseButton = (props) => (
  <TrackedCloseButtonWrapper {...props}>
    <StyledCloseIcon />
  </TrackedCloseButtonWrapper>
);
