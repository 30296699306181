import {
  IActionBarUpdate,
  parseMarkdownEvent,
} from "@origin-digital/event-dispatcher";
import { ActionBarProps } from "@origin-digital/ods-action-bar";

export const getBreadcrumbs = (
  breacrumbs?: IActionBarUpdate["payload"]["breadcrumbs"]
): ActionBarProps["breadcrumbs"] =>
  breacrumbs?.map((breadcrumb) => {
    if (!breadcrumb.href) {
      return breadcrumb;
    }
    return {
      ...breadcrumb,
      ...parseMarkdownEvent(breadcrumb.href),
    };
  });
