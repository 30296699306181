import { boot } from "@origin-digital/bootloader";
import { configFetch } from "@origin-digital/daxi";
import { initHandler } from "@origin-digital/mesh";
import { initWebChat } from "@origin-digital/origin-chat";
import { initBraze } from "@origin-digital/braze";
import { initGeoTargeting } from "@origin-digital/geo-targeting";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { isNative as verifyIsNative } from "@origin-digital/platform-helpers";

const environment = window.oetal.env.name as EnvironmentNames;
const isSSR = window.oetal.ssr.onServer;
const {
  allowedScopes = [],
  authenticated = false,
  enableChat,
} = window.oetal.pageData;

const disableAuthCheck = ["local", "dev"].includes(environment);
const isNative = verifyIsNative();

let path = "";
const parameters: Record<string, string> = {};
if (typeof window !== "undefined") {
  path = window.location.pathname;
  if (typeof URLSearchParams !== "undefined") {
    new URLSearchParams(window.location.search).forEach(
      (value, key) => (parameters[key] = value)
    );
  }
}

// init mesh here
initHandler({
  isNative,
  environment,
  parameters,
});

configFetch({
  environment: environment as any,
  disableAuthCheck,
});

if (enableChat) {
  initWebChat();
}

initGeoTargeting();

initBraze({
  environment,
  path,
  parameters,
});

boot({
  isNative,
  authenticated,
  isSSR,
  path,
  parameters,
  environment,
  allowedScopes,
});
