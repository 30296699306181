/**
 * operations performed on the mcApp object
 * mcApp obj should always be the first parameter
 *
 */

import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType } from "./mcApp.types";

export const getIntent = (mcApp: IMcAppType): string => {
  return mcApp.conditions && mcApp.conditions.intent
    ? mcApp.conditions.intent
    : mcApp.name;
};

/**
 * return true if the customerType is authenticated
 * @param mcApp
 */
export const isAuthenticated = (mcApp: IMcAppType): boolean => {
  const customerType = mcApp?.conditions?.customerType;
  return !!customerType && customerType !== CustomerType.unauthenticated;
};
