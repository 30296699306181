import React from "react";
import styled from "styled-components";
import {
  AlertFooter,
  Button,
  Heading,
  Stack,
  Text,
} from "@origin-digital/ods-core";

interface NativeAppAlertFooterViewProps {
  open: boolean;
  buttonText: string;
  onClose: () => void;
  onCtaClick: () => void;
  onCtaClickCapture: () => void;
}

export const Stars = styled.div`
  color: #ffb432;
  font-size: 20px;
`;

export const NativeAppAlertFooterView = ({
  open,
  buttonText,
  onClose,
  onCtaClick,
  onCtaClickCapture,
}: NativeAppAlertFooterViewProps) => (
  <AlertFooter open={open} onClose={onClose}>
    <Stack space={["medium", "large"]}>
      <Stars>★★★★★</Stars>
      <Heading variant="h4">Nice work! Did you find that easy?</Heading>
      <Text>If you can spare a moment, we’d love a review.</Text>
      <Button
        data-id="native-app-review"
        size="small"
        onClickCapture={onCtaClickCapture}
        onClick={onCtaClick}
      >
        {buttonText}
      </Button>
    </Stack>
  </AlertFooter>
);
