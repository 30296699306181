import { TOPICS, eventListener } from "@origin-digital/event-dispatcher";
import { logger } from "@origin-digital/reporting-client";
import { Channel } from "@origin-digital/platform-enums";

export interface IAppDiagnostics {
  /**
   * Name of the app diagnostic event/metric, which should be descriptive enough to
   * indicate the nature of the diagnostic event.
   */
  name: string;

  /**
   * An optional prop representing the timestamp of the diagnostic event occurred.
   * if not provided it will default to `performance.now()` in seconds
   */
  timeSinceLoad?: number;

  /**
   * An optional prop representing the time taken for the event to complete or the duration of a specific process
   */
  duration?: number;

  /**
   * An optional prop to add extra information relevant to the diagnostic event.
   */
  metadata?: Record<string, string | number>;
}

export const appReadyTimeoutCheck = (
  payload: IAppDiagnostics,
  channel: Channel
) => {
  const { name, metadata } = payload;

  if (name !== "appReady") return;

  if (!window.oetal?.timings?.appReady) {
    logger.error("appReady timings hasn't been set up.");

    return;
  }

  window.oetal.timings.appReady.counter++;

  if (window.oetal.timings.appReady.timer) {
    clearTimeout(window.oetal.timings.appReady.timer);
  }

  if (window.oetal.timings.appReady.counter > 1) {
    logger.error(
      `appReady fired more than once: ${
        window.oetal.timings.appReady.counter
      }. Channel: ${channel}. Metadata: ${JSON.stringify(metadata)}`
    );
  }
};

export const emitAppDiagnostics = (
  payload: IAppDiagnostics,
  channel: Channel
): void => {
  const isNewRelicLoaded = (): boolean => {
    return (
      typeof window.newrelic !== "undefined" &&
      typeof window.newrelic.noticeError === "function" &&
      typeof window.newrelic.addPageAction === "function"
    );
  };

  if (isNewRelicLoaded()) {
    const { name, metadata, ...payloadRest } = payload;

    appReadyTimeoutCheck(payload, channel);

    window.newrelic!.addPageAction!(name, {
      ...payloadRest,
      ...metadata,
      ...(!payloadRest.timeSinceLoad && {
        timeSinceLoad: performance.now() / 1000,
      }),
      channel,
    });
  }
};

export const setupMetricsHandlers = () => {
  eventListener.addListener(TOPICS.APP_DIAGNOSTICS, (event) => {
    emitAppDiagnostics(event.payload, event.channel);
  });
};
