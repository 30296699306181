import {
  changeUser,
  initialize,
  logCustomEvent,
  openSession,
  subscribeToContentCardsUpdates,
} from "@braze/web-sdk";

import {
  fetchGeolocation,
  IContentCards,
  IContentCardsResult,
} from "@origin-digital/event-dispatcher";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { logger } from "@origin-digital/reporting-client";

import { IBrazeUser } from "./consts/interfaces";
import { backgroundRefresh, fetchContentCards } from "./contentCard/card";
import { inAppMessageInit } from "./inAppMessages";
import { fetchMarketingId, sendBrazeEvents } from "./utils/";
import {
  BRAZE_ID_KEY,
  getApiKey,
  INAPP_ZINDEX,
  SDK_BASE_URL,
} from "./utils/config";
import { subscribeToSegments } from "./featureFlag";
import { isNewBrazeUser, mergeBrazeUsers } from "./utils/user";
import { initCohortCookie } from "./utils/cookie";
import { checkContentCardsLimit } from "./contentCard/cardUtils";

export interface IBrazeProps {
  environment: EnvironmentNames;
  /**
   * pathname from window.location called in setup
   */
  path: string;
  /**
   * current query string as a parameters object
   */
  parameters?: Record<string, string>;
}

let onBrazeInit: Promise<boolean> | undefined;

export const onBrazeInitFn = ({
  environment,
  path,
  parameters,
}: IBrazeProps) => {
  onBrazeInit = new Promise<boolean>((resolve) => {
    initCohortCookie(environment);

    // a new User has nothing in the Storage (cookies/localStorage)
    const newBrazeUser = isNewBrazeUser();

    fetchMarketingId(environment, parameters?.[BRAZE_ID_KEY] ?? "")
      .then(async (brazeObj: IBrazeUser) => {
        // initialize/set user
        initialize(getApiKey(environment), {
          baseUrl: SDK_BASE_URL,
          enableLogging: environment !== "prod",
          doNotLoadFontAwesome: true,
          inAppMessageZIndex: INAPP_ZINDEX,
        });

        // In-App Messages
        inAppMessageInit(environment, path);

        // DO NOT REMOVE
        // this callback is required for cleaning up cached cards
        subscribeToContentCardsUpdates(({ cards }) => {
          logger.debug("[braze] subscribeToContentCardsUpdates", cards);
          checkContentCardsLimit(cards);
        });

        if (brazeObj.bzid == null) {
          resolve(false);
          return;
        }

        changeUser(brazeObj.bzid);

        // Set segment cookie based on feature flags
        subscribeToSegments(environment);

        openSession();
        fetchGeolocation({}); // this would also write the fetched geolocation to the cache

        // fire events/view card
        sendBrazeEvents(logCustomEvent);

        resolve(true);

        // only refresh if an existing user - a Braze newUser session will refresh cards
        if (!newBrazeUser) {
          backgroundRefresh();
        }

        // merges previous user with current user
        mergeBrazeUsers(brazeObj);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

let initUser: boolean | null = null;
export const contentCard = (
  payload: IContentCards["payload"],
  useCached?: boolean
): Promise<IContentCardsResult> => {
  if (initUser === true) {
    return fetchContentCards(payload, useCached);
  } else if (initUser === false) {
    return Promise.resolve({ cards: [], unknownUser: true });
  } else {
    // create promise for after Braze has initialized
    return new Promise<IContentCardsResult>((resolve) => {
      if (onBrazeInit == null)
        throw Error("[braze]: onBrazeInit must be initialised");
      onBrazeInit.then((success) => {
        initUser = success;
        return resolve(contentCard(payload, useCached));
      });
    });
  }
};

// export for testing
export const contentCardTimeout = ({
  payload,
}: {
  payload: IContentCards["payload"];
}): IContentCards["result"] => {
  const EXISTING_USERS_DEFAULT_TIMEOUT_30s = 1000 * 30;
  const contentCardTimeOutFn = (timeout: number) =>
    new Promise<IContentCardsResult>((resolve) => {
      setTimeout(
        () =>
          resolve(
            initUser === null
              ? { cards: [], timeout: true } // if we aren't initalized return empty array[]
              : contentCard(payload, true).then((result) => ({
                  ...result,
                  timeout: true,
                }))
          ),
        payload.timeout ?? timeout
      );
    });
  return Promise.race<IContentCardsResult>([
    contentCard(payload),
    contentCardTimeOutFn(EXISTING_USERS_DEFAULT_TIMEOUT_30s),
  ]);
};

// export for testing
export const __resetForTesting = () => {
  onBrazeInit = undefined;
  initUser = null;
};
