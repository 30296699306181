import {
  fetchChatUserHash,
  IAlertBanner,
  IAsyncMessengerClose,
  IAsyncMessengerOpen,
  IAuthUser,
  IAuthUserResult,
  IChatMessengerHide,
  IChatMessengerShow,
  ICloseModal,
  IDeviceWalletOpen,
  IDeviceWalletSupported,
  IFetchAccessToken,
  IHttpReqError,
  ILinkEvAccount,
  INavClose,
  INavHref,
  INavHrefTitle,
  INavTo,
  INavToUrl,
  IntentName,
  IOpenResource,
  IPlatformParameters,
  IShowModal,
  IShowToast,
  ISurveyInit,
  MCAPPS,
  NavCompleteTopics,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { getAppRoute, IAppRoute } from "@origin-digital/mcapp-registry";
import {
  defaultNativeFeatures,
  FEATURES,
  fetchNativeFeatures,
  INativeFeatures,
  postMessage,
  postMessageWithResponse,
} from "@origin-digital/native-features";
import {
  Channel,
  CustomerType,
  EnvironmentNames,
  NavFlow,
} from "@origin-digital/platform-enums";
import { guid } from "@origin-digital/platform-helpers";
import { logger } from "@origin-digital/reporting-client";

import { IShowModalCta } from "@origin-digital/event-dispatcher/src/topics.types";
import content from "../content.json";
import {
  IPlatformHandlerProps,
  PlatformHandler,
  SubscribeTopics,
} from "../mesh.types";
import {
  appendURLHost,
  browserNav,
  getOdinCallbackUri,
  historyBack,
  isVersionGreaterThan,
  onPopStateCallback,
  onStorageChangeCallback,
  removePopStateCallback,
  replaceQueryParam,
  showAlertBanner,
} from "./helpers";
import {
  attachOnPopStateCallback,
  recordNavComplete,
} from "./navigationStateHandler";
import { fetchPlatformParameters } from "./platformParameters";
import {
  addNavToPromise,
  deleteNavToPromise,
  getNavToPromise,
  getNavToReferralPath,
  updateNavToPromise,
} from "./storage";

type IFeatures = Partial<INativeFeatures["features"]>;
type IAppInfo = INativeFeatures["appInfo"];
const VERSION_SUPPORTED_ABOVE = "2.6.9";

export class NativePlatformHandler implements PlatformHandler {
  private environment: EnvironmentNames;
  private navHrefDepWarn = false;
  private features: IFeatures = {};
  private appInfo?: IAppInfo;
  private navToPromiseMap: Partial<
    // eslint-disable-next-line @typescript-eslint/ban-types
    Record<IntentName, { resolve: Function; reject: Function }>
  > = {};
  private initalised: boolean = false;
  private eventQueue: {
    event: SubscribeTopics;
    resolve: (value: unknown) => void;
    reject: (error: Error) => void;
  }[] = [];

  public constructor({ environment }: IPlatformHandlerProps) {
    this.environment = environment;
    this.listener = this.listener.bind(this);
    this.fetchAppRoute = this.fetchAppRoute.bind(this);
    this[TOPICS.FETCH_ACCESS_TOKEN] =
      this[TOPICS.FETCH_ACCESS_TOKEN].bind(this);
    this[TOPICS.FETCH_PLATFORM_PARAMETERS] =
      this[TOPICS.FETCH_PLATFORM_PARAMETERS].bind(this);
    this[TOPICS.SURVEY_INIT] = this[TOPICS.SURVEY_INIT].bind(this);
    this[TOPICS.SURVEY_CLOSE] = this[TOPICS.SURVEY_CLOSE].bind(this);

    attachOnPopStateCallback(() => this[TOPICS.NAV_CLOSE]());
    this.initialize();
  }

  public initialize(): void {
    const nativeFeaturesCbTimer = setTimeout(() => {
      const msg = "[od/mesh] initialize() failed to fetchNativeFeatures()";
      logger.error(msg);
      // rejects queued promise
      this.eventQueue.forEach(({ reject }) => reject(new Error(msg)));
      this.eventQueue = [];
    }, 5000);
    fetchNativeFeatures()
      .then(({ features, appInfo }: INativeFeatures): void => {
        this.initializeComplete(features, appInfo);
        this.checkAppVersion(appInfo);
        clearTimeout(nativeFeaturesCbTimer);
      })
      .catch((error: any): void => {
        logger.error(
          "[od/mesh] initialize() failed fetchNativeFeatures()",
          error
        );
        clearTimeout(nativeFeaturesCbTimer);
      });
  }

  private initializeComplete(features: IFeatures, appInfo?: IAppInfo): void {
    this.features = features;
    this.appInfo = appInfo;
    this.initalised = true;
    this.eventQueue.forEach(({ event, resolve }) => {
      // resolve queue promise with result
      resolve(this.listener(event));
    });
    if (this.eventQueue.length > 0) {
      logger.debug("[mesh] initializeComplete");
    }
    this.eventQueue = [];
  }

  public checkAppVersion(appInfo: Partial<IAppInfo>): void {
    if (
      appInfo &&
      appInfo.version &&
      appInfo.version.match(/\d+.\d+.\d+.\d+/g)
    ) {
      if (!isVersionGreaterThan(appInfo.version, VERSION_SUPPORTED_ABOVE)) {
        let appStore = "App Store or Google Play Store";
        const { platform } = appInfo;
        if (platform && platform.toUpperCase() === "IOS") {
          appStore = "App Store";
        }
        if (platform && platform.toUpperCase() === "ANDROID") {
          appStore = "Google Play Store";
        }

        postMessage({
          action: FEATURES.showModal,
          data: {
            title: "Update now to keep using the Origin app",
            body: `And it’s worth it! We can’t stop adding the features you’ve been asking for. Download the newest version at the ${appStore}. \nFor free!`,
          },
        });
      }
    }
  }

  public __setFeaturesForTesting(
    features: IFeatures,
    appInfo?: IAppInfo
  ): void {
    this.initializeComplete(features, appInfo);
  }

  public __getNavToPromiseMapForTesting(): any {
    return this.navToPromiseMap;
  }

  public listener(event: SubscribeTopics): any {
    if (this.initalised) {
      return this[event.topic](event.payload as any);
    } else {
      const promise = new Promise((resolve, reject) => {
        this.eventQueue.push({ event, resolve, reject });
        logger.debug("[mesh] event queued, until initalised", { event });
      });
      return promise;
    }
  }

  public [TOPICS.FETCH_ACCESS_TOKEN](
    payload: IFetchAccessToken["payload"]
  ): IFetchAccessToken["result"] {
    return postMessageWithResponse<{ accessToken: string }>({
      action: FEATURES.fetchAccessToken,
      data: { environment: this.environment, ...payload, messageId: guid() },
    });
  }

  public [TOPICS.AUTH_USER](
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _payload: IAuthUser["payload"]
  ): IAuthUserResult["result"] {
    return Promise.resolve({ isAuth: true });
  }

  public [TOPICS.NAV_CLOSE](payload?: INavClose["payload"]): void {
    if (this.features.navClose || this.features.navFocus) {
      //mark last NAV_TO promise as resolvable if present in local storage
      const navTo = getNavToPromise();
      if (navTo && navTo.navToKey) {
        updateNavToPromise(navTo.navToKey, {
          resolvable: true,
          dispatchNavTo: payload == null ? undefined : payload.dispatchNavTo,
        });
      } else if (payload?.dispatchNavTo) {
        // add navToWithPromise to nav_to for mcApp
        // https://bitbucket.origin.com.au/projects/OD/repos/origin-platform/browse/packages/event-dispatcher/src/navigation.ts#98
        logger.error(
          "[mesh] navClose() cannot dispatch navTo missing navToPromise. Add navToWithPromise to fix mcApp",
          { dispatchNavTo: payload.dispatchNavTo }
        );
      }
      if (this.features.navClose) {
        postMessage({ action: FEATURES.navClose, data: {} });
      } else {
        postMessage({ action: FEATURES.navFocus, data: { show: false } });
      }
    } else {
      // user does not have navClose / navFocus
      const result = getNavToReferralPath();
      if (result) {
        // clean up
        deleteNavToPromise(result.navToKey);
      }
      if (payload && payload.dispatchNavTo) {
        // go to next best action
        this[TOPICS.NAV_TO](payload.dispatchNavTo);
      } else if (result && result.referrerPath) {
        // go back to where we came from
        browserNav(result.referrerPath);
      } else {
        // if nothing is defined
        this[TOPICS.NAV_TO]({
          to: MCAPPS.home,
        });
      }
    }
  }

  public [TOPICS.NAV_COMPLETE](payload: NavCompleteTopics["payload"]): void {
    const { from, result } = payload;
    if (!from) {
      logger.warn(
        "[od/mesh]: deprecated please update navigationComplete() to navComplete[...]()"
      );
    }
    recordNavComplete();
    updateNavToPromise(from, {
      resolvable: false,
      navStatus: "success",
      result,
    });
  }

  public [TOPICS.NAV_TO_URL](payload: INavToUrl["payload"]): void {
    const { url, odinReturnToUri, externalBrowser, authenticated } = payload;
    const fullUrl = appendURLHost(url);
    if (this.features.navExternal) {
      let urlWithReturnTo: string = fullUrl;
      if (this.appInfo && odinReturnToUri) {
        const odinCallbackUri = getOdinCallbackUri(
          this.appInfo,
          this.environment
        );
        urlWithReturnTo = replaceQueryParam(
          fullUrl,
          {
            returnTo: odinCallbackUri,
          },
          true
        );
      }
      postMessage({
        action: FEATURES.navExternal,
        data: { url: urlWithReturnTo, auth: authenticated, externalBrowser },
      });
    } else {
      logger.warn(
        "[od/mesh] nav external is not supported: browserNav has occurred in native",
        { url }
      );
      browserNav(url);
    }
  }

  public [TOPICS.NAV_BACK](): void {
    if (this.features.navBack && !this.features.navClose) {
      // Odin 2.7.0 needs this code path
      postMessage({ action: FEATURES.navBack });
    } else {
      let didPop = false;
      const cbNow = new Date().getTime();
      const cbFn = (): void => {
        didPop = true; // allows us to see what the average delay time is for popstates
        logger.info(`[mesh] onPopState cb delay`, {
          delay: new Date().getTime() - cbNow,
        });
      };
      onPopStateCallback(cbFn);
      historyBack();
      // We have given the browser some time to have to change state
      // if the callback hasn't been called
      // there was no back history and we should send NAV_CLOSE
      setTimeout(() => {
        removePopStateCallback(cbFn);
        if (!didPop) this[TOPICS.NAV_CLOSE]();
      }, 250);
    }
  }

  // Only used for focus flow and modal nav
  // resolves promise from the promise map, deletes it from promise map and local storage
  private resolveNavToPromise(appName: IntentName): boolean {
    let promiseComplete = false;
    const navToPromise = this.navToPromiseMap[appName];
    const navToData = getNavToPromise(appName);
    if (navToPromise && navToData && navToData.resolvable === true) {
      const { navStatus, result, dispatchNavTo } = navToData;
      if (dispatchNavTo) {
        // dispatch new action and resolve origin promise
        new Promise((resolve, reject) => {
          return this[TOPICS.NAV_TO]({
            ...dispatchNavTo,
            navToWithPromise: { resolve, reject },
          });
        })
          .then((r) => navToPromise.resolve(r))
          .catch((e) => navToPromise.reject(e));
      } else if (navStatus === "success") {
        navToPromise.resolve(result);
        promiseComplete = true;
      } else {
        navToPromise.reject(new Error("NAV_TO cancelled"));
        promiseComplete = true;
      }
      //delete from promise map and local storage
      delete this.navToPromiseMap[appName];
      deleteNavToPromise(appName);
    }
    return promiseComplete || navToPromise === undefined;
  }

  public isPromiseNavFlow(navFlow: NavFlow): boolean {
    const isPromiseFlow =
      (navFlow === NavFlow.FOCUS && this.features.navFocus) ||
      (navFlow === NavFlow.MODAL && this.features.navModal);
    return !!isPromiseFlow;
  }

  private fetchAppRoute(payload: INavTo["payload"]): Promise<IAppRoute> {
    return this[TOPICS.FETCH_PLATFORM_PARAMETERS]().then((parameters) => {
      return getAppRoute(parameters, payload);
    });
  }

  public [TOPICS.FETCH_PLATFORM_PARAMETERS](): Promise<IPlatformParameters> {
    return fetchPlatformParameters(this[TOPICS.FETCH_ACCESS_TOKEN]({})).then(
      (jwtParameters) => ({ channel: Channel.NATIVE, ...jwtParameters })
    );
  }

  // TODO: Add tests once apps support navExternal
  // If nav flow feature exists and is supported, perform action and return. If nav feature does not exist,
  // log warning and browserNav to URL
  public async [TOPICS.NAV_TO](payload: INavTo["payload"]): Promise<void> {
    const { url, navFlow, parent, title } = await this.fetchAppRoute(payload);
    const fullUrl = appendURLHost(url);

    //record promise that can be resolved later on NAV_CLOSE for navFocus and navModal
    if (this.isPromiseNavFlow(navFlow)) {
      const action =
        navFlow === NavFlow.FOCUS ? FEATURES.navFocus : FEATURES.navModal;
      postMessage({ action, data: { url, title, show: true } });

      // backwards compatible for payload.parameters.resolveNavToPromise, remove Nov 01, 2019
      let { navToWithPromise } = payload;
      if (
        !navToWithPromise &&
        payload.parameters &&
        (payload.parameters as any).resolveNavToPromise
      ) {
        logger.warn(
          "[od/mesh]: deprecated please update event-dispatcher",
          payload
        );
        navToWithPromise = {
          resolve: (payload.parameters as any).resolveNavToPromise,
          reject: () => {
            logger.warn("[od/mesh]: deprecated please update event-dispatcher");
          },
        };
      }
      if (navToWithPromise) {
        const { to } = payload;
        addNavToPromise({
          navToKey: to,
          resolvable: false,
          referrerPath: window.location.href, //will be ignored in native
        });
        this.navToPromiseMap[to] = navToWithPromise;
        const unSub = onStorageChangeCallback(() => {
          const promiseComplete = this.resolveNavToPromise(to);
          if (promiseComplete) {
            unSub();
          }
        });
      }
    }

    // Nav standard is not supported in older versions of Odin. If a nav standard is requested but not supported,
    // open it in a focus flow instead if that is supported.
    // This can be removed when standard nav is better supported by Odin
    else if (navFlow === NavFlow.STANDARD && this.features.navStandard) {
      postMessage({ action: FEATURES.navStandard, data: { url, tab: parent } });
    } else if (navFlow === NavFlow.STANDARD && this.features.navFocus) {
      postMessage({
        action: FEATURES.navFocus,
        data: { url, title, show: true },
      });
    } else if (
      (navFlow === NavFlow.EXTERNAL_AUTH) ===
      this.features.navExternalAuth
    ) {
      postMessage({ action: FEATURES.navExternalAuth, data: { url: fullUrl } });
    } else if (
      (navFlow === NavFlow.EXTERNAL || navFlow === NavFlow.EXTERNAL_AUTH) &&
      this.features.navExternal
    ) {
      postMessage({ action: FEATURES.navExternal, data: { url: fullUrl } });
    } else if (navFlow === NavFlow.TAB && this.features.navTab) {
      postMessage({ action: FEATURES.navTab, data: { tab: parent } });
    } else {
      logger.warn(
        "[od/mesh] nav flow is unknown or not supported: browserNav has occurred in native",
        { url, navFlow }
      );
      browserNav(url);
    }
  }

  /** @deprecated use NAV_HREF_TITLE */
  public [TOPICS.NAV_HREF](payload: INavHref["payload"]): INavHref["result"] {
    if (!this.navHrefDepWarn) {
      logger.warn("[@od/mesh] NAV_HREF is deprecated, use NAV_HREF_TITLE", {
        payload,
      });
      this.navHrefDepWarn = true;
    }
    const { url, title } = getAppRoute(
      {
        customerType: CustomerType.kraken, // assumption
        scopedToken: false, // assumption
        channel: Channel.NATIVE,
        backends: [],
      },
      payload
    );
    return { href: url, title };
  }

  // copy of web
  public [TOPICS.NAV_HREF_TITLE](
    payload: INavHrefTitle["payload"]
  ): INavHrefTitle["result"] {
    return this.fetchAppRoute(payload).then(({ url, title }) => ({
      href: url,
      title,
    }));
  }

  public [TOPICS.HTTP_REQ_ERROR](payload: IHttpReqError["payload"]): void {
    if (this.features.httpReqError) {
      postMessage({ action: FEATURES.httpReqError, data: payload });
    } else {
      logger.warn("[od/mesh] HTTP_REQ_ERROR feature is not enabled!");
    }
  }

  public [TOPICS.OPEN_RESOURCE](payload: IOpenResource["payload"]): void {
    const { mimeType, url, disableTracking } = payload;
    const { openResource, navExternal, viewpdf } = this.features;
    if (openResource) {
      postMessage({ action: FEATURES.openResource, data: payload });
    } else if (viewpdf && mimeType == "application/pdf" && !disableTracking) {
      postMessage({ action: FEATURES.viewpdf, data: { url } });
    } else if (mimeType == "text/plain" && navExternal) {
      postMessage({ action: FEATURES.navExternal, data: { url } });
    } else {
      const bannerContent =
        content.FEATURE_NOT_SUPPORTED_BANNER as IAlertBanner["payload"];
      showAlertBanner(bannerContent);
    }
  }

  public [TOPICS.TOAST_SHOW](payload: IShowToast["payload"]): void {
    const { message } = payload;
    const { toast } = this.features;
    if (toast) {
      postMessage({ action: FEATURES.toast, data: { message } });
    } else {
      logger.warn("[od/mesh] toast feature is not enabled", { message });
    }
  }

  public [TOPICS.MODAL_OPEN](payload: IShowModal["payload"]): void {
    const { showModal } = this.features;
    if (showModal) {
      postMessageWithResponse<{ ctaClicked?: keyof typeof payload }>({
        action: FEATURES.showModal,
        data: { ...payload, messageId: guid() },
      }).then(({ ctaClicked }) => {
        if (ctaClicked) {
          if (
            payload[ctaClicked] &&
            typeof (payload[ctaClicked] as IShowModalCta).action === "function"
          ) {
            ((payload[ctaClicked] as IShowModalCta).action as () => void)();
          } else {
            logger.warn("[od/mesh] modal feature action is not a function", {
              ...payload,
            });
          }
        }

        logger.info(`[od/mesh] clicked on ${ctaClicked}`);
      });
    } else {
      logger.warn("[od/mesh] modal feature is not enabled", { ...payload });
    }
  }

  public [TOPICS.LINK_EV_ACCOUNT](
    payload: ILinkEvAccount["payload"]
  ): ILinkEvAccount["result"] | undefined {
    const { linkEvAccount } = this.features;
    if (linkEvAccount) {
      logger.info(`[od/mesh] link ev account`, payload);
      return postMessageWithResponse<{ code: string }>({
        action: FEATURES.linkEvAccount,
        data: { ...payload, messageId: guid() },
      });
    }

    logger.warn("[od/mesh] linkEvAccount feature is not enabled", payload);
    return undefined;
  }

  public [TOPICS.DEVICE_WALLET_OPEN](
    payload: IDeviceWalletOpen["payload"]
  ): IDeviceWalletOpen["result"] {
    const { deviceWalletOpen } = this.features;
    if (deviceWalletOpen) {
      return postMessageWithResponse({
        action: FEATURES.deviceWalletOpen,
        data: { ...payload, messageId: guid() },
      });
    } else {
      logger.warn("[od/mesh] device wallet feature is not enabled", {
        ...payload,
      });
      return undefined;
    }
  }

  public [TOPICS.DEVICE_WALLET_SUPPORTED](
    payload: IDeviceWalletSupported["payload"]
  ): IDeviceWalletSupported["result"] {
    const { deviceWalletSupported } = this.features;
    if (deviceWalletSupported) {
      return postMessageWithResponse({
        action: FEATURES.deviceWalletSupported,
        data: { ...payload },
      });
    } else {
      logger.warn("[od/mesh] device wallet feature is not enabled", {
        ...payload,
      });
      return Promise.resolve({ applePay: false, googlePay: false });
    }
  }

  public [TOPICS.MODAL_CLOSE](payload: ICloseModal["payload"]): void {
    const { closeModal } = this.features;
    if (closeModal) {
      postMessage({ action: FEATURES.closeModal, data: { ...payload } });
    } else {
      logger.warn("[od/mesh] modal feature is not enabled", { ...payload });
    }
  }

  public [TOPICS.SURVEY_INIT](payload: ISurveyInit["payload"]): void {
    this[TOPICS.NAV_TO]({ to: MCAPPS.survey, parameters: payload });
  }

  public [TOPICS.SURVEY_CLOSE](): void {
    this[TOPICS.NAV_CLOSE]();
  }

  public [TOPICS.CHAT_MESSENGER_SHOW](
    payload: IChatMessengerShow["payload"]
  ): void {
    const { asyncMessaging } = this.features;
    if (asyncMessaging) {
      //read userhash from storage
      fetchChatUserHash({})?.then(({ userHash }) => {
        if (userHash) {
          postMessage({
            action: FEATURES.asyncMessaging,
            data: { ...payload, userHash, show: true },
          });
        } else {
          logger.warn(
            "[od/mesh] chat is not initialised, dispatch chatInit event first",
            {
              ...payload,
            }
          );
        }
      });
    } else {
      logger.warn("[od/mesh] asyncMessaging feature is not enabled", {
        ...payload,
      });
    }
  }

  public [TOPICS.CHAT_MESSENGER_HIDE](
    payload: IChatMessengerHide["payload"]
  ): void {
    const { asyncMessaging } = this.features;
    if (asyncMessaging) {
      postMessage({
        action: FEATURES.asyncMessaging,
        data: { ...payload, show: false },
      });
    } else {
      logger.warn("[od/mesh] asyncMessaging feature is not enabled", {
        ...payload,
      });
    }
  }

  public [TOPICS.FETCH_NATIVE_FEATURES](): Promise<INativeFeatures> {
    return Promise.resolve({
      features: { ...defaultNativeFeatures, ...this.features },
      appInfo: this.appInfo,
    });
  }

  public [TOPICS.ASYNC_MESSENGER_OPEN](
    payload: IAsyncMessengerOpen["payload"]
  ): void {
    const { asyncMessaging } = this.features;
    if (asyncMessaging) {
      postMessage({
        action: FEATURES.asyncMessaging,
        data: { ...payload, show: true },
      });
    } else {
      logger.warn("[od/mesh] asyncMessaging feature is not enabled", {
        ...payload,
      });
    }
  }

  public [TOPICS.ASYNC_MESSENGER_CLOSE](
    payload: IAsyncMessengerClose["payload"]
  ): void {
    const { asyncMessaging } = this.features;
    if (asyncMessaging) {
      postMessage({
        action: FEATURES.asyncMessaging,
        data: { ...payload, show: false },
      });
    } else {
      logger.warn("[od/mesh] asyncMessaging feature is not enabled", {
        ...payload,
      });
    }
  }
}

let instance: PlatformHandler;

export const nativePlatformHandler = (
  props: IPlatformHandlerProps
): PlatformHandler => {
  if (!instance) {
    instance = new NativePlatformHandler(props);
  }
  return instance;
};
