import { IBrazeUser } from "../consts/interfaces";

const IDS_KEY = "@od/braze.ids";
const REFRESH_KEY = "@od/braze.refresh";
const COOKIE_KEY = "@od/braze.cookie.write";
const storage = (): Storage => window.localStorage;

export const readStorage = (key = IDS_KEY): IBrazeUser => {
  const json = storage().getItem(key);
  if (json) {
    return JSON.parse(json);
  }

  return { originId: undefined, bzid: undefined, created: undefined };
};

export const writeStorage = (brazeSession: IBrazeUser): void => {
  try {
    storage().setItem(IDS_KEY, JSON.stringify(brazeSession));
  } catch (error) {}
};

export const getStorageBzid = (): Pick<IBrazeUser, "bzid" | "created"> => {
  const { bzid, created } = readStorage();
  return { bzid, created };
};

export const storeBzid = (brazeObj: IBrazeUser): void => {
  // write to Storage
  const { bzid, originId, created } = brazeObj;
  writeStorage({ bzid, originId, created });
};

export const getMatchingStoredBzid = (originId: string): string | undefined => {
  const currentBrazeStorage = readStorage();

  return originId == currentBrazeStorage.originId && currentBrazeStorage.bzid
    ? currentBrazeStorage.bzid
    : undefined;
};

export const readLastCookieDate = (): Date | undefined => {
  const dateStr = storage().getItem(COOKIE_KEY);
  return dateStr ? new Date(dateStr) : undefined;
};
export const writeLastCookieDate = () =>
  storage().setItem(COOKIE_KEY, String(new Date()));

export const readLastRefreshDate = (): Date | undefined => {
  const dateStr = storage().getItem(REFRESH_KEY);
  return dateStr ? new Date(dateStr) : undefined;
};
export const writeLastRefreshDate = () =>
  storage().setItem(REFRESH_KEY, String(new Date()));

export const deleteLastRefreshDate = () => storage().removeItem(REFRESH_KEY);
