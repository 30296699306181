import {
  getCustomerBackends,
  getCustomerType,
  isScopedToken,
} from "@origin-digital/auth-utils";
import { BackendCrm, CustomerType } from "@origin-digital/platform-enums";
import {
  IFetchAccessToken,
  IPlatformParameters,
} from "@origin-digital/event-dispatcher";

export const fetchPlatformParameters = async (
  fetchAccessToken: IFetchAccessToken["result"]
): Promise<
  Pick<IPlatformParameters, "customerType" | "scopedToken" | "backends">
> => {
  let accessToken;
  let customerType = CustomerType.unauthenticated;
  let scopedToken = false;
  let backends: BackendCrm[] = [];
  if (fetchAccessToken) {
    try {
      const result = await fetchAccessToken.then();
      if (result && result.accessToken) {
        accessToken = result.accessToken;
        customerType = getCustomerType(accessToken);
        scopedToken = isScopedToken(accessToken);
        backends = getCustomerBackends(accessToken);
      }
    } catch (e) {
      // this is okay, use the defaults
    }
  }
  return {
    customerType,
    scopedToken,
    backends,
  };
};
