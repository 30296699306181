import { CustomerType } from "@origin-digital/platform-enums";
import {
  BasicJSONSchema,
  CommonParameters,
  IMcAppType,
  NavFlow,
  Tab,
} from "../mcApp.types";

const elecProductCode: BasicJSONSchema = {
  description: "electricity product code",
  type: "string",
};

const gasProductCode: BasicJSONSchema = {
  description: "gas product code",
  type: "string",
};

const journeyType: BasicJSONSchema = {
  description: "journeyType to be passed to the sales form",
  type: "string",
};

// new sales form auth renew navigation
export const salesFormAuthRenew: IMcAppType = {
  name: "salesFormAuthRenew",
  path: "/my/renew-plan",
  parameters: {
    properties: {
      journeyType,
      originAddressId: CommonParameters.originAddressId,
      elecProductCode,
      gasProductCode,
    },
  },
  description: "Auth renew sales form",
  navFlow: NavFlow.MODAL,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthRenew",
    customerType: CustomerType.kraken,
  },
};

export const salesFormSmeAuthRenew: IMcAppType = {
  name: "salesFormSmeAuthRenew",
  path: "/my/business/renew-plan",
  parameters: {
    properties: {
      journeyType,
      originAddressId: CommonParameters.originAddressId,
      elecProductCode,
      gasProductCode,
    },
  },
  description: "Auth renew sales form",
  navFlow: NavFlow.MODAL,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormSmeAuthRenew",
    customerType: CustomerType.kraken,
  },
};
