import { BackendCrm } from "@origin-digital/platform-enums";

import { getCachedJwtEntry, IAuth0Config } from "@origin-digital/origin-auth";
import { getCustomerBackends } from "@origin-digital/auth-utils";
import bannerConfig from "./configurations.json";
import { bannerState, StorageKey } from "./CrisisBanner";

export type crisisLocation = Array<BackendCrm | "UNAUTH">;

export interface crisisACF {
  crisisPosition: string;
  crisisBannerType: "warning" | "info";
  crisisLocation: string[];
  crisisMessage: string;
}

export interface CrisisBannerContent {
  id: number;
  title: {
    rendered: string;
  };
  modified: string;
  acf: crisisACF;
}

// Get API endpoint from configuration.json
const getContentApiEndpoint = () => {
  const { env } = window.oetal || {};
  const { name: envName = "local" } = env || {};
  const config = bannerConfig[envName];
  return config.wpContentApi;
};

const getCrisisBanners = async () => {
  try {
    const response = await fetchContent(getContentApiEndpoint());
    sortCrisisBanners(response);
    return response;
  } catch (error) {
    console.error("Error fetching crisis banners:");
    console.error(error);
    return [];
  }
};

// Store banner JSON in local storage
const setCrisisBannerContentInLocalStorage = (
  content: CrisisBannerContent[]
) => {
  localStorage.setItem(StorageKey, JSON.stringify(content));
};

const loadContentFromLocalStorage = (key): CrisisBannerContent[] | null => {
  const localContentStr = localStorage.getItem(key);
  if (localContentStr === "undefined") {
    localStorage.removeItem(key);
    return null;
  }
  if (!localContentStr) {
    return null;
  }
  return JSON.parse(localContentStr);
};

const loadContent = async (): Promise<CrisisBannerContent[]> => {
  const localContent = loadContentFromLocalStorage(StorageKey);
  const cmsContent = await getCrisisBanners();
  if (!localContent || !equals(localContent, cmsContent)) {
    updateLocalStorage(bannerState, { status: "open" });
    setCrisisBannerContentInLocalStorage(cmsContent);
    return cmsContent;
  }

  return localContent;
};

// deeply compare two values
const equals = (a, b) => {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();

  if (!a || !b || (typeof a !== "object" && typeof b !== "object"))
    return a === b;

  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  return keys.every((k) => equals(a[k], b[k]));
};

// Fetch banner JSON from wordpress
const doFetch = async (url, requestOptions): Promise<any> => {
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(
      `${url} failed to fetch. Status code was ${response.status} `
    );
  }
  return response.headers.get("content-length") === "0"
    ? null
    : response.json();
};

const fetchContent = async (url) =>
  doFetch(url, {
    method: "GET",
    cache: "no-cache",
  });

//sort array by acf:crisisPosition
const sortCrisisBanners = (banners: CrisisBannerContent[]) =>
  banners &&
  banners.sort((a, b) => {
    const apos = a.acf.crisisPosition === "top" ? 0 : 1;
    const bpos = b.acf.crisisPosition === "top" ? 0 : 1;
    return apos === bpos ? 0 : apos < bpos ? -1 : 1;
  });

// if previewCrisisBanner is set in the URL then preview is true
export const isCrisisBannerPreview = () => {
  const urlParams = new URLSearchParams(window?.location?.search);
  return (
    urlParams.has("previewCrisisBanner") &&
    urlParams.get("previewCrisisBanner") !== "false"
  );
};
export const hasMatchingBackend = (
  crisisLocation: string[],
  backends: string[]
) => crisisLocation.some((e) => backends.includes(e));

const updateLocalStorage = (key, value) => {
  const sessionStr = localStorage.getItem(key);
  const sessionObj = sessionStr ? JSON.parse(sessionStr) : {};
  const newSessionObj = { ...sessionObj, ...value };
  localStorage.setItem(key, JSON.stringify(newSessionObj));
};

export const getCachedJwtBackends = (auth0Config: IAuth0Config): any => {
  const cachedJwtEntry = getCachedJwtEntry(auth0Config);
  if (cachedJwtEntry) {
    const accessToken = cachedJwtEntry.body.access_token;
    return getCustomerBackends(accessToken);
  }
  return [];
};

export {
  loadContent,
  getCrisisBanners,
  getContentApiEndpoint,
  updateLocalStorage,
};
