import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const spike_KRAKEN: IMcAppType = {
  name: "spike_KRAKEN",
  path: "/my/service/spike/:flow?",
  parameters: {
    properties: {
      flow: {
        description: "Spike sign up flow type",
        type: "string",
        enum: ["join", "signup"],
      },
    },
  },
  description: "Origin Spike Sign Up",
  navFlow: NavFlow.STANDARD,
  title: "Origin Spike",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "spike",
    customerType: CustomerType.kraken,
  },
};
