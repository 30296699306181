import { logCustomEvent } from "@braze/web-sdk";

import { eventListener, TOPICS } from "@origin-digital/event-dispatcher";
import { contentCardTimeout, IBrazeProps, onBrazeInitFn } from "./init";
import { handleEventListener, fetchMarketingId } from "./utils";

export { syncBrazeSdkUserGeolocation } from "./utils";

export const initBraze = (props: IBrazeProps) => {
  window.document.body.addEventListener("analyticsEvent", (event) => {
    handleEventListener(props.environment, event, logCustomEvent);
  });

  onBrazeInitFn(props);

  eventListener.addListener(TOPICS.CONTENT_CARDS, contentCardTimeout);
  eventListener.addListener(TOPICS.FETCH_MARKETING_ID, () =>
    fetchMarketingId(props.environment, "")
  );
};
