import { CustomerType } from "@origin-digital/platform-enums";
import {
  FALLBACK_INTENT,
  FALLBACK_MCAPP_NAME,
  IMcAppType,
  NavFlow,
  Tab,
} from "../mcApp.types";

export const fallback_KRAKEN: IMcAppType = {
  name: "fallback_KRAKEN",
  path: "/my",
  description: "Fallback for kraken users",
  navFlow: NavFlow.STANDARD,
  title: "Under construction",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: FALLBACK_INTENT,
    customerType: CustomerType.kraken,
  },
  ignorePathMapping: true,
};

export const fallback_UNAUTH: IMcAppType = {
  name: FALLBACK_MCAPP_NAME,
  path: "/my-account-setup/",
  description: "Fallback for unauth users",
  navFlow: NavFlow.STANDARD,
  title: "My Account",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: FALLBACK_INTENT,
    customerType: CustomerType.unauthenticated,
  },
  ignorePathMapping: true,
};
