import { redirectIfInvalidSession } from "@origin-digital/origin-auth";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { logger } from "@origin-digital/reporting-client";
import { redirectClientSide } from "./redirectClientSide";
import { setupMetricsHandlers } from "./setupMetricsHandlers";
import { setupVerifyJwt } from "./setupVerifyJwt";

export interface IBootProps {
  environment: EnvironmentNames;
  /**
   * is running in the server, ignore user auth checks
   */
  isSSR: boolean;
  /**
   * is running in the native app
   */
  isNative: boolean;
  /**
   * if the page is for authenticated user, non-public
   */
  authenticated: boolean;
  allowedScopes: string[];
  /**
   * current path starting with /
   */
  path: string;
  /**
   * current query string as a parameters object
   */
  parameters?: Record<string, any>;
}

export const boot = async ({
  authenticated,
  isSSR,
  path,
  parameters,
  environment,
  allowedScopes,
  isNative,
}: IBootProps) => {
  try {
    // client side redirects
    if (!isSSR) await redirectClientSide(path, parameters);

    // this boot function will load the following libs using SystemJS
    //
    // - fetch import-map
    // - load bootloader
    // - load event-registry
    // - load origin-auth
    // - load mesh
    // - load metrcis handlers
    setupMetricsHandlers();

    // - load reporting-client
    // - set environment globally?

    // - load router and find mc app for given route

    // - if auth verify route
    // NOTE: authenticated should be replaced by mcapp-registry -> conditions -> customerType = [kraken, sap]
    if (!isSSR && authenticated && !isNative) {
      // This is a temp fix. This redirection should be added to the web-router
      if (path.startsWith("/my-account/")) {
        location.assign(`${window.location.origin}/my/`);
        return;
      }
      let redirect = false;

      // ensure the user has a jwt, otherwise they will be redirected to authenticate
      // NOTE: this is being done sync, but we may be able to sliently re-auth if exipred
      redirect = redirectIfInvalidSession({ environment, allowedScopes });
      if (redirect) {
        logger.debug("[bootloader]: redirectIfInvalidSession");
        return;
      }

      // verify jwt gives user access to given path
      redirect = await setupVerifyJwt({ path, parameters });

      // return not needed ... but illustrative
      // eslint-disable-next-line no-useless-return
      if (redirect) return;
    }

    // - fetch config for given route from app registry
    // - load navigation (as needed)
    // - load action bar (as needed)
    // - load mc app
  } catch (err) {
    logger.error("[@od/bootloader]: boot() failure", err);
    // do something
  }
};
