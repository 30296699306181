import styled from "styled-components";
import { TrackedLink, TrackedLinkProps } from "@origin-digital/ods-core";

// https://codepen.io/finnhvman/pen/jLXKJw
export const ButtonLink = styled(TrackedLink)<
  {
    color: string;
    bgColor: string;
    hoverColor: string;
  } & TrackedLinkProps
>`
  display: block;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  font-weight: 600;
  color: ${(p) => p.color};

  background-color: ${(p) => p.bgColor};
  transition: background-color 0.2s;

  :hover {
    background-color: ${(p) => p.hoverColor};
  }
`;

export const SquashedButtonLink = styled(ButtonLink)`
  padding: 12px 8px;
  margin: -12px -8px;
`;
