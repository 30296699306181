import { WhiteListBrazeType } from "../consts/interfaces";

interface WhiteListBrazeCache {
  initialised: boolean;
  metadata: WhiteListBrazeType;
}

export const WHITELIST_BRAZE_METADATA: WhiteListBrazeType = {
  events: {
    interaction: [],
    task: [],
    navigation: [],
    navigationCard: [],
  },
};

const WHITELIST_BRAZE_METADATA_CACHE: WhiteListBrazeCache = {
  initialised: false,
  metadata: WHITELIST_BRAZE_METADATA,
};

export const getPrefetchedWhitelistBrazeMetadata = (): WhiteListBrazeType => {
  if (!WHITELIST_BRAZE_METADATA_CACHE.initialised) {
    const prefetchContent =
      // @ts-ignore: property 'oetal' does not exist on type Window.
      window.oetal.pageData?.prefetchContent?.tal_braze_whitelist;
    const contents =
      prefetchContent?.ok &&
      prefetchContent.content &&
      JSON.parse(prefetchContent.content);
    if (contents) {
      const { events } = WHITELIST_BRAZE_METADATA_CACHE.metadata;
      Object.keys(events).forEach((e) => {
        events[e as keyof typeof events] =
          // @ts-ignore: parameter 'c' implicitly has an 'any' type.
          contents.find((c) => c.title.match(`/events/${e}$`))?.json_content ||
          [];
      });
      WHITELIST_BRAZE_METADATA_CACHE.initialised = true;
    }
  }

  return WHITELIST_BRAZE_METADATA_CACHE.metadata;
};

/**
 * Strictly for the purposes of testing and clearing the cache in between tests.
 */
export const resetPrefetchedWhitelistBrazeMetadata = (): void => {
  WHITELIST_BRAZE_METADATA_CACHE.initialised = false;
  WHITELIST_BRAZE_METADATA_CACHE.metadata.events.interaction.length = 0;
  WHITELIST_BRAZE_METADATA_CACHE.metadata.events.navigation.length = 0;
  WHITELIST_BRAZE_METADATA_CACHE.metadata.events.navigationCard.length = 0;
  WHITELIST_BRAZE_METADATA_CACHE.metadata.events.task.length = 0;
};
