import { compile, match, parse } from "path-to-regexp";
import { logger } from "@origin-digital/reporting-client";
import { IPlatformParameters, INavTo } from "@origin-digital/event-dispatcher";
import { CustomerType, NavFlow, Tab } from "@origin-digital/platform-enums";
import { concessions } from "./mcApps/concessions";
import { contactUs } from "./mcApps/contactUs";
import { paymentMake } from "./mcApps/paymentMake";
import { paymentHistory } from "./mcApps/paymentHistory";
import * as mcApps from "./mcApps";

import {
  EXISTS_STRING,
  FALLBACK_INTENT,
  FALLBACK_MCAPP_NAME,
  IMcAppType,
  McAppListMap,
  McAppName,
  Params,
  Primitives,
} from "./mcApp.types";
import { findMcAppByPath } from "./intents";
import { getIntent } from "./mcApp";
import { profile } from "./mcApps/profile";

export const globalMcAppList: IMcAppType[] = [
  ...Object.values(mcApps),
  concessions,
  ...contactUs,
  ...paymentHistory,
  ...paymentMake,
  ...profile,
];

/**
 * Creates a map of mcApps from a given list of mcApps.
 * Keys of the map are names of mcApps and values are mcApp objects
 * @param [appsList] = globalMcAppList
 */
export const getMcAppMap = (
  appsList: IMcAppType[] = globalMcAppList
): Record<McAppName, IMcAppType> =>
  Object.values(appsList).reduce((obj, mcApp) => {
    if (!mcApp || !mcApp.name) {
      logger.error(`[@od/mcapp-reg] mcApp not defined`, mcApp);
      return obj;
    }
    if (obj[mcApp.name]) {
      logger.error(`[@od/mcapp-reg] name already exits: ${mcApp.name}`, [
        mcApp,
        obj[mcApp.name],
      ]);
    }
    obj[mcApp.name] = mcApp;
    return obj;
  }, {} as McAppListMap);

/**
 * Creates a map of intents from a given list of mcApps
 * Keys of the map are names of intents and values are mcApps that satisfy them
 * If a mcApp doesn't have an intent, its name will be considered as the name of
 * its intent
 * @param [mcAppList] = globalMcAppList
 */
// export for testing
export const getIntentMap = (
  mcAppList: IMcAppType[] = globalMcAppList
): Record<string, McAppName[]> => {
  return Object.entries(getMcAppMap(mcAppList)).reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (obj, [_name, mcApp]) => {
      let intent: string | null = null;
      if (mcApp.conditions) {
        if (mcApp.conditions.intent) {
          intent = mcApp.conditions.intent;
        } else {
          logger.error(
            `[@od/mcapp-reg] intent missing from conditions: ${mcApp.name}`,
            mcApp
          );
        }
      }
      if (intent == null && obj[mcApp.name]) {
        logger.error(
          `[@od/mcapp-reg] intent named already in intentMap, needs conditions: ${mcApp.name}`,
          mcApp
        );
      }
      if (intent == null) {
        intent = mcApp.name;
      }
      if (obj[intent] == null) {
        obj[intent] = [];
      }
      obj[intent].push(mcApp.name);
      return obj;
    },
    {} as Record<string, McAppName[]>
  );
};

/**
 * creates a uri encoded query string from key value pairs
 *
 * @param pairs - key value pair
 * @param append - boolean if we are appending "&" or not "?"
 */
export const queryString = (
  pairs: Record<string, Primitives | Primitives[]>,
  append: boolean = false
): string => {
  const q = Object.keys(pairs).reduce<string>((str: string, key: string) => {
    const value = pairs[key];
    if (value == null) return str;
    const param = `${str.length > 0 ? "&" : ""}${encodeURIComponent(
      key
    )}=${encodeURIComponent(Array.isArray(value) ? value.join(",") : value)}`;
    return `${str}${param}`;
  }, "");

  return q.length === 0 ? "" : append ? `&${q}` : `?${q}`;
};

export const findConditionalMcAppName = (
  intent: string,
  _platform: IPlatformParameters,
  parameters: Params,
  mcAppList?: IMcAppType[]
): McAppName | undefined => {
  const intentfulApps = getIntentMap(mcAppList)[intent];
  if (!intentfulApps) {
    throw new Error(
      `[@od/mcapp-reg] cannot find intent: '${intent}' in intentMap`
    );
  }

  const platform = { ..._platform };

  const mcAppsMap = getMcAppMap(mcAppList);
  const mcAppName = intentfulApps.find((myName): boolean => {
    const myApp: IMcAppType = mcAppsMap[myName];
    const { conditions } = myApp;
    // if there are no conditions then it passed
    if (!conditions) return true;

    // for the platform to be satisfied the conditions must either be:
    //  - not set, or
    //  - match exactly to the platform
    const platformSatisfied = Object.entries(platform).every(
      ([key, value]) =>
        conditions[key as keyof typeof conditions] == null ||
        conditions[key as keyof typeof conditions] === value
    );
    let parametersSatisfied = true;
    if (conditions.parameters) {
      // for the parameters to be satisfied the conditions of the mc app must either be:
      //  - if null / undefined, the parameters then must be the same or false
      //  - if defined, the parameters then must then match exactly
      parametersSatisfied = Object.entries(conditions.parameters || {}).every(
        // uses double == for null or undefined, false also is the same as null or undefined
        ([key, value]) =>
          value == null
            ? parameters[key] == null ||
              parameters[key] == false ||
              String(parameters[key]).toLowerCase() == "false"
            : (value === EXISTS_STRING &&
                typeof parameters[key] === "string") ||
              parameters[key] === value ||
              String(parameters[key]).toLowerCase() ===
                String(value).toLowerCase()
      );
    }
    return parametersSatisfied && platformSatisfied;
  });
  return mcAppName;
};

const getRoute = (mcApp: IMcAppType, parameters: Params) => {
  const { path } = mcApp;

  const queryParam: Record<string, Primitives | Primitives[]> = {};
  const pathParam: Record<string, Primitives | Primitives[]> = {};

  // get the token from path, /help/:category return [{name: category, ...}]
  const tokens = parse(path);

  // orginises parameters into query string and path tokens
  Object.keys(parameters).forEach((key) => {
    const hasToken = tokens.some(
      (t) => typeof t === "object" && t.name === key
    );
    if (hasToken) {
      pathParam[key] = parameters[key];
    } else {
      queryParam[key] = parameters[key];
    }
  });
  const toPath = compile(path);
  const pathWithTokens = toPath(pathParam);

  // TODO throw error if require parameter is missing form queryParam

  const query = queryString(queryParam, pathWithTokens.indexOf("?") > -1);
  return `${pathWithTokens}${query}`;
};

/**
 * the fallback is computed on the users intent
 * @param intent
 * @param platform
 * @param parameters
 */
export const getFallbackFromIntent = (
  intent: string = FALLBACK_INTENT,
  platform: IPlatformParameters,
  parameters: Params = {}
): string => {
  // when we suppport /navto endpoint we can use this logic

  // const intents = intentMap[intent];
  // if (intents) {
  //   const isAuthedIntent = intents.some((mcAppName) =>
  //     isAuthenticated(mcAppMap[mcAppName])
  //   );
  //   if (isAuthedIntent) {
  //     // fallback to /auth/callback?redirect=/navto/${intent}
  //   }
  // }
  logger.error(`[@od/mcapp-reg] mc-app not found hence falling back`, {
    data: { intent, parameters },
    referrerPath: window.location.href,
  });

  const fallbackMcAppName = findConditionalMcAppName(
    intent,
    platform,
    parameters
  );
  return fallbackMcAppName ?? FALLBACK_MCAPP_NAME;
};

export type IIntentRoute = Pick<IMcAppType, "navFlow" | "parent" | "title"> & {
  url: string;
};

export const getIntentRoute = (
  intent: string,
  platform: IPlatformParameters,
  parameters: Params = {},
  mcAppList?: IMcAppType[]
): IIntentRoute => {
  let mcAppName = findConditionalMcAppName(
    intent,
    platform,
    parameters,
    mcAppList
  );
  if (!mcAppName) {
    logger.error(
      `[@od/mcapp-reg] cannot find intent: '${intent}', conditions`,
      {
        parameters,
        platform,
        intent,
      }
    );
    mcAppName = getFallbackFromIntent(intent, platform, parameters);
  }
  const mcApp = getMcAppMap(mcAppList)[mcAppName];
  try {
    const url = getRoute(mcApp, parameters);
    const { navFlow, parent, title } = mcApp;
    return { url, navFlow, parent, title };
  } catch (error) {
    if (error instanceof Error) {
      logger.error(`[@od/mcapp-reg] cannot create route for: `, {
        intent,
        mcApp,
        parameters,
        error,
      });
    }

    // Do not use the mcApps list passed in as the fallbackMcApps are configured
    // inside the global list
    const fallbackMcAppName = getFallbackFromIntent(
      FALLBACK_INTENT,
      platform,
      parameters
    );
    const { navFlow, parent, title, path } = getMcAppMap()[fallbackMcAppName];
    return { navFlow, parent, title, url: path };
  }
};

export interface IAppRoute {
  url: string;
  navFlow: NavFlow;
  parent: Tab;
  title: string;
}
/**
 * translates old contract to new contract
 * @param platform
 * @param payload
 */
export const getAppRoute = (
  {
    channel,
    customerType = CustomerType.unauthenticated,
    scopedToken,
    backends,
  }: IPlatformParameters,
  payload: INavTo["payload"]
): IAppRoute => {
  const { to: intentName, parameters = {} } = payload;
  // remove parameters that will not form the query string
  // these will be provided by the platform in the future
  const { isUnauthenticated, ...rest } = parameters as Record<string, any>;

  // allows the customer type to be overridden
  // isUnAuthCustomer is true if isUnauthenticated is overridden otherwise honour the passed in value.
  const isUnAuthCustomer =
    isUnauthenticated ?? customerType == CustomerType.unauthenticated;
  if (isUnAuthCustomer) {
    customerType = CustomerType.unauthenticated;
  } else {
    customerType = CustomerType.kraken;
  }

  const platform: IPlatformParameters = {
    channel, // translates types
    customerType,
    scopedToken,
    backends,
  };
  return getIntentRoute(intentName, platform, rest) as IAppRoute;
};

export const extractPathBasedURLParametersFromMcApp = (
  appPath: string,
  incomingPath: string
): Record<string, unknown> => {
  const matchFunction = match(appPath);
  const matchResult = matchFunction(incomingPath);
  if (matchResult) {
    return { ...matchResult.params };
  } else {
    return {};
  }
};

/**
 * Given a path, ensure the customer is on the correct mc-app.
 *
 * If the path is for an unknown mc-app, ignore
 * If the mc-app matches, done
 *
 *
 * Fallback rules
 *   - if origin path is auth
 *
 *
 * @param path
 * @param platform
 * @param parameters
 * @param routes
 */
export const verifyIntentPath = (
  path: string,
  platform: IPlatformParameters,
  parameters: Record<string, any> = {},
  routes?: IMcAppType[]
): string | true | undefined => {
  const mcAppList = routes ?? globalMcAppList;
  const mcAppByPath = findMcAppByPath(mcAppList, path);
  if (!mcAppByPath) {
    logger.warn(`[@od/mcapp-reg] cannot find mcAppByPath for ${path}`);
    return undefined; // cannot find the intent, nothing we can do
  }
  const intent = getIntent(mcAppByPath);
  const pathBasedUrlParameters = extractPathBasedURLParametersFromMcApp(
    mcAppByPath.path,
    path
  );

  const mcAppName = findConditionalMcAppName(
    intent,
    platform,
    { ...parameters, ...pathBasedUrlParameters },
    routes
  );
  if (mcAppName) {
    if (mcAppByPath.name === mcAppName) {
      return true; // we are on the correct path
    } else {
      return intent; // another mc-app satisfies the conditions
    }
  } else {
    logger.error(`[@od/mcapp-reg] mc-app not found hence falling back`, {
      data: { intent, parameters, path },
      referrerPath: window.location.href,
    });
    return FALLBACK_INTENT;
  }
};
