import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const evIChargeUsage: IMcAppType = {
  name: "evIChargeUsage",
  path: "/my/service/ev-icharge/:chargeLocationId/usage",
  parameters: {
    required: ["chargeLocationId"],
    properties: { vehicleId: CommonParameters.chargeLocationId },
  },
  description: "Power Up - Usage page",
  navFlow: NavFlow.STANDARD,
  title: "EV Power Up Usage",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evIChargeUsage",
  },
};
