import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const evIChargeChargingTarget_KRAKEN: IMcAppType = {
  name: "evIChargeChargingTarget_KRAKEN",
  path: "/my/service/ev-icharge/:vehicleId/targets",
  parameters: {
    required: ["vehicleId"],
    properties: { vehicleId: CommonParameters.vehicleId },
  },
  description: "EV iCharge Charging Target",
  navFlow: NavFlow.STANDARD,
  title: "Target charge level and time",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evIChargeChargingTarget",
    customerType: CustomerType.kraken,
  },
};
