import { ErrorFetch, IXhrRequest, xhrFetch } from "@origin-digital/daxi";
import { deleteCookie } from "@origin-digital/origin-auth";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { getCookie } from "@origin-digital/platform-helpers";
import { logger } from "@origin-digital/reporting-client";

import { pushUserToOptimizely, SegmentType } from "@origin-digital/optimizely";
import { IBrazeUser } from "../consts/interfaces";
import {
  BRAZE_ID_COOKIE,
  BRAZE_ID_COOKIE_CREATED,
  BRAZE_SEGMENT_COOKIE,
  COOKIE_API,
  SEGMENT,
} from "../consts/consts";
import { REFERRER_KEY } from "./config";
import { readLastCookieDate, writeLastCookieDate } from "./storage";

// eslint-disable-next-line import/extensions
import { CookieOptions } from "./cookie.type";

export const getDomain = (environment: EnvironmentNames) =>
  `.${REFERRER_KEY[environment]}`;

export const readCookies = (): { bzid?: string; created: boolean } => {
  const bzid = getCookie(BRAZE_ID_COOKIE);
  if (bzid == null) return { bzid, created: false };

  // if the created cookie exists
  const created = getCookie(BRAZE_ID_COOKIE_CREATED) != null;
  return { bzid, created };
};

const updateCookies = (
  currBzid: string,
  environment: EnvironmentNames
): boolean => {
  const created = getCookie(BRAZE_ID_COOKIE_CREATED);
  const prevBzid = getCookie(BRAZE_ID_COOKIE);

  if (created) {
    if (prevBzid == currBzid) {
      // need to update created
      return true;
    } else {
      // remove old created flag
      const domain = getDomain(environment);
      deleteCookie(BRAZE_ID_COOKIE_CREATED, { domain });
      return false;
    }
  }
  return false;
};

export const isCookieYoungerThan = (age: number, bzid: string) => {
  const prevBzid = getCookie(BRAZE_ID_COOKIE);

  if (prevBzid == bzid) {
    const lastWrite = readLastCookieDate();
    if (lastWrite != null && lastWrite.getTime() + age > Date.now()) {
      return true;
    }
  }
  return false;
};

export const writeCookies = (
  brazeStorage: IBrazeUser,
  environment: EnvironmentNames
) => {
  const { bzid, created } = brazeStorage ?? {};
  if (!bzid) return;

  const oneDay = 1000 * 60 * 60 * 24;
  if (isCookieYoungerThan(oneDay, bzid)) {
    return;
  }

  const twoYears = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 2);
  const sixMonths = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180);
  const cookieExpiration = created ? sixMonths : twoYears;

  const base = {
    domain: getDomain(environment),
    secure: environment !== "local",
  };
  const cookies: CookieOptions[] = [
    {
      name: BRAZE_ID_COOKIE,
      value: brazeStorage.bzid,
      expires: cookieExpiration,
      ...base,
    },
  ];
  const shouldUpdateCookies = updateCookies(bzid, environment);

  if (created || shouldUpdateCookies) {
    cookies.push({
      name: BRAZE_ID_COOKIE_CREATED,
      value: "true",
      expires: cookieExpiration,
      ...base,
    });
  }

  return xhrFetch(COOKIE_API, {
    method: "post",
    body: { cookies } as unknown as IXhrRequest["body"],
  })
    .then(writeLastCookieDate)
    .catch((error: ErrorFetch) => {
      const { fetchResponse } = error;
      logger.error("[braze] fetch /api/braze/cookies ", {
        cookies,
        fetchResponse,
      });
    });
};

export const updateCohortCookie = (
  segment: SegmentType,
  environment: EnvironmentNames
) => {
  const base = {
    domain: getDomain(environment),
    secure: environment !== "local",
  };
  const twoYears = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 2);
  const cohortCookie: CookieOptions = {
    name: BRAZE_SEGMENT_COOKIE,
    value: segment,
    expires: twoYears,
    ...base,
  };
  return xhrFetch(COOKIE_API, {
    method: "post",
    body: { cookies: [cohortCookie] } as unknown as IXhrRequest["body"],
  })
    .then(() => {
      writeLastCookieDate();

      // Push a customer type to Optimizely
      pushUserToOptimizely(segment);
    })
    .catch((error: ErrorFetch) => {
      const { fetchResponse } = error;
      logger.error("[braze] fetch /api/braze/cookies ", {
        cohortCookie,
        fetchResponse,
      });
    });
};

// if there are no cookies, set the default segment
export const initCohortCookie = (environment: EnvironmentNames) =>
  getCookie(BRAZE_SEGMENT_COOKIE) ||
  updateCohortCookie(SEGMENT.DEFAULT as SegmentType, environment);
