import {
  IInteractionData,
  IInteractionTypes,
  INavigationCardData,
  INavigationData,
  ITaskData,
  ITaskTypes,
} from "@origin-digital/reporting-client";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { logCustomEvent, requestImmediateDataFlush } from "@braze/web-sdk";

import { ICustomEvent } from "@origin-digital/event-dispatcher";
import {
  AnalyticsEventType,
  BrazeEventType,
  InteractionDetailType,
  InteractionType,
  NavigationCardDetailType,
  NavigationDetailType,
  NavigationType,
  TaskDetailType,
  TaskType,
} from "../consts/interfaces";
import { BASE_CONTENT_CARD_EVENT_NAME } from "../consts/consts";
import {
  EVENT_NAV_PREFIX,
  NAVIGATION_DOMAIN,
  PAC_DOMAIN_URI,
  PRODUCTION_DOMAIN_URI,
} from "./config";
import { getPrefetchedWhitelistBrazeMetadata } from "./prefetch";

export const shouldUseEventDispatch = (buttonLink: string) =>
  buttonLink && buttonLink.indexOf(EVENT_NAV_PREFIX) === 0;

// Converts event values to kebab-case
// needs to allow appNames with `:` and `-` (e.g. `rx-my-account:reward`)
export const setKebabCase = (str: string) => {
  if (!str) return "";
  return str
    .trim()
    .replace(/[^a-zA-Z0-9\s\-:]/g, "") // Remove non-alphanumeric characters except `\s`, `:` and `-`
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/([a-z])([A-Z])/g, "$1-$2") // Convert camelCase to kebab-case
    .toLowerCase();
};

// Format to return currentUri (replace domain, remove parameters, and remove trailing slash)
export const formatUri = (
  environment: EnvironmentNames,
  currentUri: string
) => {
  // remove parameters first
  const removeParametersUri = currentUri.split("?")[0];

  // slice off either *origindigital-pac.com.au or *originenergy.com.au
  // and replace with NAVIGATION_DOMAIN
  const domain =
    environment === "prod" ? PRODUCTION_DOMAIN_URI : PAC_DOMAIN_URI;

  // if currentUri start with a `/` prefix with NAVIGATION_DOMAIN otherwise replace domain
  const domainCorrectedUri = removeParametersUri.startsWith("/")
    ? NAVIGATION_DOMAIN + removeParametersUri
    : removeParametersUri.replace(
        new RegExp(`(.*)${domain}`, "g"),
        NAVIGATION_DOMAIN
      );

  // remove trailing `/`
  return domainCorrectedUri?.replace(/\/*$/, "");
};

let brazeEventsToBeSent: BrazeEventType[] = [];

// Queue up events
export const addBrazeEventsToBeSent = (event: BrazeEventType) => {
  brazeEventsToBeSent.push(event);
};

// Filter on:
// event.detail.data.appName
// event.detail.type
// event.details.data.action
// return [<app-name>::task::<type>::<action>] or undefined
export const getTaskEvents = (
  detail: {
    eventType: AnalyticsEventType;
    type: ITaskTypes;
    data: ITaskData;
  },
  whitelist: TaskType[]
): string[] | undefined => {
  const { data } = detail;

  const result = whitelist.reduce(
    (acc: string[], { appName, type, action }) => {
      if (
        appName &&
        appName === data?.appName &&
        type === detail?.type &&
        action === data?.action
      ) {
        acc.push(`${setKebabCase(appName)}::task::${type}::${action}`);
      }
      return acc;
    },
    []
  );

  return result.length > 0 ? result : undefined;
};

// Match on:
// event.detail.data.appName
// domainCorrectedUri
// return matching whitelist items or undefined
export const getNavigationRegexMatches = (
  whitelist: NavigationType[],
  data: INavigationData,
  domainCorrectedUri: string
): NavigationType[] | undefined => {
  try {
    return whitelist.filter(
      ({ appName, uri }) =>
        appName === data.appName &&
        !!uri.regex &&
        domainCorrectedUri.match(new RegExp(uri.regex))
    );
  } catch {
    return undefined;
  }
};

// Filter on:
// event.detail.data.currentUri
// event.detail.data.appName
// returns [<appName>::<type>::<currentUri>, <appName>::<type>::<regex>] or undefined
export const getNavigationEvents = (
  environment: EnvironmentNames,
  data: INavigationData | INavigationCardData,
  whitelist: NavigationType[],
  type: "navigation" | "navigationCard" = "navigation"
): string[] | undefined => {
  if (!data.currentUri) {
    return undefined;
  }

  // If Staging update to check against a Prod version of the currentUri
  const domainCorrectedCurrentUri = formatUri(environment, data.currentUri);

  const formattedFilteredLabelEvent = whitelist.reduce(
    (acc: string[], { appName, uri }) => {
      if (
        appName === data.appName &&
        uri?.label === domainCorrectedCurrentUri
      ) {
        acc.push(`${setKebabCase(appName)}::${type}::${uri.label}`);
      }
      return acc;
    },
    []
  );

  const filteredRegexEvent = getNavigationRegexMatches(
    whitelist,
    data,
    domainCorrectedCurrentUri
  );

  const formattedFilteredRegexEvent = filteredRegexEvent?.map(
    (filteredEvent) =>
      `${setKebabCase(filteredEvent.appName)}::${type}::${
        filteredEvent.uri.label
      }`
  );

  const result = [
    ...(formattedFilteredLabelEvent ?? []),
    ...(formattedFilteredRegexEvent ?? []),
  ];

  return result.length > 0 ? result : undefined;
};

// Filter on:
// event.detail.data.appName
// event.detail.type
// event.details.data.id
// [<app-name>::interaction::<type>::<id>] or undefined
export const getInteractionEvents = (
  detail: {
    eventType: AnalyticsEventType;
    interaction: IInteractionTypes;
    data: IInteractionData;
  },
  whitelist: InteractionType[]
): string[] | undefined => {
  const { data } = detail;

  const filteredEvents = whitelist.filter(
    ({ appName, componentType, id = "" }) =>
      appName === data.appName &&
      id === setKebabCase(data.id) &&
      componentType === data.componentType.toLowerCase()
  );

  if (!filteredEvents) {
    return undefined;
  }

  // loop through the filtered events and return an array of event names
  const results = filteredEvents
    .filter(
      (filteredEvent) =>
        filteredEvent !== undefined && filteredEvent.appName !== undefined
    )
    .map((filteredEvent) => {
      return `${setKebabCase(
        filteredEvent.appName ?? ""
      )}::interaction::${filteredEvent.componentType.toLowerCase()}::${setKebabCase(
        filteredEvent.id
      )}`;
    });

  return results.length > 0 ? results : undefined;
};

export const getTrackingEventNames = (
  environment: EnvironmentNames,
  detail:
    | InteractionDetailType
    | NavigationDetailType
    | NavigationCardDetailType
    | TaskDetailType
): string[] | undefined => {
  // white listed events (navigation, task, interaction)
  const getBrazeEvents = (eventType: string | undefined) => {
    const { events } = getPrefetchedWhitelistBrazeMetadata();

    switch (eventType) {
      case "interaction":
        return getInteractionEvents(
          detail as InteractionDetailType,
          events["interaction"]
        );

      case "navigation":
        return getNavigationEvents(
          environment,
          detail.data as INavigationData,
          events["navigation"],
          "navigation"
        );

      case "navigationCard":
        return getNavigationEvents(
          environment,
          detail.data as INavigationCardData,
          events["navigationCard"],
          "navigationCard"
        );

      case "task":
        return getTaskEvents(detail as TaskDetailType, events["task"]);

      default:
        return undefined;
    }
  };

  return getBrazeEvents(detail?.eventType);
};

let checkedUser = false;

export const handleEventListener = (
  environment: EnvironmentNames,
  event: any,
  _logCustomEvent: (
    brazeEventName: string,
    data: INavigationData | ITaskData
  ) => void
) => {
  const { detail } = event;
  // check if it is whitelisted
  const brazeEventNames = getTrackingEventNames(environment, detail);

  if (!brazeEventNames || brazeEventNames?.length === 0) {
    return;
  }
  const { data } = detail;

  // confirmed user account and auth?
  // for each event, send to braze
  brazeEventNames.forEach((brazeEventName: string) => {
    if (checkedUser) {
      _logCustomEvent(brazeEventName, data);
    } else {
      addBrazeEventsToBeSent({
        brazeEventName,
        data,
      });
    }
  });
};

export const sendBrazeEvents = (
  _logCustomEvent: (
    brazeEventName: string,
    data: INavigationData | ITaskData
  ) => void
) => {
  checkedUser = true;
  brazeEventsToBeSent.forEach((event: BrazeEventType) => {
    const { brazeEventName, data } = event;
    _logCustomEvent(brazeEventName, data);
  });

  if (brazeEventsToBeSent.length > 0) {
    requestImmediateDataFlush();
    brazeEventsToBeSent = []; // clear queue
  }
};

export const logContentCardCustomEvent = ({ name, data }: ICustomEvent) => {
  const formattedEventName = setKebabCase(name);
  logCustomEvent(`${BASE_CONTENT_CARD_EVENT_NAME}${formattedEventName}`, data);
};
