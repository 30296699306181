import React from "react";

import { Section, Text, TextLink } from "@origin-digital/ods-core";
import {
  Chevron,
  DesktopBreadcrumbs,
  MobileBreadcrumb,
  BreadcrumbType,
} from "./styles";

type BreadcrumbsContent = BreadcrumbType[];

type Props = {
  data: BreadcrumbsContent;
};

export const getBreadcrumbsContent = (prefetchContent) => {
  const breadCrumbs: BreadcrumbsContent | undefined =
    prefetchContent.tal_breadcrumbs_nav?.ok &&
    prefetchContent.tal_breadcrumbs_nav.content &&
    JSON.parse(prefetchContent.tal_breadcrumbs_nav.content);

  if (breadCrumbs?.length && breadCrumbs?.length < 2) return null;
  return breadCrumbs;
};

export default ({ data }: Props) => (
  <Section paddingY="none" hideGutter={[true, false]} fluidity={[true, false]}>
    {data.length > 1 && <MobileBreadcrumb {...data[data.length - 2]} />}
    <DesktopBreadcrumbs aria-label="breadcrumb" separator={<Chevron />}>
      {[{ title: "Home", link: "/" }, ...data].map((crumb, idx) => (
        <Text key={crumb.title} variant="body-small">
          {idx === data.length ? (
            crumb.title
          ) : (
            <TextLink href={crumb.link}>{crumb.title}</TextLink>
          )}
        </Text>
      ))}
    </DesktopBreadcrumbs>
  </Section>
);
