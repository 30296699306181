import { dapiFetch, IDapiRequest } from "@origin-digital/daxi";
import { chatUpdateExpiryConstant } from "./helper";
import { readStorage, writeStorage } from "./storage";

export type FetchUserHashResponse = { userHash?: string };

export type PlatformType = "IOS" | "ANDROID" | "WEB";

export const fetchUserHash = async (
  platformType: PlatformType,
  isNative: boolean
): Promise<FetchUserHashResponse> => {
  const { lastUpdatedTime, userHash } = readStorage(isNative);

  // if userHash not available or lastUpdatedTime not available
  if (!userHash || !lastUpdatedTime) {
    return getUserHashFromContactsAPI(platformType, isNative);
  }

  // if userHash available and lastUpdatedTime available and re-sync time greater than 24 hours
  if (
    userHash &&
    lastUpdatedTime &&
    checkForIntercomResyncExpiry(lastUpdatedTime)
  ) {
    return getUserHashFromContactsAPI(platformType, isNative);
  }

  return { userHash };
};

const checkForIntercomResyncExpiry = (lastUpdatedTime: number) => {
  return Date.now() - lastUpdatedTime > chatUpdateExpiryConstant;
};

const getUserHashFromContactsAPI = async (
  platformType: PlatformType,
  isNative: boolean
) => {
  const endpoint = "/api/intercom/v2/contacts";
  const payload: IDapiRequest = {
    body: { platformType },
    method: "POST",
    authorization: "jwt",
  };
  const { data } = await dapiFetch<FetchUserHashResponse>(endpoint, payload);
  //update storage
  const userHashResponse = data?.userHash;
  if (userHashResponse) {
    writeStorage(
      { userHash: userHashResponse, lastUpdatedTime: Date.now() },
      isNative
    );
  }
  return { userHash: userHashResponse };
};
