import React from "react";

import { Box, Divider } from "@origin-digital/ods-core";

import styled from "styled-components";
import { hasChildren, isActiveNavSection } from "./helpers/NavHelpers";
import { IKrakenNavLink } from "./interfaces";
import { NavLinkContainer } from "./NavLinkContainer";
import { navigationBarHeight } from "./helpers/consts";
import { getIcon } from "./Icons/icons";

const StyledNavLink = styled(Box)<{ active: boolean; bold?: boolean }>(
  ({ theme: { palette }, active, bold }) => ({
    margin: 0,
    color: palette.grey["500"],
    fontSize: 14,
    lineHeight: "24px",
    display: "inline-flex",
    cursor: "pointer",
    ...(active && {
      fontWeight: 600,
    }),
    ...(bold && {
      fontWeight: 600,
    }),
  })
);

const StyledIndicator = styled(Box)(({ theme: { palette } }) => ({
  width: "64px",
  lineHeight: "64px",
  display: "block",
  marginLeft: "auto",
  textAlign: "center",
  borderLeft: `solid 1px ${palette.grey["200"]}`,
  height: "100%",
  fontSize: 16,
  color: palette.primary.main,
}));

const StyledLinkContainer = styled(Box)<{ autoHeight: boolean }>(
  ({ autoHeight }) => ({
    display: "flex",
    minHeight: navigationBarHeight,
    paddingLeft: 0,
    paddingRight: 0,
    cursor: "pointer",
    ...(autoHeight && {
      minHeight: "auto",
    }),
  })
);
const StyledIconContainer = styled(Box)({
  display: "flex",
  position: "relative",
  pointerEvents: "none",
  "& svg": {
    fontSize: 24,
  },
  '&& [class*="MuiSvgIcon-root"]': {
    height: 38,
    margin: "0px 10px 0px 0px !important",
  },
});
const StyledBadge = styled("div")(({ theme: { palette } }) => ({
  minWidth: 6,
  minHeight: 6,
  borderRadius: 6,
  border: `2px solid ${palette.common.white}`,
  background: palette.primary.main,
  position: "absolute",
  top: 7,
  right: 8,
  boxSizing: "unset",
}));

export enum NavLinkIndentation {
  "None" = 0,
  "Parent" = 16,
  "Child" = 50,
}

interface INavLink extends IKrakenNavLink {
  mobile?: boolean;
  indent?: NavLinkIndentation;
  isOpen?: boolean;
}

export const NavLinkBase = ({
  badge,
  indent,
  isOpen,
  headlineLink,
  linkConfig,
  mobile,
}: INavLink) => {
  const { href, icon, title, divider, name, bold } = linkConfig;
  const active = isActiveNavSection(href);

  return (
    <>
      {divider && !mobile && (
        <Box marginBottom="medium" data-id={`${name}-divider`}>
          <Divider />
        </Box>
      )}

      <StyledLinkContainer
        autoHeight={!headlineLink && !hasChildren(linkConfig) && !mobile}
      >
        <NavLinkContainer indent={indent} href={href} id={`${name}-link`}>
          <StyledIconContainer data-id={`${name}-icon`}>
            {getIcon(icon)}
            {badge && <StyledBadge data-id={`${name}-badge`} />}
          </StyledIconContainer>
          <StyledNavLink active={active} bold={bold} data-id={`${name}-title`}>
            {title}
          </StyledNavLink>
          {mobile && hasChildren(linkConfig) ? (
            <StyledIndicator data-id="open-indicator" aria-hidden="true">
              {isOpen ? "-" : "+"}
            </StyledIndicator>
          ) : null}
        </NavLinkContainer>
      </StyledLinkContainer>
    </>
  );
};
