//TODO: fix these disabled rules
/* eslint-disable babel/no-unused-expressions */
import { logger } from "@origin-digital/reporting-client";
import { isNative } from "@origin-digital/platform-helpers";
import { FeatureList } from "./features.type";

// Supports both >5.0.0 and <5.0.0 of react-native-webview
// If window.ReactNativeWebView exists, use new version of postMessage
export const postMessage = (message: FeatureList): void => {
  if (!isNative()) {
    const msg =
      "[@od/natives-features]: postMessage is not supported outside of not in native";
    logger.debug(msg, message);
  }

  const messageString = JSON.stringify(message);
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  window.ReactNativeWebView && window.ReactNativeWebView.postMessage
    ? window.ReactNativeWebView.postMessage(messageString)
    : window.postMessage
    ? window.postMessage(messageString, window.location.origin)
    : logger.error("postMessage() unavailable", { message });
};

export const postMessageWithResponse = <T = any>(
  message: FeatureList
): Promise<T> => {
  logger.debug("[od/mesh].postMessageWithResponse message", { message });
  return new Promise<T>((resolve, reject) => {
    try {
      //process message event
      const messageEventHandler = (event: MessageEvent): void => {
        if (event.data) {
          let response;
          if (typeof event.data === "string") {
            response = JSON.parse(event.data);
          } else {
            response = event.data;
          }
          resolve(response);
        } else {
          reject(
            new Error(
              "[@od/natives-features] postMessageWithResponse: no event data"
            )
          );
        }
        document.removeEventListener(
          message.data.messageId,
          messageEventHandler as EventListener
        );
      };
      //add message event listener
      document.addEventListener(
        message.data.messageId,
        messageEventHandler as EventListener
      );
      //request app info
      postMessage(message);
    } catch (error) {
      logger.error("[@od/natives-features] postMessageWithResponse:", error);
      reject(
        new Error(`[@od/natives-features] postMessageWithResponse: ${error}`)
      );
    }
  });
};
