import React from "react";
import { useNavigation } from "@origin-digital/navigation-client-sdk";
import styled from "styled-components";
import { NavLink } from "../NavLink";
import { filterChangePlanNavItem } from "../helpers/tempChangePlanFilter";
import { INavTest } from "../interfaces";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: ${(p) => p.theme.space.xxlarge}px;
  @media (min-width: ${(p) => p.theme.breakpoints.values.xl}px) {
    gap: ${(p) => p.theme.space.xxxlarge}px;
  }
`;

export const DesktopNav = ({ isTestExp }: INavTest) => {
  const { data, loading } = useNavigation();

  // MAR-513 update
  const navData = data?.children
    ? filterChangePlanNavItem(data.children, isTestExp)
    : [];

  if (loading) {
    return null;
  }
  return (
    <Container>
      {navData?.map((navLink) => (
        <NavLink key={navLink.name} linkConfig={navLink} headlineLink />
      ))}
    </Container>
  );
};
