import { dapiFetch } from "@origin-digital/daxi";
import { errorForm } from "@origin-digital/reporting-client";

interface InvitationResponse {
  url: string;
  id: string;
}

export const invitationApi = "/api/reviews/v1/invitations";
export const defaultTrustPilotUrl =
  "https://au.trustpilot.com/evaluate/www.originenergy.com.au";

const sendErrorAnalytics = (statusCode: number) => {
  errorForm({
    formName: "trustpilot-review",
    errorMessage: "Invitation API failed",
    errorCode: `invitation-api-failed-${statusCode}`,
  });
};

export const getInvitationUrl = async (journey = "") => {
  try {
    const response = await dapiFetch<InvitationResponse>(invitationApi, {
      body: { journey },
      method: "POST",
      authorization: "jwt",
    });
    if (response.data?.url) {
      return response.data.url;
    }
    sendErrorAnalytics(response.status);
  } catch (e) {
    // @ts-expect-error
    sendErrorAnalytics(e?.fetchResponse?.status);
  }
  return defaultTrustPilotUrl;
};
