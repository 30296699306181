export const getEnv = (): string => {
  const host = window.location.host;
  switch (host) {
    case "www.originenergy.com.au":
    case "id.originenergy.com.au":
      return "prod";
    case "staticsite-cdn-staging.origindigital-pac.com.au":
    case "staticsite-router-staging.origindigital-pac.com.au":
    case "id.preprod.originenergy.com.au":
      return "staging";
    case "staticsite-cdn-test.origindigital-dac.com.au":
    case "staticsite-router-test.origindigital-dac.com.au":
    case "id.test.originenergy.com.au":
      return "test";
    case "staticsite-cdn-dev.origindigital-dac.com.au":
    case "staticsite-router-dev.origindigital-dac.com.au":
    case "id.dev.originenergy.com.au":
      return "dev";
    default:
      if (host.includes(".origindigital-pac.com.au")) {
        return "staging";
      } else if (host.includes(".origindigital-dac.com.au")) {
        return "test";
      } else {
        return "local";
      }
  }
};

export const windowExists = typeof window !== "undefined";

export const chatUpdateExpiryConstant = 60 * 60 * 24 * 1000;
