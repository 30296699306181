import { useContext, createContext } from "react";

export type PrefetchedContent = {
  url: string;
  ok: boolean;
  status?: number;
  content?: string;
  inlineStyleSheets?: boolean;
  EXPERIMENTAL_shadowFooter?: boolean;
};

export const PrefetchContext = createContext<Record<string, PrefetchedContent>>(
  {}
);

export const usePrefetched = (name: string): PrefetchedContent | undefined => {
  const prefetchedData = useContext(PrefetchContext);

  return prefetchedData[name];
};
