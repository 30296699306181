import {
  ControlMessage,
  FullScreenMessage,
  InAppMessage,
  ModalMessage,
  logInAppMessageClick,
  logInAppMessageImpression,
  showInAppMessage,
  subscribeToInAppMessage,
} from "@braze/web-sdk";
import { TOPICS, eventDispatcher } from "@origin-digital/event-dispatcher";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { logger } from "@origin-digital/reporting-client";

import {
  BRAZE_ID,
  DASHBOARD_PATHNAME,
  MY_ACCOUNT_ROUTE_PREFIX,
  MY_ACCOUNT_SURVEY_PATH,
  TASK_COMPLETION_SURVEY_ID,
} from "../utils/config";
import { configureButtons, overwriteButtonAction } from "./buttons";
import { overwriteMessage } from "./message";
import { brazeIAMStyle } from "./inAppMessageStyles";

export const configureMessage = (
  inAppMessage: ModalMessage | FullScreenMessage
) => {
  inAppMessage.htmlId = BRAZE_ID;

  const { buttons, extras } = inAppMessage;

  // turn off any buttons with navTo. or navClose.
  if (buttons) {
    const replacementButtons = configureButtons(buttons);
    inAppMessage.buttons = replacementButtons;
  }

  // iframed survey detection - unable to confirm type
  if (extras && extras.style === "oe-style") {
    inAppMessage.css = brazeIAMStyle;
  }
};

export const handleSurveyMessage = (inAppMessage: InAppMessage) => {
  logger.debug("[braze] task completion survey triggered");

  if (!inAppMessage.message) {
    logger.debug("[braze] task completion survey payload empty");
    return;
  }

  try {
    const values = JSON.parse(inAppMessage.message);
    const { heading, message, cta, surveyType, dialogHeight } = values;

    const onClickHandler = () => {
      logger.debug("[braze] task completion survey clicked");
      // inform Braze that the message was clicked
      logInAppMessageClick(inAppMessage);
      // fire an event to open the survey
      eventDispatcher.dispatch({
        topic: TOPICS.SURVEY_INIT,
        payload: { surveyType, dialogHeight },
      });
    };

    eventDispatcher.dispatch({
      topic: TOPICS.ALERT_FOOTER_OPEN,
      payload: {
        heading,
        message,
        cta: {
          buttonText: cta,
          onClick: onClickHandler,
        },
        onShow: () => logInAppMessageImpression(inAppMessage),
      },
    });
  } catch (error) {
    logger.error("[braze] error triggering task completion survey", error);
  }
};

export const inAppMessageSubscribe = (
  inAppMessage: InAppMessage | ControlMessage,
  environment: EnvironmentNames,
  path: string
) => {
  logger.debug("[braze] subscribeToInAppMessage", inAppMessage);

  // Control group messages should always be "shown"
  // This will log an impression and not show a visible message
  if (inAppMessage.isControl) {
    logger.debug("[braze] show in app message - control group");
    showInAppMessage(inAppMessage);
    return;
  }

  // Don't show any popups within the survey app
  if (path.startsWith(MY_ACCOUNT_SURVEY_PATH)) {
    logger.debug("[braze] message received within survey app - ignoring");
    return;
  }

  // Task completion survey can appear on any /my/ page
  if (
    (path.startsWith(MY_ACCOUNT_ROUTE_PREFIX) || environment === "local") &&
    inAppMessage.extras?.id === TASK_COMPLETION_SURVEY_ID
  ) {
    handleSurveyMessage(inAppMessage as InAppMessage);
    return;
  }

  // Restrict all other In-App Message to Dashboard (except for local dev)
  if (path === DASHBOARD_PATHNAME || environment === "local") {
    // configure before show
    configureMessage(inAppMessage as ModalMessage | FullScreenMessage);
    // braze call to show message
    showInAppMessage(inAppMessage, undefined, () => {
      overwriteButtonAction(inAppMessage as ModalMessage | FullScreenMessage);
      overwriteMessage(inAppMessage as ModalMessage | FullScreenMessage);
    });
  }
};

export const inAppMessageInit = (
  environment: EnvironmentNames,
  path: string
) => {
  subscribeToInAppMessage((inAppMessage) =>
    inAppMessageSubscribe(inAppMessage, environment, path)
  );
};
