import { logger } from "@origin-digital/reporting-client";
import { multicastEventHandler, multicastStorageHandler } from "./eventHandler";

export interface IEventHandler<T extends IOriginEvents> {
  (topic: T): any;
}

export interface ISubscription {
  (): void;
}

// COPIED from event-dispatcher, since we don't want to have a dependency
// these enums could be moved into platform-enums
// eslint-disable-next-line no-shadow
export enum Multicast {
  NONE = "NONE", // default, only the tab sending event will receivce it
  ALL_TABS = "ALL_TABS", // all tabs listening to event will recevice it, includes sending tab
  OTHER_TABS = "OTHER_TABS", // only other tabs listening to event will recevice it
}

type TOPICS = string;

export interface IOriginEvents {
  topic: TOPICS;
  payload: Record<string, any>;
  multicast?: Multicast; // default NONE
}

interface IListenerEntry {
  topic: string;
  handler: IEventHandler<any>;
}

export interface IEventRegistry {
  dispatch: (event: IOriginEvents) => void;
}
export interface IEventListener {
  addListener: (topic: TOPICS, handler: IEventHandler<any>) => ISubscription;
  addMulticastListener: (
    topic: TOPICS,
    handler: IEventHandler<any>
  ) => ISubscription;
}

let localDevMode = false;

export class EventRegistry implements IEventRegistry, IEventListener {
  private static entryIdCounter: number = 0;
  private static multicastEntryIdCounter: number = 0;

  private listeners: Record<number, IListenerEntry> = {};
  private multiCastListeners: Record<number, IListenerEntry> = {};

  /** @deprecated - only PLATFORM apps should be listening for events, DO NOT USE this otherwise */
  public addListener<T extends IOriginEvents>(
    topic: T["topic"],
    handler: IEventHandler<T>
  ): ISubscription {
    const id = EventRegistry.entryIdCounter++;
    this.listeners[id] = { topic, handler };
    return () => {
      delete this.listeners[id];
    };
  }

  /** @deprecated - only PLATFORM apps should be listening for events, DO NOT USE this otherwise */
  public addListeners<T extends IOriginEvents>(
    topics: T["topic"][],
    handler: IEventHandler<T>
  ): ISubscription {
    const ids = topics.map((topic) => {
      const id = EventRegistry.entryIdCounter++;
      this.listeners[id] = { topic, handler };
      return id;
    });
    return () => {
      ids.forEach((id) => delete this.listeners[id]);
    };
  }

  // add this to McApp to listen to multicast events, e.g STALE_DATA
  public addMulticastListener<T extends IOriginEvents>(
    topic: T["topic"],
    handler: IEventHandler<T>
  ): ISubscription {
    const id = EventRegistry.multicastEntryIdCounter++;
    const unsub = multicastStorageHandler(handler);
    this.multiCastListeners[id] = { topic, handler };
    return () => {
      delete this.multiCastListeners[id];
      unsub();
    };
  }

  public dispatch<T = any>(event: IOriginEvents): T | undefined {
    let r: T | undefined;
    let handlerFound = false;
    logger.debug("[od/event-reg] dispatch", event);
    const listeners = [
      ...Object.values(this.listeners),
      ...Object.values(this.multiCastListeners),
    ];
    listeners.forEach((val: IListenerEntry) => {
      if (event.multicast === Multicast.OTHER_TABS) return;
      const { topic } = event;
      if (val.topic === topic) {
        handlerFound = true;
        const result = val.handler(event);
        if (result !== undefined) {
          logger.debug("result", result);
          if (r === undefined) {
            r = result;
          } else {
            logger.error(
              `[od/event-reg] has more than one result: "${r}" and "${result}", event:`,
              event
            );
          }
        }
      }
    });
    let isMulticast = false;
    if (event.multicast) {
      isMulticast = multicastEventHandler(event);
    }

    if (!handlerFound && !localDevMode && !isMulticast) {
      logger.error(
        `[od/event-reg] no handlerFound found for topic ${event.topic} event: `,
        event
      );
    }
    return r;
  }

  public __clearAllListenersForTesting(): void {
    this.listeners = {};
  }
}

export const initEventRegistry = () => {
  if (typeof window === "undefined") {
    logger.error("[od/event-reg] init() window not defined");
    return;
  }
  if (!window.FRAME) {
    window.FRAME = {};
  }
  const { FRAME } = window;
  if (FRAME.eventDispatcherInstance) {
    logger.error("[od/event-reg] init() already initalized");
  } else {
    // legacy reasons we cannot change the name
    FRAME.eventDispatcherInstance = new EventRegistry();
    FRAME.eventDispatcherDebug = false;
  }
};

export const enableLocalDev = () => {
  localDevMode = true;
};
