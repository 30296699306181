import {
  fetchNativeFeatures,
  INativeFeatures,
} from "@origin-digital/event-dispatcher";
import { logger } from "@origin-digital/reporting-client";
import { fetchUserHash, FetchUserHashResponse, PlatformType } from "./userHash";

export const initNativeChat = async (): Promise<boolean> => {
  const nativeData = await fetchNativeFeatures({});
  if (nativeData?.features.asyncMessaging === true) {
    const { userHash } = await fetchUserHashForNative(nativeData.appInfo);
    return userHash !== undefined;
  } else {
    logger.warn(
      "[od/chat] initNativeChat: asyncMessaging feature is not supported in this version of the app"
    );
    return false;
  }
};

export const fetchNativePlatformType = async (
  appInfo?: INativeFeatures["appInfo"]
): Promise<PlatformType> => {
  const nativeAppInfo = appInfo ?? (await fetchNativeFeatures({}))?.appInfo;
  return nativeAppInfo?.platform.toUpperCase() as PlatformType;
};

export const fetchUserHashForNative = async (
  appInfo?: INativeFeatures["appInfo"]
): Promise<FetchUserHashResponse> => {
  const platformType = await fetchNativePlatformType(appInfo);
  return fetchUserHash(platformType, true);
};
