import { EnvironmentNames } from "@origin-digital/platform-enums";

import { auth0EnvConfigs } from "../config";
import { IAuth0Config, CachePayload } from "../interfaces";
import { getLocationOrigin, matchesOneElement } from "./utils";
import { decodeJwt } from "./jwtUtils";
import {
  getCachedJwtEntry,
  entryNotExpired,
} from "./spa-auth-client/cacheUtils";

const SCOPE_ERROR_PAGE = "/auth/#/permission/error";
const DEFAULT_ALLOWED_SCOPES = ["all", "native", "offline_access"];

export const AUTH_CALLBACK_PAGE = "/auth/callback";
export const RETURN_TO = "returnTo";

export const logoutAndRedirectToScopeError = () =>
  location.assign(
    `${getLocationOrigin()}/logout?returnTo=${encodeURIComponent(
      SCOPE_ERROR_PAGE
    )}`
  );

export const scopeStringToList = (scope: string): string[] => {
  return scope.split(/\s+/);
};

export const hasDefaultScope = (scopes: string[]): boolean => {
  return matchesOneElement(DEFAULT_ALLOWED_SCOPES, scopes);
};

export const hasScopedJwt = (auth0Config: IAuth0Config) => {
  const cachedEntry = getCachedJwtEntry(auth0Config);
  if (cachedEntry) {
    const decoded = decodeJwt(cachedEntry.body.access_token);
    if (!hasDefaultScope(scopeStringToList(decoded.payload.scope))) {
      return true;
    }
  }
  return false;
};

/**
 * If the jwt scope contains a scope from the allowedScopes
 * then return true, otherwise redirect to scope error page.
 * @param jwtScopes
 * @param allowedScopes
 */
export const checkValidScope = (
  jwtScopes: string[] = [],
  allowedScopes: string[] = []
): boolean => {
  // If they get here, that means they have a scoped jwt
  const hasManifestScope = matchesOneElement(allowedScopes, jwtScopes);

  // if the allowedScopes from the manifest does not match
  // any from the jwt
  // redirect to scope error page, logout 1st to clear
  // jwt cache (need to clear jwt, otherwise we get into
  // a scope error loop as login will use the same jwt)
  if (!hasManifestScope) {
    logoutAndRedirectToScopeError();
  }
  return true;
};

export function checkJwtSession(
  cachedJwtEntry: CachePayload,
  jwtScopes: string[],
  allowedScopes: string[]
): boolean {
  return (
    entryNotExpired(cachedJwtEntry.expiresAt) &&
    checkValidScope(jwtScopes, allowedScopes)
  );
}

/**
 * Check for valid jwt & scope
 * If the jwt is from a logged in user, then the jwt must be having default scopes
 * If the jwt is not from a logged in user (i.e. does not contain default scopes), then jwt must be scoped
 * so check it matches the allowedScopes. If jwt does not contain correct scope, then redirect to
 * scope error page. If the scopes match then return true. This case indicates that we can use the jwt alone for authentication.
 * @param auth0Config
 * @param allowedScopes
 */
export const sessionExists = (
  auth0Config: IAuth0Config,
  allowedScopes: string[]
): boolean => {
  const cachedJwtEntry = getCachedJwtEntry(auth0Config);
  if (cachedJwtEntry) {
    const accessToken = cachedJwtEntry.body.access_token;
    const decodedAccessToken = decodeJwt(accessToken);
    const jwtScopes = scopeStringToList(decodedAccessToken.payload.scope);

    if (
      !hasDefaultScope(jwtScopes) &&
      checkJwtSession(cachedJwtEntry, jwtScopes, allowedScopes)
    ) {
      return true;
    }

    return entryNotExpired(cachedJwtEntry.expiresAt);
  }
  return false;
};

/**
 * Redirects to authcallback if user doesn't have a valid
 * session, i.e. jwt (with correct scope)
 * @param environment
 * @param allowedScopes
 */
export const redirectIfInvalidSession = (props: {
  environment: EnvironmentNames;
  allowedScopes: string[];
}): boolean => {
  const auth0Config: IAuth0Config = auth0EnvConfigs[props.environment];
  if (!sessionExists(auth0Config, props.allowedScopes)) {
    sessionStorage.setItem(RETURN_TO, location.href);
    location.assign(`${getLocationOrigin()}${AUTH_CALLBACK_PAGE}`);
    return true;
  }
  return false;
};
