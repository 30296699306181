import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const paymentExtension_KRAKEN: IMcAppType = {
  name: "paymentExtension_KRAKEN",
  path: "/my/payment-extension/:accountNumber?",
  description: "Request a payment extension",
  parameters: {
    required: ["accountNumber"],
    properties: {
      accountNumber: CommonParameters.accountNumber,
    },
  },
  navFlow: NavFlow.FOCUS,
  title: "Payment extension",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentExtension",
    customerType: CustomerType.kraken,
  },
};
