import React from "react";

import { TrackedLink } from "@origin-digital/ods-core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@origin-digital/ods-types";
import styled from "styled-components";
import { Link as LinkType } from "./nav-config";

export const LinkText = styled.div`
  /* allow bottom hover/active borders to absolutely position themselves
  relative to the actual text */
  position: relative;
`;

export const Link = styled(TrackedLink)<{
  active: boolean | undefined;
  largeOnMobile: boolean | undefined;
}>`
  position: relative;
  text-decoration: none;
  color: ${(p) => p.theme.colors.grey600};
  font-size: ${(p) => (p.largeOnMobile ? "24px" : "14px")};
  ${(p) => p.theme.breakpoints.up("lg")} {
    font-size: 14px;
  }
  line-height: ${(p) => (p.largeOnMobile ? "60px" : "46px")};
  font-weight: ${(p) => (p.largeOnMobile || p.active ? 600 : 400)};
  ${(p) => p.theme.breakpoints.up("lg")} {
    font-weight: ${(p) => (p.active ? 600 : 400)};
  }
  width: fit-content;
  box-sizing: border-box;

  // icon support
  display: flex;
  gap: ${(p) => p.theme.space.xxsmall}px;
  svg {
    margin: auto 0;
  }

  ${(p) => p.theme.breakpoints.up("lg")} {
    line-height: 60px;
    :hover :after {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  :after {
    position: absolute;
    content: "";
    bottom: 0;

    border-bottom: 4px solid ${(p) => p.theme.colors.grey200};

    transition: 0.2s ease-in-out 0s;
    /* starting states for transition */
    left: 50%;
    width: 0;
    opacity: 0;

    ${(p) =>
      p.active
        ? `
        /* Active state for links */
        border-bottom: 4px solid #ffc72c;
        left: 0;
        width: 100%;
        opacity: 1
        `
        : ""}
  }
`;

type Props = {
  link: LinkType;
  tabable?: boolean;
  largeOnMobile?: boolean;
};

export const NavLink = ({ link, tabable, largeOnMobile }: Props) => {
  const isAboveLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isBelowXl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );
  const preferSmallTitles = isAboveLg && isBelowXl;

  return (
    <Link
      domProps={{ tabIndex: tabable ? undefined : -1 }}
      trackingType="Link"
      trackingLabel={link.title}
      data-id={`navigation:${link.title} `}
      key={link.title}
      href={link.link}
      active={link.selected}
      largeOnMobile={largeOnMobile}
    >
      {link.icon}
      <LinkText>
        {preferSmallTitles ? link.smallTitle || link.title : link.title}
      </LinkText>
    </Link>
  );
};
