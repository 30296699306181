export const TAG_IDS = {
  CUSTOMERS: "customers_tag",
  PROSPECTS: "prospects_tag",
  OTHERS: "others_tag",
};

export const SEGMENT = {
  PROPERTY: "segment",
  DEFAULT: "prospects",
  CUSTOMERS: "customers",
  OTHERS: "others",
};

export const BRAZE_ID_COOKIE = "bzid.id";
export const BRAZE_SEGMENT_COOKIE = "bzid.aud_cohort";

// used to remember if we created a new user in braze
export const BRAZE_ID_COOKIE_CREATED = "bzid.created";

export const COOKIE_API = "/api/braze/cookies";

export const BASE_CONTENT_CARD_EVENT_NAME = "content-card::interaction::link::";

export const MAX_CONTENT_CARDS = 100;
