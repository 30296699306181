import React from "react";
import styled from "styled-components";
import { Nav } from "./nav-config";
import { SecondLevelNav } from "./SecondLevelNav";
import { NavLink } from "./NavLinks";

export const gridAreaName = "firstLevel";
const NavBar = styled.div<{ showMenu: boolean }>`
  grid-area: ${gridAreaName};
  margin: auto 0px;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  gap: 24px;

  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: -100vw;
    right: -100vw;

    transition: background 0.3s ease-in-out;
    background: ${(p) =>
      p.showMenu ? p.theme.colors.grey50 : p.theme.colors.white};

    ${(p) => p.theme.breakpoints.up("lg")} {
      background: ${(p) => p.theme.colors.grey50};
    }
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    gap: 18px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    gap: 30px;
  }
`;

type Props = {
  links: Nav["primaryLinks"];
  showMenu: boolean;
};
export const PrimaryNav = ({ showMenu, links }: Props) => {
  let secondLevelLinks = links[0].children;
  const primaryNavBar = (
    <NavBar showMenu={showMenu}>
      {links.map((link) => {
        if (link.selected) {
          secondLevelLinks = link.children;
        }
        return <NavLink key={link.title} tabable={showMenu} link={link} />;
      })}
    </NavBar>
  );

  return (
    <>
      {primaryNavBar}
      <SecondLevelNav links={secondLevelLinks} showMenu={showMenu} />
    </>
  );
};
