import { GetTokenSilentlyOptions, Auth0Client } from "@auth0/auth0-spa-js";
import { logger } from "@origin-digital/reporting-client";
import { StoragePersistentKeys } from "@origin-digital/platform-enums";
import { Logger } from "../../helpers/Logger";
import {
  IAuth0Config,
  IReportingClientConfig,
  IOriginSpaAuthClient,
} from "../../interfaces";
import { getLocationOrigin } from "../utils";

// eslint-disable-next-line no-shadow
enum AUTH0_CLIENT_ERROR {
  INVALID_STATE = "Invalid state",
}

/**
 * Origin Single Page App Auth Client
 * This class wraps auth0's spa sdk to
 * manage authentication for Single Page Applications.
 * It handles token management, authentication etc.. for you.
 * We want to eventually move away from using OriginWebAuthClient in favor of this.
 *
 * Create this class before your app is initialised & call initialiseClient to create an instance of the
 * auth0 client. Auth0 recommends that the sdk is created only once. See: https://github.com/auth0/auth0-spa-js
 * The auth0 spa sdk is still fairly new, so it does not yet support passwordless login. Hence we can't completely switch over.
 *
 * Benefits of using auth0 spa sdk over auth0 sdk. See: https://auth0.com/blog/introducing-auth0-single-page-apps-spa-js-sdk/
 * - abstracts the developer from standards and protocols
 * - frees the developer from having to specify the grant or other protocol details, manage token expiration and renewal, or store and cache tokens
 * - follows industry and service best practices and protects developers from security pitfalls
 * - weights around 7kb minified and gzipped
 */

export class OriginSpaAuthClient implements IOriginSpaAuthClient {
  // we want to maintain only one instance of the auth0Client as recommended by auth0
  // hence its a static variable
  private static auth0Client: Auth0Client;

  // At client code start up, many things are requesting JWT info.  After it is
  // initialised, the Auth0Client will return cached information, but until the first
  // call succeeds, each and every request here causes auth0 to make an API call to
  // their backend - we get many calls per page.  In tests, this can lead the IP to be
  // blocked and auth0 responding with a 429 "too many requests".  To that end, for
  // each 'options' value, we cache the request promise for any subsequent simultaneous
  // requests with the same options, we re-use the cached 'in flight' promise.

  private static authRequestCache: Record<string, Promise<any>> = {};

  // create a string cache key from the values in an auth0 options object
  private optionsToCacheKey(options?: GetTokenSilentlyOptions): string {
    if (!options) return "nooptions";
    return Object.keys(options)
      .sort()
      .reduce((accum, key) => {
        return `${accum}${options[key]},`;
      }, "");
  }

  constructor(
    auth0Config: IAuth0Config,
    reportingClientConfig?: IReportingClientConfig
  ) {
    this.initLogger(reportingClientConfig);
    this.createAuth0Client(auth0Config);
  }

  // async call to get jwt token with default retries of 3 times
  // will throw an error if user is not authenticated
  // this will not do a redirect if the user is not authenticated
  // repeated calls will return token from memory cache
  public getJwtSilently = async (
    options?: GetTokenSilentlyOptions,
    retries = 3,
    error: Error | null = null
  ): Promise<string> => {
    const rememberMe =
      window.localStorage.getItem(StoragePersistentKeys.rememberMe) === "true";
    const customOptions = {
      [StoragePersistentKeys.rememberMe]: rememberMe,
      ...options,
    };
    if (!retries) {
      logger.error("[GetJwtSilently]: Maximum retries reached.");
      return Promise.reject(error);
    }
    try {
      const cacheKey = this.optionsToCacheKey(customOptions);
      const cachedPromise = OriginSpaAuthClient.authRequestCache[cacheKey];

      let jwt;

      if (cachedPromise) {
        jwt = await cachedPromise;
      } else {
        try {
          const promise =
            OriginSpaAuthClient.auth0Client.getTokenSilently(customOptions);
          OriginSpaAuthClient.authRequestCache[cacheKey] = promise;
          jwt = await promise;
        } finally {
          delete OriginSpaAuthClient.authRequestCache[cacheKey];
        }
      }

      return jwt;
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === AUTH0_CLIENT_ERROR.INVALID_STATE) {
          logger.warn(
            `[GetJwtSilently]: Invalid state from auth0. Retrying ${retries}.`
          );
          return this.getJwtSilently(customOptions, retries - 1, e);
        }
      }
      return Promise.reject(e);
    }
  };

  /**
   * Clears the application storage on logout
   */
  public logout = (): void => {
    // passing localOnly tells auth0 to not redirect to the logout url
    OriginSpaAuthClient.auth0Client.logout({ localOnly: true });
  };

  private createAuth0Client(auth0Config: IAuth0Config) {
    // eslint-disable-next-line no-negated-condition
    if (!OriginSpaAuthClient.auth0Client) {
      OriginSpaAuthClient.auth0Client = new Auth0Client({
        domain: auth0Config.customDomain,
        client_id: auth0Config.clientId,
        audience: auth0Config.audience,
        scope: auth0Config.scope,
        // not yet used, but required by auth0 client
        // if we need specific redirect urls later on, we can update this
        // Note: each redirect uri needs to be added to auth0 tenant otherwise
        // it will fail
        redirect_uri: `${getLocationOrigin()}/auth/callback`,
        issuer: auth0Config.customDomain,
        cacheLocation: "localstorage",
      });
    }
  }

  private initLogger(reportingClientConfig?: IReportingClientConfig) {
    if (reportingClientConfig) {
      new Logger().bootstrap(reportingClientConfig);
    }
  }
}
