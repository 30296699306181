import {
  IOriginEvents,
  TOPICS,
  eventListener,
} from "@origin-digital/event-dispatcher";
import { AlertFooter, Button, Text } from "@origin-digital/ods-core";
import React, { useEffect, useState } from "react";
import { Z_INDEX } from "../../consts/style";

export interface InAppMessage {
  message: string;
  heading?: string;
  cta?: {
    buttonText: string;
    onClick: () => void;
  };
}

const isAlertAlreadyShowing = (): boolean =>
  document.querySelectorAll(`[class*="MuiSnackbar-root"]`).length > 0;

export const InAppMessagingService = () => {
  const [open, setOpen] = useState(false);
  const [inAppMessage, setInAppMessage] = useState<InAppMessage>();

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const listener = async (topic: IOriginEvents) => {
      if (topic.topic === TOPICS.ALERT_FOOTER_OPEN) {
        if (isAlertAlreadyShowing()) {
          return;
        }

        const { heading, message, cta, onShow } = topic.payload;

        setInAppMessage({
          heading,
          message,
          cta: cta
            ? {
                buttonText: cta.buttonText,
                onClick: () => {
                  cta.onClick();
                  setOpen(false);
                },
              }
            : undefined,
        });

        setOpen(true);
        // call the onShow callback to log an impression with Braze
        if (onShow) {
          onShow();
        }
      }
    };
    const alertFooterUnsubscribe = eventListener.addListener(
      TOPICS.ALERT_FOOTER_OPEN,
      listener
    );
    return alertFooterUnsubscribe;
  }, []);

  return (
    <AlertFooter
      open={open}
      onClose={onClose}
      title={inAppMessage?.heading}
      muiProps={{ componentProps: { style: { zIndex: Z_INDEX.SURVEY } } }}
    >
      <Text>{inAppMessage?.message}</Text>
      {inAppMessage?.cta ? (
        <Button onClick={inAppMessage.cta.onClick} size="small">
          {inAppMessage.cta.buttonText}
        </Button>
      ) : null}
    </AlertFooter>
  );
};
