const fontPath =
  "https://www.originenergy.com.au/static/tal-client/fonts/gordita/";
const fontFamily = "'Gordita', Arial, sans-serif;";

/*
 * Braze styling:
 * Survey CSS
 * Bottom setting to account for Intercom on page
 */
export const brazeIAMStyle = `
  @font-face {
    font-family: Gordita;
    src: url("${fontPath}gorditamedium-webfont.woff2") format('woff2'),
      url("${fontPath}gorditamedium-webfont.woff") format('woff');
    font-weight: 600; font-style: normal; font-display: swap;
  }
  @font-face {
    font-family: Gordita;
    src: url("${fontPath}gorditaregular-webfont.woff2") format('woff2'),
      url("${fontPath}gorditaregular-webfont.woff") format('woff');
    font-weight: 500; font-style: normal; font-display: swap;
  }
  #braze-oe .bz-iam { font-family: ${fontFamily}; font-weight: 500; }
  #braze-oe .bz-background { background-color: rgba(0, 0, 0, 0.5);  }
  #braze-oe .bz-modal { background-color: white;  padding-bottom: 24px; }
  #braze-oe .bz-header { color: #232323; font-weight: 600; }
  #braze-oe .bz-btn {
    padding: 12px 24px; border-radius: 24px;
    min-width: 64px;  min-height: auto; box-sizing: border-box;
    font: 600 16px/24px ${fontFamily}; background-color: #ec0000; }
  #braze-oe .rad-sel { border: 1px solid #ffb432; }
  #braze-oe input:checked+.rad-sel { border-width: 5px; }
  #braze-oe label { color: #505050; }
  #braze-oe .bz-close-btn { top: 8px; right: 8px; border-radius: 50%; }
  #braze-oe .bz-close-btn:hover { background-color: rgba(0, 0, 0, 0.08); }
  #braze-oe .bz-close-btn path { fill: #ec0000; }
`;
