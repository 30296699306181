import React from "react";

import { StorageKeys } from "@origin-digital/platform-enums";
import { auth0EnvConfigs } from "@origin-digital/origin-auth";
import { BannerView } from "./BannerView";
import {
  getCachedJwtBackends,
  CrisisBannerContent,
  hasMatchingBackend,
  isCrisisBannerPreview,
  loadContent,
  updateLocalStorage,
} from "./helpers";

export const StorageKey = StorageKeys.crisisBanner;
export const bannerState = "bannerState";

interface IState {
  show: boolean;
  content: CrisisBannerContent[] | undefined;
}

export class CrisisBanner extends React.Component<any, IState> {
  state: IState = {
    show: false,
    content: undefined,
  };

  onCloseButtonClick = () => {
    updateLocalStorage(bannerState, { status: "closed" });
    this.setState({ show: false });
  };

  isBannerClosed = () => {
    const bannerStatus = localStorage.getItem(bannerState);
    if (bannerStatus) {
      const status = JSON.parse(bannerStatus);
      return status.status === "closed";
    }
    return false;
  };

  async componentDidMount() {
    try {
      const rawContent = await loadContent();

      const isPreview = isCrisisBannerPreview();

      if (this.isBannerClosed() && !isPreview) return;

      let content = rawContent;

      // grab the cached JWT backends.  This saves a fair bit of time over fetchPlatformParameters() as the
      // auth0 SDK is not involved and does not have to initialise and make back end calls.
      // Additionally, the auth0 SDK seems to have issues in func tests.  If the user has already
      // authenticated then the JWT will be cached.  So, pretty simple even if not perfect (like the JWT
      // could have expired), but, this is not about securing anything, just checking what messages
      // to show.
      const jwtBackEnds = getCachedJwtBackends(
        auth0EnvConfigs[window.oetal.env.name]
      );
      const backEnds =
        jwtBackEnds && jwtBackEnds.length ? jwtBackEnds : ["UNAUTH"];

      // if not previewing, filter down banner list based on backends.  If previewing,
      // show everything
      if (!isPreview) {
        content = rawContent
          .filter((item) =>
            hasMatchingBackend(item.acf.crisisLocation, backEnds)
          )
          .slice(0, 2);
      }

      const showCrisisBanner = !!content?.length;

      if (showCrisisBanner) {
        this.setState({
          show: true,
          content,
        });
      }
    } catch (error) {
      let message = "Unknown Error";
      if (error && !!error["message"]) message = error["message"];
      console.error("CrisisBanner Error:", { message });
    }
  }

  render() {
    const { show, content } = { ...this.state } as IState;
    return show && content
      ? content.map((item, index) => {
          return (
            <BannerView
              key={index}
              index={index}
              bannerId={item.id}
              type={item.acf.crisisBannerType}
              heading={item.title.rendered}
              message={item.acf.crisisMessage}
              onCloseButtonClick={this.onCloseButtonClick}
              closeBtnDataId="cbCloseBtn"
            />
          );
        })
      : null;
  }
}
