import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const selfMeterReadLanding: IMcAppType = {
  name: "selfMeterReadLanding",
  path: "/my/self-meter-read",
  description: "Self meter read landing page",
  navFlow: NavFlow.STANDARD,
  title: "Self meter read",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "selfMeterReadLanding",
    customerType: CustomerType.kraken,
  },
};

export const selfMeterReadFlow: IMcAppType = {
  name: "selfMeterReadFlow",
  path: "/my/self-meter-read/service",
  description: "Self meter read enter read flow",
  navFlow: NavFlow.FOCUS,
  title: "Submit a meter read",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "selfMeterReadFlow",
    customerType: CustomerType.kraken,
  },
};
