import {
  fetchPlatformParameters,
  navigationCloseAndNavTo,
} from "@origin-digital/event-dispatcher";
import { getIntentRoute } from "@origin-digital/mcapp-registry";
import { logger } from "@origin-digital/reporting-client";

/**
 * Redirects client side to a new path with parameters.
 * @param path - current path
 * @param parameters - query parameters
 *
 * @returns void
 *
 * @example
 * Current path:
 * `/my/make-payment/?navto=true&intent=paymentMake&accountNumber=A-012345678`
 * Result: `location.replace('/my/make-payment/A-012345678')`
 *
 * @example
 * Current path:
 * ` /my/service/spike/?navto=true&intent=spike&flow=signup`
 * Result: location.replace('/my/service/spike/signup')
 *
 * @example
 * Current path:
 * `/my/make-payment/?navto=true&intent=paymentMake&customerNumber=012345678`
 * Result: NAV_CLOSE({dispatchNavTo: {to: "paymentMake", parameters: {customerNumber: 012345678}})
 * This would result in `/my/make-lpg-payment/012345678`
 */
export const redirectClientSide = async (
  path: string,
  parameters?: Record<string, any>
) => {
  try {
    if (parameters?.navto !== "true") return;

    const platform = await fetchPlatformParameters({});
    if (!platform) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { intent, navto, ...rest } = parameters;
    const intentUrl = getIntentRoute(intent, platform, rest).url;

    if (intentUrl === path) return;

    if (intentUrl.startsWith(path)) {
      location.replace(`${location.origin}${intentUrl}`);
    } else {
      navigationCloseAndNavTo({
        to: intent,
        parameters: rest,
      });
    }
  } catch (err) {
    logger.error("[@od/bootloader]: redirect failure", err);
  }
};
