import {
  IChatInit,
  IFetchChatUserHash,
} from "@origin-digital/event-dispatcher";
import { isNative } from "@origin-digital/platform-helpers";
import { initWebChat } from "./webChatHandler";
import { fetchUserHash } from "./userHash";
import { IIntercomSettings } from "./initialize";
import { fetchUserHashForNative, initNativeChat } from "./nativeChatHandler";

//event handlers
export function initChatEventHandler({
  payload,
}: {
  payload: IIntercomSettings;
}): IChatInit["result"] {
  if (isNative()) {
    return initNativeChat().then((initialised) => ({ success: initialised }));
  } else {
    return initWebChat(payload).then(() => ({ success: true }));
  }
}

export function fetchUserHashEventHandler(): IFetchChatUserHash["result"] {
  return isNative() ? fetchUserHashForNative() : fetchUserHash("WEB", false);
}
