import { EnvironmentNames } from "@origin-digital/platform-enums";
import { IAuth0Config } from "../interfaces/index";

import { auth0ConfigLocal } from "./local";
import { auth0ConfigDev } from "./dev";
import { auth0ConfigTest } from "./test";
import { auth0ConfigStaging } from "./staging";
import { auth0ConfigProd } from "./prod";

export type Auth0EnvConfigs = {
  [key in EnvironmentNames]: IAuth0Config;
};

export const auth0EnvConfigs: Auth0EnvConfigs = {
  local: auth0ConfigLocal,
  dev: auth0ConfigDev,
  test: auth0ConfigTest,
  staging: auth0ConfigStaging,
  regress: auth0ConfigStaging,
  prod: auth0ConfigProd,
};
