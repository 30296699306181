import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const marketingGlobalOptOut: IMcAppType = {
  name: "marketGlobalOptOut",
  path: "/marketing-preferences/",
  description: "Unauthenticated Global Opt Out page",
  navFlow: NavFlow.STANDARD,
  title: "Contact Preferences",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "marketGlobalOptOut",
  },
};

export const marketingPreferences: IMcAppType = {
  name: "marketPreferences",
  path: "/my/marketing-preferences/",
  description: "Authenticated Marketing Preferences page",
  navFlow: NavFlow.STANDARD,
  title: "Marketing Preferences",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "marketPreferences",
  },
};
