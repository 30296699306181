import { logger } from "@origin-digital/reporting-client";
import { getOriginCustomerId } from "@origin-digital/auth-utils";
import {
  fetchAccessToken,
  fetchPlatformParameters,
} from "@origin-digital/event-dispatcher";

import { BackendCrm } from "@origin-digital/platform-enums";
import { fetchUserHash } from "./userHash";
import { IIntercomSettings, initIntercom } from "./initialize";

interface IUserInfo {
  userId: string;
  backends: BackendCrm[];
}

// TODO: This check will be moved to the page manifest of tal apps (P21-579)
export function shouldInitialize(): boolean {
  const platformApps = [
    "/auth/callback",
    "/logout",
    "/auth/ml",
    "/auth/sml",
    "/my-account/contexthub",
  ];
  const pathName = window.location.pathname;
  const isPlatformApp = platformApps.some((app) => app === pathName);

  if (isPlatformApp) {
    return false;
  }

  return true;
}

export async function getUserInfo(): Promise<IUserInfo | undefined> {
  try {
    const result = await fetchAccessToken({});

    if (result && result.accessToken) {
      const userId = getOriginCustomerId(result.accessToken);
      const tokenResponse = await fetchPlatformParameters({});
      const backends = tokenResponse?.backends ?? [];
      return userId ? { userId, backends } : undefined;
    } else {
      logger.debug("[od/chat] getUserInfo:: accessToken is undefined");
      return undefined;
    }
  } catch (e) {
    logger.error("[od/chat] getUserInfo failed");
    return undefined;
  }
}

export async function fetchUserHashAndHandleFetchError(): Promise<
  string | undefined
> {
  try {
    const { userHash } = await fetchUserHash("WEB", false);
    return userHash;
  } catch (e) {
    logger.error("[od/chat] fetchUserHash failed");
    return undefined;
  }
}
export async function initWebChat(settings?: IIntercomSettings): Promise<void> {
  const userInfo = await getUserInfo();
  const backends = userInfo ? userInfo.backends : [];

  if (!shouldInitialize()) {
    // Do not initialise for platform specific and kraken apps
    return;
  }

  const userHash = await fetchUserHashAndHandleFetchError();

  if (!userInfo) {
    // when unauthenticated, install settings for visitors/leads
    return initIntercom(backends, userHash, settings);
  }

  // eslint-disable-next-line no-negated-condition
  if (!userInfo) {
    // when userId or userHash is missing, install settings for visitors/leads
    return initIntercom(backends, userHash, settings);
  } else {
    // when userId and userHash are present, install settings for users
    return initIntercom(backends, userHash, {
      ...settings,
      user_id: userInfo.userId,
    });
  }
}
