import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const evIChargeAccountLinkingComplete: IMcAppType = {
  name: "evIChargeAccountLinkingComplete",
  path: "/my/service/ev-icharge/linking/callback",
  description: "Power Up - Account linking complete",
  navFlow: NavFlow.FOCUS,
  title: "Establishing connection",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evIChargeAccountLinkingComplete",
  },
};
