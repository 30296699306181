import { CustomerType } from "@origin-digital/platform-enums";
import {
  BasicJSONSchema,
  CommonParameters,
  IMcAppType,
  NavFlow,
  Tab,
} from "../mcApp.types";

const journeyType: BasicJSONSchema = {
  description: "journeyType to be passed to the sales form",
  type: "string",
};

const fuel: BasicJSONSchema = {
  description: "fuel to be passed to the sales form",
  type: "string",
};

const existingFuel: BasicJSONSchema = {
  description: "existing fuel to be passed to the sales form",
  type: "string",
};

const planFetchStrategy: BasicJSONSchema = {
  description: "planFetchStrategy to be passed to the sales form",
  type: "string",
};

// new sales form auth move navigation
export const salesFormAuthMove: IMcAppType = {
  name: "salesFormAuthMove",
  path: "/my/move-home",
  parameters: {
    properties: {
      journeyType,
      planFetchStrategy,
      fuel,
      existingFuel,
      originAddressId: CommonParameters.originAddressId,
    },
  },
  description: "Auth move sales form",
  navFlow: NavFlow.FOCUS,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthMove",
    customerType: CustomerType.kraken,
  },
};

// new sales form auth move for businessnavigation
export const salesFormAuthMoveBusiness: IMcAppType = {
  name: "salesFormAuthMoveBusiness",
  path: "/my/business/move-home",
  parameters: {
    properties: {
      journeyType,
      planFetchStrategy,
      fuel,
      existingFuel,
      originAddressId: CommonParameters.originAddressId,
    },
  },
  description: "Auth move sales form for business",
  navFlow: NavFlow.FOCUS,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthMoveBusiness",
    customerType: CustomerType.kraken,
  },
};
