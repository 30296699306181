import { INavigationItem } from "@origin-digital/navigation-client-sdk";

export const isActiveNavSection = (href?: string): boolean => {
  const pathname = global?.window?.location?.pathname;

  if (!pathname) {
    return false;
  }

  return pathname === href || pathname === `${href}/`;
};

export const hasChildren = (navLinkConfig: INavigationItem): boolean =>
  !!navLinkConfig.children && navLinkConfig.children.length > 0;
