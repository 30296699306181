import React from "react";
import { IconSearch } from "@origin-digital/ods-icons";

export type Link = {
  link: string;
  title: string;
  smallTitle?: string;
  icon?: React.ReactNode;
  selected?: boolean;
};

export type Nav = {
  primaryLinks: Array<
    Link & {
      children: Array<Link>;
    }
  >;
  secondaryLinks: Array<Link>;
};

// return a new version of the config every time so we can mutate it
export const getConfig = (): Nav => ({
  primaryLinks: [
    {
      title: "At Home",
      link: "/",
      children: [
        {
          title: "Energy",
          link: "/electricity-gas/",
        },
        {
          title: "Internet",
          link: "/internet/",
        },
        {
          title: "Solar & Batteries",
          link: "/solar/",
        },
        {
          smallTitle: "LPG",
          title: "LPG bottles",
          link: "/lpg/",
        },
        {
          smallTitle: "EVs",
          title: "Electric vehicles",
          link: "/electric-vehicles/",
        },
        {
          title: "Moving",
          link: "/moving/",
        },
      ],
    },
    {
      title: "Small Business",
      link: "/business/",
      children: [
        {
          title: "Energy",
          link: "/business/electricity-and-gas/",
        },
        {
          title: "Internet",
          link: "/internet/business-internet-plans/",
        },
        {
          title: "Solar & Batteries",
          link: "/business/solar/",
        },
        {
          smallTitle: "LPG",
          title: "LPG bottles",
          link: "/business/lpg/",
        },
        {
          smallTitle: "EVs",
          title: "Electric vehicles",
          link: "/electric-vehicles/business/",
        },
        {
          title: "Move your business",
          link: "/business/moving/",
        },
      ],
    },
    {
      title: "Enterprise",
      link: "/enterprise/",
      children: [
        {
          title: "Energy",
          link: "/enterprise/energy",
        },
        {
          title: "Lower carbon solutions",
          link: "/enterprise/explore-our-lower-carbon-solutions",
        },
        {
          title: "Solar & Batteries",
          link: "/enterprise/explore-our-lower-carbon-solutions/large-business-solar-and-storage/",
        },
        {
          title: "Electric vehicles",
          link: "/electric-vehicles/enterprise/",
        },
        {
          title: "Energy reporting",
          link: "/enterprise/reporting/",
        },
      ],
    },
  ],
  secondaryLinks: [
    {
      title: "About",
      link: "/about/",
    },
    {
      title: "Blog",
      link: "/blog/",
    },
    {
      title: "Help",
      link: "/help-support",
      icon: <IconSearch size="xsmall" color="grey300" />,
    },
  ],
});
