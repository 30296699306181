import { CustomerType } from "@origin-digital/platform-enums";
import { IFullProps, IMcAppType } from "../mcApp.types";

export const getMcAppFromBaseProps = (
  props: IFullProps,
  extraParamters?: any
): IMcAppType => {
  const obj: IMcAppType = {
    name: props.name,
    path: `${props.digishiftPath || props.magiclinkPath || props.path}${
      props.subPath ? `/${props.subPath}` : ""
    }`,
    navFlow: props.navFlow,
    title: props.title,
    parent: props.parent,
    parameters: {
      properties: extraParamters,
    },
    description: `${props.description}${
      props.digishiftPath ? ", for digi-shift" : ""
    }${props.magiclinkPath ? ", for magic link" : ""}`,
    conditions: {
      intent: props.intent,
      parameters: {
        isDigishift: props.digishiftPath ? !!props.digishiftPath : undefined,
      },
      scopedToken: !!props.magiclinkPath,
      customerType: props.customerType ?? CustomerType.kraken,
    },
    ignorePathMapping: props.ignorePathMapping,
  };
  if (props.parameters) {
    obj.parameters = {
      required: props.parameters.required,
      properties: {
        ...props.parameters.properties,
        ...extraParamters,
      },
    };
  }
  return obj;
};
