import jwtDecode from "jwt-decode";

export function decodeOriginCustomerIdFromJwt(jwt: string) {
  try {
    const subClaim = getClaimFromJwt(jwt, "sub");
    if (subClaim) {
      const subList = subClaim.split("|");
      return subList.length >= 3 ? subList[2] : null;
    }
  } catch (error) {
    console.error("unable to decode jwt", error);
  }
  return null;
}

export function getClaimFromJwt(
  jwt: string,
  claim: string
): string | undefined {
  return jwtDecode(jwt)[claim];
}

// TODO: better place to put this?
export function isLocalEnvironment() {
  return ["local", "dev"].includes(window.oetal.env.name);
}
