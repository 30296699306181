import React from "react";
import { KrakenDropdown } from "./Desktop/KrakenDropdown";
import { NavLinkBase } from "./NavLinkBase";
import { IKrakenNavLink } from "./interfaces";
import { hasChildren } from "./helpers/NavHelpers";

export const NavLink = (props: IKrakenNavLink) => {
  const { linkConfig } = props;
  const { children, name } = linkConfig;

  if (!hasChildren(linkConfig)) {
    return <NavLinkBase {...props} />;
  }

  return (
    <KrakenDropdown id={`${name}-dropdown`} dropdownList={children!}>
      <NavLinkBase {...props} />
    </KrakenDropdown>
  );
};
