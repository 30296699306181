import { IAuth0Config } from "../interfaces";

export const auth0ConfigLocal: IAuth0Config = {
  scope: "openid email read:api all",
  auth0host: "https://dev-originenergy.au.auth0.com",
  auth0Tenant: "dev-originenergy",
  clientId: "k2aUd29SC84qGj1Kw8XVRTytlp4gqQuu",
  clientPassword: "client password",
  grantType: "http://auth0.com/oauth/grant-type/password-realm",
  realm: "MyAccountUsers",
  apiPath: "https://staticsite-cdn-dev.origindigital-dac.com.au/auth/api",
  apiKey: "IT8kjMFYfIL1CBCZEara5VdkC2nxG9u9NOuu0Sm5",
  audience: "https://digitalapi",
  domain: "dev-originenergy.au.auth0.com",
  customDomain: "id.dev.originenergy.com.au",
  responseType: "token id_token",
  ruleNamespace: "https://originenergy.com.au",
  assetPath: "https://staticsite-cdn-dev.origindigital-dac.com.au/identity",
  environmentId: "dev",
  krakenApiPath: "https://api-dev.rx.originenergy.com.au",
};
