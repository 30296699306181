import React from "react";

export const LandscapeLogo = (props) => (
  <svg
    width="106"
    height="32"
    viewBox="0 0 106 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Origin Energy Logo</title>
    <path
      d="M7.74592 15.7854C7.74592 14.1921 8.21956 12.6345 9.10696 11.3096C9.99435 9.9848 11.2556 8.95222 12.7313 8.34246C14.207 7.7327 15.8308 7.57316 17.3974 7.88401C18.9639 8.19486 20.4029 8.96214 21.5324 10.0888C22.6618 11.2155 23.431 12.651 23.7426 14.2137C24.0542 15.7765 23.8942 17.3963 23.283 18.8684C22.6718 20.3405 21.6366 21.5987 20.3086 22.4839C18.9805 23.3692 17.4191 23.8417 15.8218 23.8417C14.7612 23.842 13.7109 23.6339 12.7309 23.2291C11.7509 22.8244 10.8605 22.231 10.1105 21.4828C9.36052 20.7347 8.76566 19.8464 8.35994 18.8688C7.95421 17.8913 7.74557 16.8435 7.74592 15.7854V15.7854ZM15.8218 0C12.4868 0.000459442 9.23713 1.05205 6.53687 3.00463C3.83662 4.95721 1.82382 7.71093 0.785875 10.8726C-0.252074 14.0343 -0.262106 17.4423 0.757213 20.6101C1.77653 23.7778 3.77308 26.5433 6.46179 28.5116C9.17597 26.5243 12.455 25.4527 15.8218 25.4527C19.1887 25.4527 22.4677 26.5243 25.1819 28.5116C27.8706 26.5433 29.8671 23.7778 30.8865 20.6101C31.9058 17.4423 31.8957 14.0343 30.8578 10.8726C29.8198 7.71093 27.807 4.95721 25.1068 3.00463C22.4065 1.05205 19.1569 0.000459442 15.8218 0V0Z"
      fill="url(#paint0_linear_402_3913)"
    />
    <path
      d="M45.3935 12.4071C44.7242 12.4146 44.0721 12.6196 43.5194 12.9963C42.9667 13.373 42.5383 13.9046 42.288 14.5239C42.0378 15.1432 41.9771 15.8226 42.1134 16.4764C42.2497 17.1301 42.5771 17.7289 43.0542 18.1973C43.5312 18.6657 44.1367 18.9827 44.7941 19.1082C45.4515 19.2338 46.1315 19.1624 46.7483 18.9029C47.3651 18.6435 47.891 18.2077 48.2598 17.6504C48.6286 17.0932 48.8237 16.4394 48.8205 15.7717C48.807 14.8742 48.4399 14.0181 47.7986 13.3884C47.1573 12.7588 46.2933 12.4063 45.3935 12.4071V12.4071ZM45.3935 22.2199C44.1235 22.197 42.8886 21.8006 41.8435 21.0804C40.7985 20.3602 39.9898 19.3482 39.519 18.1714C39.0482 16.9945 38.9362 15.7052 39.197 14.4651C39.4578 13.225 40.0798 12.0893 40.985 11.2004C41.8903 10.3116 43.0384 9.7091 44.2855 9.46857C45.5326 9.22804 46.8231 9.36015 47.9953 9.84834C49.1674 10.3365 50.169 11.1591 50.8743 12.2128C51.5797 13.2666 51.9576 14.5046 51.9604 15.7717C51.9555 16.6258 51.7815 17.4704 51.4484 18.2572C51.1154 19.0441 50.6299 19.7575 50.0197 20.3566C49.4095 20.9558 48.6868 21.4288 47.8929 21.7485C47.099 22.0683 46.2496 22.2285 45.3935 22.2199Z"
      fill="#FF373C"
    />
    <path
      d="M54.5557 9.58668H57.747V10.492C58.9417 9.61101 60.1094 9.35156 62.4988 9.35156V12.4081C58.4351 12.4081 57.747 13.5486 57.747 15.6701V21.8913H54.5557V9.58668Z"
      fill="#FF373C"
    />
    <path
      d="M68.0527 9.58594H64.8613V21.8824H68.0527V9.58594Z"
      fill="#FF373C"
    />
    <path d="M90.8584 9.58594H87.667V21.8824H90.8584V9.58594Z" fill="#FF373C" />
    <path
      d="M102.798 15.3076C102.798 12.9266 101.527 12.4078 100.256 12.4078C98.9859 12.4078 97.7126 12.8996 97.7126 15.2562V21.8909H94.5186V9.58633H97.7126V10.4917C98.6194 9.72662 99.7764 9.32073 100.964 9.35121C103.673 9.35121 106 10.9322 106 14.7887V21.8909H102.798V15.3076Z"
      fill="#FF373C"
    />
    <path
      d="M80.9994 25.9707C80.9015 26.8258 80.4793 27.6111 79.8195 28.1657C79.1597 28.7202 78.3123 29.0019 77.4509 28.9529C76.5896 28.904 75.7796 28.5282 75.1872 27.9026C74.5947 27.277 74.2646 26.449 74.2646 25.5883C74.2646 24.7277 74.5947 23.8997 75.1872 23.2741C75.7796 22.6484 76.5896 22.2727 77.4509 22.2237C78.3123 22.1748 79.1597 22.4565 79.8195 23.011C80.4793 23.5655 80.9015 24.3509 80.9994 25.2059C81.0168 25.3313 81.025 25.4577 81.0238 25.5843C81.0252 25.7135 81.0171 25.8427 80.9994 25.9707V25.9707ZM74.1941 15.7768C74.1827 14.8992 74.5117 14.0511 75.1124 13.4098C75.713 12.7685 76.5389 12.3835 77.4175 12.3354C78.296 12.2872 79.1592 12.5796 79.8267 13.1514C80.4942 13.7233 80.9143 14.5303 80.9994 15.4039C81.0169 15.5301 81.0251 15.6575 81.0238 15.7849C81.0252 15.9115 81.017 16.038 80.9994 16.1633C80.9014 17.0259 80.4763 17.8184 79.8114 18.3784C79.1464 18.9384 78.2919 19.2234 77.423 19.175C76.5541 19.1266 75.7367 18.7485 75.1384 18.1182C74.54 17.4878 74.206 16.653 74.2049 15.7849L74.1941 15.7768ZM84.169 15.7849V9.58805H81.0129V10.2691C81.0084 10.2686 81.0039 10.2686 80.9994 10.2691C79.969 9.66796 78.7958 9.35345 77.6021 9.35834C76.2726 9.33593 74.9687 9.72519 73.8701 10.4725C72.7714 11.2199 71.932 12.2886 71.4674 13.5315C71.0027 14.7744 70.9358 16.1304 71.2756 17.4129C71.6154 18.6954 72.3454 19.8412 73.3651 20.6927C72.6415 21.2886 72.0595 22.0371 71.6608 22.8843C71.2622 23.7316 71.0568 24.6565 71.0596 25.5924C71.0945 27.3036 71.8004 28.9329 73.0258 30.1308C74.2513 31.3286 75.8986 31.9996 77.6143 31.9996C79.33 31.9996 80.9774 31.3286 82.2028 30.1308C83.4283 28.9329 84.1342 27.3036 84.169 25.5924C84.1718 24.6565 83.9665 23.7316 83.5678 22.8843C83.1692 22.0371 82.5871 21.2886 81.8636 20.6927C82.5856 20.0938 83.1664 19.3435 83.5649 18.4953C83.9634 17.6471 84.1696 16.7217 84.169 15.7849"
      fill="#FF373C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_402_3913"
        x1="15.8218"
        y1="31.2223"
        x2="15.8218"
        y2="-1.02696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC72C" />
        <stop offset="0.99" stopColor="#FF373C" />
      </linearGradient>
    </defs>
  </svg>
);
