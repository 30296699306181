/* eslint-disable no-negated-condition */
import {
  Column,
  Columns,
  Heading,
  HeadingProps,
} from "@origin-digital/ods-core";
import React from "react";
import styled from "styled-components";
import { ActionBarMobileProps, getMobileNavButton } from "./ActionBarMobile";
import { Box } from "./Box";

const CustomHeading = ({
  className,
  children,
  component,
  "data-id": dataId,
}: HeadingProps) => (
  <Heading
    variant="h4"
    className={className}
    component={component}
    data-id={dataId}
  >
    {children}
  </Heading>
);

const StyledCustomHeading = styled(CustomHeading)`
  && {
    font-size: 14px;
  }
`;

const RowContainer = styled(Box)`
  height: 44px;
`;

const ButtonContainer = styled(Box)`
  width: 54px;
  margin-left: -2px;
  margin-right: 2px;
`;

export const NavTitle = ({
  mobileNavButton,
  onClickMobileNavButton,
  headingComponent,
  title,
  desktopVariant,
  isDesktop,
  showPropertySelector,
  propertySelector,
}: ActionBarMobileProps) => {
  return (
    <Box backgroundColor="white">
      <Columns space="none" alignY="center">
        {!isDesktop || desktopVariant === "slim" ? (
          <>
            <Column width={!isDesktop ? "content" : "flex"}>
              <RowContainer display="flex" alignItems="center">
                <ButtonContainer display="flex">
                  {getMobileNavButton(
                    mobileNavButton,
                    onClickMobileNavButton,
                    isDesktop,
                    desktopVariant
                  )}
                </ButtonContainer>
              </RowContainer>
            </Column>
            <Column alignX="center">
              {showPropertySelector ? (
                propertySelector
              ) : title ? (
                <StyledCustomHeading
                  data-id="actionbar-heading-mobile"
                  variant="h4"
                  component={headingComponent}
                >
                  {title}
                </StyledCustomHeading>
              ) : null}
            </Column>
          </>
        ) : null}
        {/*used to be the property selector, now just here to help keep the title
         centered */}
        <Column width={!isDesktop ? "content" : "flex"} alignX="right">
          <Box
            display="flex"
            style={{ height: "44px", width: isDesktop ? "auto" : "48px" }}
            justifyContent="flex-end"
          />
        </Column>
      </Columns>
    </Box>
  );
};
