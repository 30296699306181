import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumber, cardId } = CommonParameters;

export const paymentSettingsEditCard: IMcAppType = {
  name: "paymentSettingsEditCard",
  path: "/my/payment-settings/update-card-expiry",
  parameters: {
    properties: { accountNumber, cardId },
    required: ["accountNumber", "cardId"],
  },
  description: "Update the card expiry for the specified card",
  navFlow: NavFlow.MODAL,
  title: "Update card expiry",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentSettingsEditCard",
    customerType: CustomerType.kraken,
  },
};
