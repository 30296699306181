import {
  FullScreenMessage,
  InAppMessageButton,
  ModalMessage,
} from "@braze/web-sdk";
import { parseMarkdownEvent } from "@origin-digital/event-dispatcher";

import { BUTTON_CLICK_ACTION_NONE } from "../utils/config";
import { shouldUseEventDispatch } from "../utils/events";
import { overwriteButtonOnDOMChange } from "./mutation";

export const handleButtonClick = (
  button: InAppMessageButton,
  btnElement: HTMLElement
) => {
  if (button.uri && shouldUseEventDispatch(button.uri)) {
    const { onClick } = parseMarkdownEvent(button.uri);
    if (onClick) {
      btnElement.addEventListener("click", () => onClick());
    }
  }
};

// Add an action for all In-App Message buttons to handle navTo or navClose
// Braze In-App Message Modal type only
export const overwriteButtonAction = (
  inAppMessage: ModalMessage | FullScreenMessage
) => {
  const { buttons } = inAppMessage;
  const targetNode = document.querySelector(".ab-in-app-message"); // Get a reference of the In-App Message Modal
  const btnElements = Array.from(
    document.querySelectorAll(".ab-message-button")
  ); // Get a reference of all In-App Message buttons

  // No buttons in In-App Message to handle or no Modal version detected
  if (!Array.isArray(buttons) || buttons.length === 0 || !targetNode) return;

  // Check if the buttons exists in the DOM/In-App Message annd they match expected buttons

  // Not matching: Observe changes to the In-App Message and overwrite if buttons are added
  if (btnElements.length !== buttons.length)
    return overwriteButtonOnDOMChange(buttons, targetNode);

  // Matching: Loop through the buttons and the btnElements and call handleButtonClick(button, btnElement)
  buttons.forEach((button, i) =>
    handleButtonClick(button, btnElements[i] as HTMLElement)
  );
};

// If the URI contains navTo or navClose - reset the action to "NONE"
export const configureButtons = (
  buttons: InAppMessageButton[]
): InAppMessageButton[] => {
  buttons.forEach((button: InAppMessageButton) => {
    if (button.uri && shouldUseEventDispatch(button.uri)) {
      button.clickAction = BUTTON_CLICK_ACTION_NONE;
    }
  });
  return buttons;
};
