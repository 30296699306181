/**
 * Libraries provided by TAL to consuming apps.
 */

import React from "react";
import ReactDOM from "react-dom";
import * as styled from "sc3";
import * as sc5 from "styled-components";
import * as MuiStyles from "@material-ui/styles";

window["React"] = React;
window["ReactDOM"] = ReactDOM;
window["styled"] = styled;
window["sc5"] = sc5;
window["MuiStyles"] = MuiStyles;

export {};
