import {
  getCachedContentCards,
  logCardClick,
  logCardDismissal,
  logCardImpressions,
  requestContentCardsRefresh,
} from "@braze/web-sdk";
import {
  IContentCards,
  IContentCardsResult,
} from "@origin-digital/event-dispatcher";

import { IBrazeContentCard, IBrazeRetry } from "../consts/interfaces";
import { readLastRefreshDate, writeLastRefreshDate } from "../utils/storage";

import { logContentCardCustomEvent } from "../utils/events";
import { setCustomUserAttribute } from "../utils/user";
import {
  getFilteredRankedCards,
  translateBrazeCardsToContentCards,
} from "./cardUtils";

// Get all currently cached cards from the last content cards refresh.
export const getContentCards = (
  limit: number | undefined,
  location: string | undefined
): IContentCardsResult => {
  try {
    const brazeContentCards = getCachedContentCards();
    // lastUpdated: if null, it means the content cards are still being fetched for this user.
    if (!brazeContentCards.cards.length || !brazeContentCards.lastUpdated) {
      return { cards: [] };
    }
    // 1. convert Braze cards to ContentCards
    const standardisedContentCards = translateBrazeCardsToContentCards(
      brazeContentCards.cards as IBrazeContentCard[],
      logCardClick,
      logCardDismissal,
      logCardImpressions,
      logContentCardCustomEvent,
      setCustomUserAttribute
    );

    // 2. filter + rank cards
    const filteredCards = getFilteredRankedCards(
      standardisedContentCards,
      location,
      limit
    );

    if (!filteredCards.length) {
      return { cards: [] };
    }

    return { cards: filteredCards };
  } catch {
    return { cards: [] };
  }
};

// Requests an immediate refresh of content cards from Braze servers.
// By default, content cards are refreshed when a new session opens.
// If you want to refresh content cards from the server at another time you must call this function.
export const refreshContentCards = (): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    requestContentCardsRefresh(
      () => {
        writeLastRefreshDate();
        resolve(true);
      },
      // on failure
      () => resolve(false)
    );
  });
const TIME_DURATION_2s = 2 * 1000;
const TIME_DURATION_20s = 20 * 1000;
const DEFAULT_RETRY: IBrazeRetry = { retries: 2, delay: TIME_DURATION_2s };

export const isContentCardsStale = (
  ttl: number = TIME_DURATION_20s
): boolean => {
  const { lastUpdated } = getCachedContentCards();
  if (lastUpdated == null) return true;

  const lastRefresh = readLastRefreshDate();
  if (lastRefresh == null) return true;

  return lastRefresh.getTime() + ttl < Date.now();
};

function delayFn(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

const doFetchContentCards = async (
  payload: IContentCards["payload"],
  retry: IBrazeRetry = DEFAULT_RETRY
): Promise<IContentCardsResult> => {
  const { forceRefresh, limit = 1, location } = payload;
  const isRefresh =
    isContentCardsStale() ||
    (forceRefresh && isContentCardsStale(TIME_DURATION_2s));
  if (isRefresh) {
    // if the content cards are stale, refresh them
    await refreshContentCards();
  }
  const result = getContentCards(limit, location);
  // forceRefresh true or undefined or null are treated as retriable
  const isRetry =
    result.cards.length < limit &&
    (forceRefresh || forceRefresh == null) &&
    retry.retries >= 1;
  if (isRetry) {
    await delayFn(retry.delay);
    retry.retries--;
    return doFetchContentCards({ ...payload, forceRefresh: true }, retry);
  }
  return result;
};

export const fetchContentCards = async (
  payload: IContentCards["payload"],
  useCached?: boolean
): Promise<IContentCardsResult> => {
  if (useCached) {
    return getContentCards(payload.limit, payload.location);
  }
  return doFetchContentCards(payload);
};

export const backgroundRefresh = async () => {
  const TTL_60s = 1000 * 60;

  if (isContentCardsStale(TTL_60s)) {
    refreshContentCards();
  }
};
