import { IFetchAccessToken, TOPICS } from "@origin-digital/event-dispatcher";
import { initNativeEmulator } from "@origin-digital/native-features";
import { logger } from "@origin-digital/reporting-client";

import { IPlatformHandlerProps, PlatformHandler } from "../mesh.types";
import { webPlatformHandler } from "./webPlatformHandler";
import { NativePlatformHandler } from "./nativePlatformHandler";

export class NativeEmulatorPlatformHandler
  extends NativePlatformHandler
  implements PlatformHandler
{
  private readonly webPlatformHandler: PlatformHandler;

  constructor({ environment }: IPlatformHandlerProps) {
    super({ environment });
    this.webPlatformHandler = webPlatformHandler({
      environment,
    });

    this[TOPICS.FETCH_ACCESS_TOKEN] =
      this[TOPICS.FETCH_ACCESS_TOKEN].bind(this);
  }
  public [TOPICS.FETCH_ACCESS_TOKEN](
    payload: IFetchAccessToken["payload"]
  ): IFetchAccessToken["result"] {
    return this.webPlatformHandler[TOPICS.FETCH_ACCESS_TOKEN](
      payload
    ) as IFetchAccessToken["result"];
  }
}

let instance: PlatformHandler;
export const nativeEmulatorPlatformHandler = (
  props: IPlatformHandlerProps
): PlatformHandler => {
  if (!instance) {
    initNativeEmulator();
    logger.info("[nativeEmulatorPlatformHandler]: enabled");
    instance = new NativeEmulatorPlatformHandler(props);
  }
  return instance;
};
