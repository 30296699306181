import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

/** @deprecated use paymentHistory instead */
export const billHistory_KRAKEN: IMcAppType = {
  name: "billHistory_KRAKEN",
  path: "/my/bills-payments/:accountNumber?",
  description: "History of transactions",
  parameters: {
    required: ["accountNumber"],
    properties: { accountNumber: CommonParameters.accountNumber },
  },
  navFlow: NavFlow.STANDARD,
  title: "Bills and Payments",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "billHistory",
    customerType: CustomerType.kraken,
  },
  ignorePathMapping: true,
  deprecated: true,
};
