import { getFeatureFlag } from "@braze/web-sdk";
import { SegmentType } from "@origin-digital/optimizely";

import { SEGMENT } from "../consts/consts";

export const getSegment = (tagId: string) => {
  const featureFlag = getFeatureFlag(tagId);
  if (featureFlag?.enabled) {
    return featureFlag.getStringProperty(SEGMENT.PROPERTY);
  }
  return null;
};

export const isValidSegmentType = (segment: string): segment is SegmentType =>
  [SEGMENT.CUSTOMERS, SEGMENT.OTHERS, SEGMENT.DEFAULT].includes(segment);
