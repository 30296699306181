import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const referral: IMcAppType = {
  name: "referral",
  path: "/my/referral",
  description: "Refer a friend",
  navFlow: NavFlow.STANDARD,
  title: "Refer a friend",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "referral",
    customerType: CustomerType.kraken,
  },
};
