import {
  IFetchGeolocation,
  IGeolocation,
  IWriteGeolocation,
} from "@origin-digital/event-dispatcher";
import { syncBrazeSdkUserGeolocation } from "@origin-digital/braze";
import { getUserGeolocation as getUserGeolocationOpti } from "@origin-digital/optimizely";

import { readLastGeolocation, writeLastGeolocation } from "./storage";
import { getUserGeolocation as getUserGeolocationMM } from "./maxmind";

class StatePostcodeRegex {
  // NSW:
  //      1\d{3}                      => 1000—1999 (LVRs and PO Boxes only)
  //      2[0-5]\d{2}                 => 2000—2599
  //      2619|26[2-9]\d|2[78]\d{2}   => 2619—2899
  //      292[1-9]|29[3-9]\d          => 2921—2999
  private static readonly NSW_REGEX =
    /^(1\d{3}|2[0-5]\d{2}|(2619|26[2-9]\d|2[78]\d{2})|(292[1-9]|29[3-9]\d))$/;

  // ACT:
  //      02\d{2}         => 0200—0299 (LVRs and PO Boxes only)
  //      260\d|261[0-8]  => 2600—2618
  //      29[01]\d|2920   => 2900—2920
  private static readonly ACT_REGEX =
    /^(02\d{2}|(260\d|261[0-8])|(29[01]\d|2920))$/;

  // VIC:
  //      3\d{3}  => 3000—3999
  //      8\d{3}  => 8000—8999 (LVRs and PO Boxes only)
  private static readonly VIC_REGEX = /^(3\d{3}|8\d{3})$/;

  // QLD:
  //      4\d{3}  => 4000—4999
  //      9\d{3}  => 9000—9999 (LVRs and PO Boxes only)
  private static readonly QLD_REGEX = /^(4\d{3}|9\d{3})$/;

  // SA:
  //      5\d{3}  => 5000—5799
  //      5\d{3}  => 5800—5999 (LVRs and PO Boxes only)
  private static readonly SA_REGEX = /^5\d{3}$/;

  // WA:
  //      6[0-6]\d{2}|67[0-8]\d|679[0-7]  => 6000—6797 => 6000—6699 + 6700—6789 + 6790—6797
  //      6[89]\d{2}                      => 6800—6999 (LVRs and PO Boxes only)
  private static readonly WA_REGEX =
    /^((6[0-6]\d{2}|67[0-8]\d|679[0-7])|6[89]\d{2})$/;

  // TAS:
  //      7[0-7]\d{2} => 7000—7799
  //      7[89]\d{2}  => 7800—7999 (LVRs and PO Boxes only)
  private static readonly TAS_REGEX = /^(7\d{3})$/;

  // NT:
  //      0[89]\d{2} => 0800—0899
  //      0[89]\d{2} => 0900—0999 (LVRs and PO Boxes only)
  private static readonly NT_REGEX = /^0[89]\d{2}$/;

  public static readonly STATE_REGEX_MAP = {
    ACT: StatePostcodeRegex.ACT_REGEX,
    NSW: StatePostcodeRegex.NSW_REGEX,
    NT: StatePostcodeRegex.NT_REGEX,
    QLD: StatePostcodeRegex.QLD_REGEX,
    SA: StatePostcodeRegex.SA_REGEX,
    TAS: StatePostcodeRegex.TAS_REGEX,
    VIC: StatePostcodeRegex.VIC_REGEX,
    WA: StatePostcodeRegex.WA_REGEX,
  };
}

const mapGeolocationOnPostcode = (geolocation: IGeolocation): IGeolocation => {
  const { state, postcode } = geolocation;
  if (!postcode) {
    return { state, postcode };
  }
  for (const [_state, _regex] of Object.entries(
    StatePostcodeRegex.STATE_REGEX_MAP
  )) {
    if (_regex.test(postcode)) {
      return { state: _state, postcode } as IGeolocation;
    }
  }
  return { state, postcode };
};

// Validation Rules:
// state?, postcode?
// VIC, 3100            => VIC, 3100
// VIC, undefined       => VIC, undefined
// undefined, 3100      => VIC, 3100
// NSW, 3000            => NSW, undefined
// undefined, undefined => undefined, undefined
const mapGeolocation = (geolocation: IGeolocation): IGeolocation => {
  const { state, postcode } = geolocation;
  if (state) {
    const regex = StatePostcodeRegex.STATE_REGEX_MAP[state];
    const _postcode = regex.test(postcode ?? "") ? postcode : undefined;
    return { postcode: _postcode, state };
  }
  return mapGeolocationOnPostcode(geolocation);
};

export const writeGeolocationFn = ({
  payload,
}: {
  payload: IWriteGeolocation["payload"];
}): Promise<boolean> => {
  const cachedGeolocation = readLastGeolocation().geolocation;
  const geolocation = mapGeolocation(payload);
  if (
    !cachedGeolocation ||
    cachedGeolocation.state != geolocation.state ||
    cachedGeolocation.postcode != geolocation.postcode
  ) {
    writeLastGeolocation(geolocation);
    return Promise.resolve(syncBrazeSdkUserGeolocation(geolocation));
  }
  return Promise.resolve(false);
};

const fetchAndCacheGeolocation = async (
  fetchGeolocationFn: () => Promise<IGeolocation>,
  noCacheUpdate: boolean
) => {
  const fetchedGeolocation = await fetchGeolocationFn();
  if (!noCacheUpdate) {
    writeGeolocationFn({ payload: fetchedGeolocation });
  }
  return fetchedGeolocation;
};

const DEFAULT_VALUE_TTL_FIVE_DAYS_IN_HOURS = 5 * 24;
const toMilliseconds = (hrs: number = 0, min: number = 0, sec: number = 0) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const fetchGeolocationFn = async ({
  payload,
}: {
  payload: IFetchGeolocation["payload"];
}): Promise<IGeolocation> => {
  const {
    ttlInHours = DEFAULT_VALUE_TTL_FIVE_DAYS_IN_HOURS,
    noCacheUpdate = false,
    useMaxMindGeolocation = false,
  } = payload;
  const cachedGeolocation = readLastGeolocation();
  const isFetch =
    !cachedGeolocation.geolocation ||
    cachedGeolocation.date + toMilliseconds(ttlInHours) < Date.now();
  const hasOptimizely = window["@od/optimizely"]?.usingOptimizelyUI === false;

  if (isFetch) {
    // Using Optimizely Geolocation if:
    // 1. The Optimizely script is added to the page (and it's not in preview mode)
    // 2. Use MaxMind Geolocation is not specified in the payload
    if (hasOptimizely && !useMaxMindGeolocation) {
      return fetchAndCacheGeolocation(getUserGeolocationOpti, noCacheUpdate);
    }
    // Use Maxmind Geolocation instead of Optimizely Geolocation
    return fetchAndCacheGeolocation(getUserGeolocationMM, noCacheUpdate);
  }
  return Promise.resolve(cachedGeolocation.geolocation);
};
