import React, { useState } from "react";
import { interaction } from "@origin-digital/reporting-client";
import { getInvitationUrl } from "./invitation-service";
import { TrustPilotAlertView } from "./TrustPilotAlertFooterView";
import { RatingResponse } from "./review-summary-service";

interface TrustPilotAlertFooterProps {
  open: boolean;
  journey?: string;
  rating: RatingResponse | null;
  onClose: () => void;
  onCtaClick: () => void;
}

export const TrustPilotAlertFooter = ({
  open,
  journey,
  rating,
  onClose,
  onCtaClick,
}: TrustPilotAlertFooterProps) => {
  const [loading, setLoading] = useState(false);

  const goToTrustPilot = async () => {
    setLoading(true);
    const newTabReference = window.open();
    const url = await getInvitationUrl(journey);
    setLoading(false);
    if (newTabReference) {
      (newTabReference as any).location = url;
    }
  };

  const onCloseAlert = () => {
    interaction("click", {
      componentType: "modal",
      id: "trustpilot-review",
      parentContainer: "",
      appName: "trustpilot-review",
      label: "Review us on Trustpilot",
      state: "closed",
    });
    onClose();
  };

  const onCtaClickCapture = () => {
    interaction("click", {
      componentType: "button",
      id: "trustpilot-review",
      parentContainer: "",
      appName: "trustpilot-review",
      label: "Review us on Trustpilot",
    });
  };

  return (
    <TrustPilotAlertView
      open={open}
      loading={loading}
      rating={rating}
      onClose={onCloseAlert}
      onCtaClickCapture={onCtaClickCapture}
      onCtaClick={() => {
        onCtaClick();
        goToTrustPilot();
      }}
    />
  );
};
