import { logger, interaction } from "@origin-digital/reporting-client";
import { getEnv } from "./helper";
import initialize from "./intercom";

export interface IIntercomSettings {
  user_id?: string;
  hide_default_launcher?: boolean;
  custom_launcher_selector?: string;
}

export function initIntercom(
  backends: string[],
  userHash?: string,
  settings?: IIntercomSettings
): void {
  const appId = getEnv() === "prod" ? "hs2d6jw6" : "pllf553u";
  if (document.readyState === "complete") {
    install(appId, backends, userHash, settings);
  } else {
    window.addEventListener(
      "load",
      () => {
        install(appId, backends, userHash, settings);
      },
      false
    );
  }
}

function install(
  appId: string,
  backends: string[],
  userHash?: string,
  settings?: IIntercomSettings
): void {
  createSettingsInWindow(appId, backends, userHash, settings);
  initialize(appId);
  addAnalyticsOnShow();
}

export async function createSettingsInWindow(
  appId: string,
  backends: string[],
  userHash?: string,
  settings?: IIntercomSettings
): Promise<void> {
  const { user_id, ...rest } = settings || {};

  window.intercomSettings =
    user_id && userHash
      ? {
          app_id: appId,
          user_id,
          user_hash: userHash,
          mcid: getMarketingCloudVisitorID(),
          custom_launcher_selector: ".oe_chat_launcher",
          backend_crms: backends.join(", "),
          ...rest,
        }
      : {
          app_id: appId,
          mcid: getMarketingCloudVisitorID(),
          custom_launcher_selector: ".oe_chat_launcher",
          ...rest,
        };
}

function getMarketingCloudVisitorID(): string | null {
  try {
    const mcid = window["_satellite"]
      .getVisitorId()
      .getMarketingCloudVisitorID();
    // eslint-disable-next-line no-negated-condition
    return mcid !== null ? mcid : null;
  } catch (e) {
    if (e instanceof Error) {
      logger.error("[od/chat]: failed to get MarketingCloudVisitorID", e);
    }
    return null;
  }
}

function addAnalyticsOnShow(): void {
  if (window.Intercom) {
    window.Intercom("onShow", () => {
      interaction("click", {
        componentType: "button",
        id: "open-chat",
        parentContainer: "intercom",
        label: "open chat widget",
      });
    });
  } else {
    logger.error("[od/chat]: failed to add analytics: open-chat: web");
  }
}
