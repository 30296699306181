import { dapiFetch } from "@origin-digital/daxi";

export interface RatingResponse {
  starsString: string;
  starsImageUrl: string;
  numberOfReviews: number;
}

export const ratingApi = "/api/reviews/v1/reviews/summary";

export const getTrustpilotRatingData = async () => {
  try {
    const response = await dapiFetch<RatingResponse>(ratingApi, {
      method: "GET",
      authorization: "jwt",
    });
    return response.data as RatingResponse;
  } catch (e) {
    return null;
  }
};
