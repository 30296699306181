import React, { useEffect, useState } from "react";
import { MASQUERADE_ID_CLAIM_KEY } from "@origin-digital/origin-auth";
import { fetchAccessToken } from "@origin-digital/event-dispatcher";
import {
  decodeOriginCustomerIdFromJwt,
  getClaimFromJwt,
} from "../../auth/jwtUtils";
import { MasqueradeBannerView } from "./MasqueradeBannerView";

export const MasqueradeBanner = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [originCustomerId, setOriginCustomerId] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      const accessTokenResponse = await fetchAccessToken({});
      if (accessTokenResponse) {
        const masqueradeId = getClaimFromJwt(
          accessTokenResponse.accessToken,
          MASQUERADE_ID_CLAIM_KEY
        );
        setOriginCustomerId(
          decodeOriginCustomerIdFromJwt(accessTokenResponse.accessToken)
        );
        if (masqueradeId) {
          setShowBanner(true);
        }
      }
    };
    init();
  }, []);

  return showBanner ? (
    <MasqueradeBannerView originCustomerId={originCustomerId} />
  ) : null;
};
