import { EnvironmentNames } from "@origin-digital/platform-enums";

export const BRAZE_ID_KEY = "bzid";

const API_KEY_RX_DEV = "55ddcb26-6267-4026-a2b4-98230f715bfb"; // rx_dev
const API_KEY_ORIGIN_TEST = "2df83312-d734-493f-86b4-3393b9c1a2ef"; // origin_test
const API_KEY_ORIGIN_PROD = "3b8383c1-c29d-435f-8dcd-e91a86955afe";

export const API_KEY: Record<EnvironmentNames, string> = {
  local: API_KEY_RX_DEV,
  dev: API_KEY_RX_DEV,
  test: API_KEY_ORIGIN_TEST,
  staging: API_KEY_ORIGIN_TEST,
  regress: API_KEY_ORIGIN_TEST,
  prod: API_KEY_ORIGIN_PROD,
};

export const SDK_BASE_URL = "sdk.iad-05.braze.com";

export const getApiKey = (environment: EnvironmentNames) => {
  return API_KEY[environment] ?? API_KEY["test"];
};

const LOCAL_DOMAIN = "localhost";
const DAC_DOMAIN_URI = "origindigital-dac.com.au";
export const PAC_DOMAIN_URI = "origindigital-pac.com.au";
export const PRODUCTION_DOMAIN_URI = "originenergy.com.au";
export const NAVIGATION_DOMAIN = "originenergy";

export const REFERRER_KEY: Record<EnvironmentNames, string> = {
  local: LOCAL_DOMAIN,
  dev: DAC_DOMAIN_URI,
  test: DAC_DOMAIN_URI,
  staging: PAC_DOMAIN_URI,
  regress: PAC_DOMAIN_URI,
  prod: PRODUCTION_DOMAIN_URI,
};

export type Context = "dashboard";

// higher than intercom: 2147483003
export const INAPP_ZINDEX = 2147483010;

export const EVENT_NAV_PREFIX = "NAV_";
export const BUTTON_CLICK_ACTION_NONE = "NONE";
export const BRAZE_ID = "braze-oe";
export const DASHBOARD_PATHNAME = "/my/";
export const MY_ACCOUNT_ROUTE_PREFIX = "/my/";
export const MY_ACCOUNT_SURVEY_PATH = "/my/survey";

export const TASK_COMPLETION_SURVEY_ID = "task-completion";
