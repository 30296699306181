import { handleButtonClick } from "./buttons";

export const overwriteButtonOnDOMChange = (
  buttons: any[],
  targetNode: Element
) => {
  const btnSelector = ".ab-message-button";
  const MUTATION_TIMEOUT_5S = 1000 * 5;

  // Create a MutationObserver to watch for changes in the InAppMessage
  // The buttons are not shown immediately with the rest of the In-App Message, so we need to wait for it to be added to the DOM
  const mutationObserver = new MutationObserver((mutationsList, observer) => {
    // Look through all mutations that just occurred
    for (const mutation of mutationsList) {
      // If the addedNodes property has one or more nodes
      if (mutation.addedNodes.length) {
        const btnsInAppMessage = targetNode.querySelectorAll(btnSelector);
        if (btnsInAppMessage.length) {
          buttons.forEach((button, i) =>
            handleButtonClick(button, btnsInAppMessage[i] as HTMLElement)
          );
          // Once the buttons are found, there's no need to keep observing
          observer.disconnect();
        }
      }
    }
  });
  // Start observing the targetNode
  mutationObserver.observe(targetNode, { childList: true, subtree: true });

  // Abort the observer if no buttons load within 5 seconds
  setTimeout(() => mutationObserver.disconnect(), MUTATION_TIMEOUT_5S);
};
