import { DecodedJwt, IAuth0Config } from "../interfaces";

export const decodeJwt = (jwt: string): DecodedJwt => {
  try {
    const header = JSON.parse(atob(jwt.split(".")[0]));
    const payload = JSON.parse(atob(jwt.split(".")[1]));
    return {
      header,
      payload,
    };
  } catch (e) {
    throw new Error("Could not decode jwt");
  }
};

export const verifyJwt = (
  decoded: DecodedJwt,
  auth0Config: IAuth0Config
): void => {
  if (decoded.header.alg !== "RS256") {
    throw new Error(
      `Signature algorithm of "${decoded.header.alg}" is not supported. Expected the token to be signed with "RS256".`
    );
  }
  if (!decoded.payload.sub) {
    throw new Error(
      "Subject (sub) claim must be a string present in the token"
    );
  }
  if (decoded.payload.aud.indexOf(auth0Config.audience) === -1) {
    throw new Error(
      `Audience (aud) claim mismatch in the token; expected "${auth0Config.audience}" but found "${decoded.payload.aud}"`
    );
  }
  if (isNaN(decoded.payload.exp)) {
    throw new Error(
      "Expiration Time (exp) claim must be a number present in the token"
    );
  }
  if (isNaN(decoded.payload.iat)) {
    throw new Error(
      "Issued At (iat) claim must be a number present in the token"
    );
  }

  const leeway = 60;
  const now = new Date(Date.now());
  const expDate = new Date(0);
  expDate.setUTCSeconds(decoded.payload.exp + leeway);
  if (now > expDate) {
    throw new Error(
      `Expiration Time (exp) claim error in the token; the token is expired`
    );
  }
};
