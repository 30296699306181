import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const lpgUsage_WITHOUT_PARAMS: IMcAppType = {
  name: "lpgUsage_WITHOUT_PARAMS",
  path: "/my/service/lpg/usage",
  description: "Lpg Usage",
  navFlow: NavFlow.FOCUS,
  title: "LPG Usage",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "lpgUsage",
  },
};
