import React, { useEffect, useRef } from "react";

export const useFocusTrap = (
  ref: React.RefObject<HTMLElement>,
  showMenu: boolean
) => {
  // tracking showMenu in a ref to avoid stale closure
  // in the event listener
  const showMenuRef = useRef(showMenu);
  useEffect(() => {
    showMenuRef.current = showMenu;
  }, [showMenu]);

  // For accessibility, when the menu is open (fullscreen), trap tab selections to the menu
  React.useEffect(() => {
    const listener = (e) => {
      if (!(e.key === "Tab")) return;
      if (!ref.current) return;
      if (!showMenuRef.current) return;

      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

      const focusableContent =
        ref.current.querySelectorAll<HTMLDivElement>(focusableElements);

      const firstFocusableElement = focusableContent[0];
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];

      const isFirstSelected = document.activeElement === firstFocusableElement;
      const isLastSelected = document.activeElement === lastFocusableElement;

      let isSlideinLink = false;
      focusableContent.forEach((el) => {
        if (document.activeElement === el) isSlideinLink = true;
      });

      if (e.shiftKey && (isFirstSelected || !isSlideinLink)) {
        lastFocusableElement.focus();
        e.preventDefault();
      } else if (!e.shiftKey && (isLastSelected || !isSlideinLink)) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, []);
};
