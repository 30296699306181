import React from "react";
import { eventDispatcher, TOPICS } from "@origin-digital/event-dispatcher";
import { interaction } from "@origin-digital/reporting-client";
import { ComponentTypeEnum } from "../ReviewAlertFooterService";
import { NativeAppAlertFooterView } from "./NativeAppAlertFooterView";

interface NativeAppAlertFooterProps {
  open: boolean;
  type: ComponentTypeEnum;
  onClose: () => void;
  onCtaClick: () => void;
}

export const appStoreUrl =
  "https://apps.apple.com/app/id1201586468?action=write-review";
export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.originmobileapp";

export const NativeAppAlertFooter = ({
  open,
  type,
  onClose,
  onCtaClick,
}: NativeAppAlertFooterProps) => {
  const buttonText =
    type === ComponentTypeEnum.NativeAppIos
      ? "Review us on the App Store"
      : "Review us on Google Play";

  const goToAppStore = () => {
    eventDispatcher.dispatch({
      topic: TOPICS.NAV_TO_URL,
      payload: {
        url:
          type === ComponentTypeEnum.NativeAppIos ? appStoreUrl : playStoreUrl,
        odinReturnToUri: false,
        authenticated: false,
        externalBrowser: true,
      },
    });
    onCtaClick();
  };

  const onCloseAlert = () => {
    interaction("click", {
      componentType: "modal",
      id: "app-store-review",
      parentContainer: "",
      appName: "app-store-review",
      label: buttonText,
      state: "closed",
    });
    onClose();
  };

  const onCtaClickCapture = () => {
    interaction("click", {
      componentType: "button",
      id: "app-store-review",
      parentContainer: "",
      appName: "app-store-review",
      label: buttonText,
    });
  };

  return (
    <NativeAppAlertFooterView
      open={open}
      buttonText={buttonText}
      onClose={onCloseAlert}
      onCtaClickCapture={onCtaClickCapture}
      onCtaClick={goToAppStore}
    />
  );
};
