import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumbers } = CommonParameters;

export const paymentSettingsFrequency: IMcAppType = {
  name: "paymentSettingsFrequency",
  path: "/my/payment-settings/frequency",
  parameters: {
    properties: { accountNumbers },
  },
  description: "Payment frequency for a set of given accounts numbers",
  navFlow: NavFlow.FOCUS,
  title: "Payment frequency",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentSettingsFrequency",
    customerType: CustomerType.kraken,
  },
};
