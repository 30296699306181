/**
 * Globally install the MaxMind GeoIP script for use.
 * Refer https://www.npmjs.com/package/@maxmind/geoip2-node
 * Refer https://dev.maxmind.com/geoip/geolocate-an-ip/client-side-javascript
 * @returns {Promise}
 */
const MAXMIND_SCRIPT_SRC = "//js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js";
function loadGeoTargetingApi() {
  return new Promise((resolve, reject) => {
    const maxmindScriptTag = document.createElement("script");
    maxmindScriptTag.setAttribute("src", MAXMIND_SCRIPT_SRC);
    maxmindScriptTag.setAttribute("async", "true");
    maxmindScriptTag.addEventListener("load", resolve);
    maxmindScriptTag.addEventListener("error", reject);
    document.body.appendChild(maxmindScriptTag);
  });
}

/**
 * @returns {Promise.<global.geoip2>}
 */
async function getGeoTargetingApi() {
  // @ts-ignore
  if (!global.geoip2) {
    await loadGeoTargetingApi();
  }
  // @ts-ignore
  return Promise.resolve(global.geoip2);
}

export interface GeoIpCityResponse {
  country?: { iso_code: string };
  subdivisions?: Array<{ iso_code: string }>;
  postal?: { code: string };
}

/**
 * @returns {Promise.<geoTargetResponse>}
 */
export const getUserLocation = async (): Promise<GeoIpCityResponse> => {
  const geoTargetingApi = await getGeoTargetingApi();

  return new Promise<GeoIpCityResponse>((resolve, reject) => {
    geoTargetingApi.city(resolve, reject);
  });
};
