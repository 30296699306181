import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const move_KRAKEN: IMcAppType = {
  name: "move_KRAKEN",
  path: "/moving/",
  parameters: {
    properties: {
      book: {
        type: "boolean",
        description: "Show 'Book my Move' button",
      },
    },
  },
  description: "Move house",
  navFlow: NavFlow.STANDARD,
  title: "Move",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "move",
  },
};
