import { fetchPlatformParameters } from "@origin-digital/event-dispatcher";
import { CustomerType } from "@origin-digital/platform-enums";

import { addDays, fromUnixTime, isAfter, isValid, getUnixTime } from "date-fns";

export const getDismissedKey = (componentType: string) =>
  `review_dismissUntil_${componentType}`;

export const handleDismiss = (
  componentType: string | undefined,
  dismissDuration: number,
  currentDate = new Date()
) => {
  const endDate = addDays(currentDate, Number(dismissDuration));
  localStorage.setItem(
    getDismissedKey(componentType!),
    getUnixTime(endDate).toString()
  );
};

export const getInititalShowState = (
  componentType?: string,
  currentDate = new Date()
) => {
  if (!componentType) {
    return false;
  }
  const key = getDismissedKey(componentType);
  const dismissedEndDate = localStorage.getItem(key);

  const dismissUntilDate = fromUnixTime(Number(dismissedEndDate));
  if (!isValid(dismissUntilDate)) {
    localStorage.removeItem(key);
    return true;
  }

  const expired = isAfter(currentDate, dismissUntilDate);
  if (expired) {
    localStorage.removeItem(key);
  }
  return expired;
};

export const getCustomerType = async (): Promise<CustomerType | undefined> => {
  try {
    const platform = await fetchPlatformParameters({});
    return platform?.customerType;
  } catch (e) {
    return CustomerType.unauthenticated;
  }
};
