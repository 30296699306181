import {
  eventDispatcher,
  fetchPlatformParameters,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { logger } from "@origin-digital/reporting-client";
import { Channel, CustomerType } from "@origin-digital/platform-enums";
import { verifyIntentPath } from "@origin-digital/mcapp-registry";

export interface ISetupJwtProps {
  path: string;
  parameters?: Record<string, any>;
}

/**
 * load, verify and setup user info based on jwt
 * @param environment
 */
export const setupVerifyJwt = async ({
  path,
  parameters,
}: ISetupJwtProps): Promise<boolean> => {
  try {
    const platform = await fetchPlatformParameters({});
    if (!platform) return false;
    const { customerType, channel } = platform;

    // this should never happen since we redirect if customer is unauthenticated
    if (customerType === CustomerType.unauthenticated) return false;

    const intent = verifyIntentPath(path, platform, parameters);
    // cannot find an intent that matches
    if (intent === undefined) return false;
    // found intent that matches current route
    if (intent === true) return false;

    if (channel === Channel.NATIVE) {
      // fix in https://origindd.atlassian.net/browse/P20-107
      logger.warn(
        "[@od/bootloader]: setupVerifyJwt() native wrong route, show upgrade dialog"
      );
      return false;
    } else {
      // timeout if logger.warn does not resolve
      const oneSecTimeOut = new Promise<boolean>((resolve) => {
        setTimeout(resolve, 1000);
      });
      return Promise.race([
        oneSecTimeOut,
        logger.warn(
          `[@od/bootloader]: setupVerifyJwt() redirect to '${intent}' for ${customerType}${
            platform.scopedToken ? " (scoped)" : ""
          } customer '${window.location.pathname}'`
        ),
      ])
        .then(() => true)
        .finally(() => {
          eventDispatcher.dispatch({
            topic: TOPICS.NAV_TO,
            payload: {
              to: intent,
              parameters,
            },
          });
          return true;
        });
    }
  } catch (err) {
    logger.error("[@od/bootloader]: boot() jwt fetch failure", err);
    return false;
  }
};
