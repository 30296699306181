import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const homeEnergyOverview_KRAKEN: IMcAppType = {
  name: "homeEnergyMonitor_KRAKEN",
  path: "/my/home-energy-overview",
  description: "Origin home energy overview",
  navFlow: NavFlow.STANDARD,
  title: "Home energy overview",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "homeEnergyOverview",
    customerType: CustomerType.kraken,
  },
};

export const homeEnergyMonitorVpp_KRAKEN: IMcAppType = {
  name: "homeEnergyMonitorVpp_KRAKEN",
  path: "/my/home-energy-overview/vpp-events",
  description: "Origin home energy overview",
  navFlow: NavFlow.STANDARD,
  title: "VPP event history",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "homeEnergyOverviewVpp",
    customerType: CustomerType.kraken,
  },
};
