import {
  IProperty,
  IService,
  IUserContext,
} from "@origin-digital/event-dispatcher";

const isActive = (service: IService) => service.serviceStatus !== "CLOSED";

export const getAllPropertiesForUser = (user?: IUserContext) => {
  const allServices = user?.accounts.flatMap((a) => a.services) ?? [];

  const [activeProperties, inactiveProperties] = allServices.reduce(
    (acc: [IProperty[], IProperty[]], service) =>
      isActive(service)
        ? (acc[0].push(service.property), acc)
        : (acc[1].push(service.property), acc),
    [[], []]
  );

  return [activeProperties, inactiveProperties];
};
