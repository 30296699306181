import { IGeolocation } from "@origin-digital/event-dispatcher";
import { getUserLocation, GeoIpCityResponse } from "./maxmindScript";

export const getUserGeolocation = async (): Promise<IGeolocation> => {
  const location: GeoIpCityResponse = await getUserLocation();
  if (location?.country?.iso_code !== "AU") {
    return Promise.resolve({});
  }
  const state = location?.subdivisions?.at(0)?.iso_code;
  const postcode = location?.postal?.code;
  const geolocation = { state, postcode } as IGeolocation;
  return Promise.resolve(geolocation);
};
