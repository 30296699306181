import React from "react";
import {
  AlertBanner,
  Box,
  Inline,
  Text,
  TextLink,
} from "@origin-digital/ods-core";
import styled from "styled-components";
import { Z_INDEX } from "../../consts/style";

export interface IMasqueradeBannerView {
  originCustomerId: string | null;
}

const StickyBannerContainer = styled.div`
  z-index: ${Z_INDEX.MASQUERADE_BANNER};
  position: sticky;
  top: 0;
`;

export const IdentityConsoleBanner = styled(AlertBanner)({
  backgroundColor: "#1e0d35",
  borderRadius: "0",
  paddingTop: "4px",
  paddingBottom: "4px",
});

export const MasqueradeBannerView = ({
  originCustomerId,
}: IMasqueradeBannerView) => {
  return (
    <StickyBannerContainer>
      <IdentityConsoleBanner
        fluidity={true}
        variant="filled"
        tone="neutral"
        icon={false}
      >
        <Box
          display="flex"
          justifyContent={["space-between", "center"]}
          data-id="masquerade-banner"
        >
          <Inline space="none">
            <Text tone={"white" as any}>
              Masquerading User {originCustomerId}
            </Text>
          </Inline>
          <Box paddingLeft="medium">
            <Text tone={"white" as any} weight="medium">
              <TextLink data-id="agent-ui-link" href="/agent" target="_blank">
                User details
              </TextLink>
            </Text>
          </Box>
        </Box>
      </IdentityConsoleBanner>
    </StickyBannerContainer>
  );
};
