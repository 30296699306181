import React from "react";

import { INavigationItem } from "@origin-digital/navigation-client-sdk";
import { NavLinkIndentation } from "../NavLinkBase";
import { MobileNavLinkContainer } from "./MobileNavLinkContainer";

interface IMobileNavLinkGroupProps {
  linkConfig: INavigationItem;
  setDrawerVisibility: (event: React.KeyboardEvent | React.MouseEvent) => void;
  isOpen: boolean;
  setDropdownOpen: (name: string) => void;
}

export const MobileNavLinkGroup: React.FC<IMobileNavLinkGroupProps> = ({
  linkConfig,
  isOpen,
  setDrawerVisibility,
  setDropdownOpen,
}) => {
  return (
    <div
      data-id="mobile-nav-link-group"
      id={`mobile-nav-link-group-${linkConfig.name}`}
    >
      <MobileNavLinkContainer
        linkConfig={linkConfig}
        onClick={() => setDropdownOpen(linkConfig.name)}
        indent={NavLinkIndentation.Parent}
        isOpen={isOpen}
      />

      {isOpen && (
        <div data-id="mobile-nav-link-group-children">
          {linkConfig.children?.map((navLink, index) => (
            <MobileNavLinkContainer
              linkConfig={navLink}
              key={index}
              onClick={setDrawerVisibility}
              indent={NavLinkIndentation.Child}
            />
          ))}
        </div>
      )}
    </div>
  );
};
