import React from "react";

export const getNodeText = (node?: React.ReactNode): string => {
  if (!node) {
    return "";
  }
  if (["string", "number", "boolean"].includes(typeof node)) {
    return String(node);
  }
  if (node instanceof Array) {
    return node.map(getNodeText).join("");
  }
  if (typeof node === "object") {
    return getNodeText((node as React.ReactElement)?.props?.children);
  }

  return "";
};
