import { IAuth0Config } from "../interfaces";

export const auth0ConfigTest: IAuth0Config = {
  scope: "openid email read:api all",
  auth0host: "https://test-originenergy.au.auth0.com",
  auth0Tenant: "test-originenergy",
  clientId: "ALYOChtYV62H4hNeCFYXGIgS2UQgL5r0",
  clientPassword: "client password",
  grantType: "http://auth0.com/oauth/grant-type/password-realm",
  realm: "MyAccountUsers",
  apiPath: "https://staticsite-cdn-test.origindigital-dac.com.au/auth/api",
  apiKey: "IT8kjMFYfIL1CBCZEara5VdkC2nxG9u9NOuu0Sm5",
  audience: "https://digitalapi",
  domain: "test-originenergy.au.auth0.com",
  customDomain: "id.test.originenergy.com.au",
  responseType: "token id_token",
  ruleNamespace: "https://originenergy.com.au",
  assetPath: "https://staticsite-cdn-test.origindigital-dac.com.au/identity",
  environmentId: "test",
  krakenApiPath: "https://api-test.rx.originenergy.com.au",
};
