import { CustomerType } from "@origin-digital/platform-enums";
import {
  CommonParameters,
  IMcAppType,
  NavFlow,
  Tab,
  BasicJSONSchema,
} from "../mcApp.types";

const isTrackingUnavailableParameter: BasicJSONSchema = {
  description:
    "whether tracking is unavailable or not for an existing LPG order",
  type: "boolean",
};

const trackingIdParameter: BasicJSONSchema = {
  description: "tracking id for an existing LPG order",
  type: "string",
};

const orderNumberParameter: BasicJSONSchema = {
  description: "Order number for an existing LPG order",
  type: "string",
};

export const lpgOrder_KRAKEN: IMcAppType = {
  name: "lpgOrder_KRAKEN",
  path: "/my/service/lpg/:customerNumber/order",
  parameters: {
    required: ["customerNumber"],
    properties: { customerNumber: CommonParameters.customerNumber },
  },
  description: "Place an LPG order",
  navFlow: NavFlow.FOCUS,
  parent: Tab.DASHBOARD,
  title: "LPG Order",
  conditions: {
    intent: "lpgOrder",
    customerType: CustomerType.kraken,
    parameters: { customerNumber: "<string>" },
  },
};

export const lpgOrder_KRAKEN_WITHOUT_PARAMS: IMcAppType = {
  name: "lpgOrder_KRAKEN_WITHOUT_PARAMS",
  path: "/my/service/lpg/order",
  description: "Place an LPG order",
  navFlow: NavFlow.FOCUS,
  parent: Tab.DASHBOARD,
  title: "LPG Order",
  conditions: {
    intent: "lpgOrder",
    customerType: CustomerType.kraken,
  },
};

export const lpgOrderReschedule_KRAKEN: IMcAppType = {
  name: "lpgOrderReschedule_KRAKEN",
  path: "/my/service/lpg/:customerNumber?/order/:orderNumber/reschedule",
  parameters: {
    required: ["orderNumber"],
    properties: {
      customerNumber: CommonParameters.customerNumber,
      orderNumber: orderNumberParameter,
    },
  },
  description: "Reschedule Order",
  navFlow: NavFlow.FOCUS,
  parent: Tab.DASHBOARD,
  title: "Reschedule order",
  conditions: {
    intent: "lpgOrderReschedule",
    customerType: CustomerType.kraken,
  },
};

export const lpgOrderCancel_KRAKEN: IMcAppType = {
  name: "lpgOrderCancel_KRAKEN",
  path: "/my/service/lpg/:customerNumber/order/:orderNumber/cancel",
  parameters: {
    required: ["customerNumber", "orderNumber"],
    properties: {
      customerNumber: CommonParameters.customerNumber,
      orderNumber: orderNumberParameter,
    },
  },
  description: "Cancel Order",
  navFlow: NavFlow.FOCUS,
  parent: Tab.DASHBOARD,
  title: "Cancel order",
  conditions: {
    intent: "lpgOrderCancel",
    customerType: CustomerType.kraken,
  },
};

export const lpgOrderSummary_KRAKEN: IMcAppType = {
  name: "lpgOrderSummary_KRAKEN",
  path: "/my/service/lpg/:customerNumber/order/:orderNumber",
  parameters: {
    required: ["customerNumber", "orderNumber"],
    properties: {
      orderNumber: orderNumberParameter,
      customerNumber: CommonParameters.customerNumber,
    },
  },
  description: "LPG Order",
  navFlow: NavFlow.STANDARD,
  parent: Tab.DASHBOARD,
  title: "LPG order",
  conditions: {
    intent: "lpgOrderSummary",
    customerType: CustomerType.kraken,
  },
};

export const lpgOrderSummaryLanding_KRAKEN: IMcAppType = {
  name: "lpgOrderSummaryLanding_KRAKEN",
  path: "/my/service/lpg/order/summary",
  parameters: {
    properties: { code: trackingIdParameter },
  },
  description: "LPG Order Summary Landing",
  navFlow: NavFlow.STANDARD,
  parent: Tab.DASHBOARD,
  title: "LPG Order",
  conditions: {
    intent: "lpgOrderSummaryLanding",
    customerType: CustomerType.kraken,
  },
};

export const lpgOrderTrack_KRAKEN: IMcAppType = {
  name: "lpgOrderTrack_KRAKEN",
  path: "/my/service/lpg/track/:trackingId?",
  parameters: {
    properties: {
      trackingId: trackingIdParameter,
      isTrackingUnavailable: isTrackingUnavailableParameter,
    },
  },
  description: "Track an LPG order",
  navFlow: NavFlow.FOCUS,
  parent: Tab.DASHBOARD,
  title: "Track LPG Order",
  conditions: {
    intent: "lpgOrderTrack",
    customerType: CustomerType.kraken,
  },
};
