import { CustomerType } from "@origin-digital/platform-enums";
import { HOME_INTENT, NavFlow, Tab, IMcAppType } from "../mcApp.types";

export const home_KRAKEN: IMcAppType = {
  name: "home_KRAKEN",
  path: "/my",
  description: "Home for kraken users",
  navFlow: NavFlow.TAB,
  title: "Home",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: HOME_INTENT,
    customerType: CustomerType.kraken,
    scopedToken: false,
  },
  ignorePathMapping: true,
};

export const home_SCOPED: IMcAppType = {
  name: "home_SCOPED",
  path: "/",
  description: "Home for scoped users",
  navFlow: NavFlow.STANDARD,
  title: "Home",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: HOME_INTENT,
    scopedToken: true,
  },
  ignorePathMapping: true,
};

export const home_UNAUTHED: IMcAppType = {
  name: "home_UNAUTHED",
  path: "/",
  description: "Home for unauthed users",
  navFlow: NavFlow.STANDARD,
  title: "Home",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: HOME_INTENT,
    customerType: CustomerType.unauthenticated,
  },
  ignorePathMapping: true,
};
