// @ts-nocheck
// eslint-disable
import React from "react";
import { Box as OrigBox } from "@origin-digital/ods-core";

export type { BoxProps } from "@origin-digital/ods-core";

export const Box = (props: BoxProps) => {
  return <OrigBox {...props} />;
};
