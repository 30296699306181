import {
  logFeatureFlagImpression,
  subscribeToFeatureFlagsUpdates,
} from "@braze/web-sdk";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { logger } from "@origin-digital/reporting-client";

import { updateCohortCookie } from "../utils/cookie";
import { TAG_IDS } from "../consts/consts";
import { getSegment, isValidSegmentType } from "./featureFlagUtils";

// Keeps the segment priority in order
const tagIds = [TAG_IDS.CUSTOMERS, TAG_IDS.PROSPECTS, TAG_IDS.OTHERS];

/**
 * Handles the segment update based on the environment.
 * @param {EnvironmentNames} environment - The current environment
 */
export const handleSegmentUpdate = (environment: EnvironmentNames) => {
  let segment: string | null = null;
  for (const tagId of tagIds) {
    segment = getSegment(tagId);
    if (segment && isValidSegmentType(segment)) {
      updateCohortCookie(segment, environment).then(() =>
        logFeatureFlagImpression(tagId)
      );
      break; // Exit the loop after the first segment is found and processed
    }
  }
  if (!segment) {
    logger.warn("[braze] No valid segment found");
  }
};

/**
 * Subscribes to segment updates and updates cohort cookies based on the environment.
 * @param {EnvironmentNames} environment - The current environment
 */
export const subscribeToSegments = (environment: EnvironmentNames) =>
  subscribeToFeatureFlagsUpdates(() => handleSegmentUpdate(environment));
