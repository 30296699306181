import { configFetch } from "@origin-digital/daxi";
import { eventListener, TOPICS } from "@origin-digital/event-dispatcher";
import { getEnv } from "./helper";
import { fetchUserHashEventHandler, initChatEventHandler } from "./chat";

const environment = getEnv();
const disableAuthCheck = ["local", "dev"].includes(environment);

configFetch({
  environment: environment as any,
  disableAuthCheck,
});
eventListener.addListener(TOPICS.CHAT_INIT, initChatEventHandler);
eventListener.addListener(
  TOPICS.FETCH_CHAT_USER_HASH,
  fetchUserHashEventHandler
);

export { initWebChat } from "./webChatHandler";
