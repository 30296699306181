import { IAuth0Config } from "../interfaces";

export const auth0ConfigStaging: IAuth0Config = {
  scope: "openid email read:api all",
  auth0host: "https://preprod-originenergy.au.auth0.com",
  auth0Tenant: "preprod-originenergy",
  clientId: "s0yVVvgSjB9NqHmamqB2PdZp000JtyWG",
  clientPassword: "",
  grantType: "http://auth0.com/oauth/grant-type/password-realm",
  realm: "MyAccountUsers",
  apiPath: "https://staticsite-cdn-staging.origindigital-pac.com.au/auth/api",
  apiKey: "RTkOXSBckW5e8FYrU3vOp6KgJeUBasuI274eBAAz",
  audience: "https://digitalapi",
  domain: "preprod-originenergy.au.auth0.com",
  customDomain: "id.preprod.originenergy.com.au",
  responseType: "token id_token",
  ruleNamespace: "https://originenergy.com.au",
  assetPath: "https://staticsite-cdn-staging.origindigital-pac.com.au/identity",
  environmentId: "staging",
  krakenApiPath: "https://api-test.rx.originenergy.com.au",
};
