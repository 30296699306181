import { configSumo, logger } from "@origin-digital/reporting-client";
import SumoLogger from "sumo-logger";
import { IErrorResponse, IReportingClientConfig } from "../interfaces/index";

/**
 * This class is just a wrapper to initialize the SumoLogic logger using the
 * reporting-client.
 */

interface LoggerPayLoad {
  errorMessage: string;
  payload: any;
}
export class Logger {
  /**
   * Bootstraps the SumoLogic config and initialises a SumoLogic logging instance.
   * @param config{IReportingClientConfig} - Object containing required SumoLogging params
   */
  public bootstrap(config: IReportingClientConfig) {
    if (config && Object.keys(config).length !== 0) {
      configSumo({
        SumoLogger,
        appName: config.appName,
        endpoint: config.sumoEndpoint,
        sourceEnv: config.sourceEnv,
        sendOnErrors: true,
        sumoLogLevel: config.sumoLogLevel,
        consoleLogLevel: config.consoleLogLevel,
      });
    } else {
      console.log("Reporting client configurations are empty");
    }
  }

  public error(args: IErrorResponse) {
    const loggerPayload = this.createLoggerPayLoad(args);
    logger.log("error", loggerPayload.errorMessage, loggerPayload.payload);
  }

  public debug(args: IErrorResponse) {
    const loggerPayload = this.createLoggerPayLoad(args);
    logger.debug(loggerPayload.errorMessage, loggerPayload.payload);
  }

  createLoggerPayLoad = (args: IErrorResponse): LoggerPayLoad => {
    const { timestamp }: any = args;
    // prettier-ignore
    const isoTimestamp = timestamp ? new Date(timestamp).toISOString() : new Date().toISOString();
    const errorMessage = `${args.methodName} - ${args.displayMessage.errorCode} - ${args.displayMessage.body}`;
    const payload: any = { ...args.data, timestamp: isoTimestamp };

    return {
      errorMessage,
      payload,
    };
  };
}
