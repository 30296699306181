import React from "react";

import { Box, Divider } from "@origin-digital/ods-core";

import styled from "styled-components";
import { IKrakenNavLink } from "../interfaces";
import { NavLinkBase, NavLinkIndentation } from "../NavLinkBase";

interface IMobileNavLinkContainer extends IKrakenNavLink {
  onClick?: (e: React.KeyboardEvent | React.MouseEvent) => void;
  indent?: NavLinkIndentation;
  isOpen?: boolean;
}

const NavLinkButton = styled.button`
  border: none;
  padding: 0;

  width: 100%;
  background-color: transparent;
`;

export const MobileNavLinkContainer = (props: IMobileNavLinkContainer) => {
  const { onClick, ...linkProps } = props;

  return (
    <Box>
      <Divider />
      <NavLinkButton
        data-id="mobile-nav-link-container"
        as={linkProps.linkConfig.href && "span"}
        onClick={onClick}
        tabIndex={linkProps.linkConfig.href ? undefined : 0}
      >
        <NavLinkBase mobile={true} {...linkProps} />
      </NavLinkButton>
    </Box>
  );
};
