import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const batteryMaximiserUsage: IMcAppType = {
  name: "batteryMaximiserUsage",
  path: "/my/service/battery-maximiser/:nmi/usage",
  parameters: {
    required: ["nmi"],
    properties: { nmi: CommonParameters.nmi },
  },
  description: "Battery Maximiser - Energy breakdown page",
  navFlow: NavFlow.STANDARD,
  title: "Energy breakdown",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "batteryMaximiserUsage",
  },
};
