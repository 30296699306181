import * as React from "react";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@origin-digital/ods-types";

import { MenuBar } from "./MenuBar";
import { getConfig, Link } from "./nav-config";
import { useFocusTrap } from "./focus-trap";

export const NavWrapper = styled.div<{ showMenu: boolean }>`
  // set background to white to for pages that have a grey background (currently help-support)
  background: white;
  position: relative;

  z-index: 1000;
  //  the nav must be above the actionbar and above intercomm.
  //  actionbar is 1000, intercomm is 2147483001
  ${(p) =>
    p.showMenu &&
    `position: fixed; top: 0; left: 0; right: 0; z-index: 2147483002;`}
`;

const useMobileMenu = (navRef: React.RefObject<HTMLElement>) => {
  const [menuOpen, setShowMenu] = React.useState(false);

  React.useEffect(() => {
    // toggle body scroll
    if (menuOpen) {
      document.body.style.setProperty("height", "100vh", "important");
      document.body.style.setProperty("overflow", "hidden", "important");
    } else {
      document.body.style.removeProperty("height");
      document.body.style.removeProperty("overflow");
    }

    // using requestAnimationFrame seems to give the best result
    // allowing any reflow (potentially from a scrollbar appearing
    // or dissapearing) to happen as the page navbar disapears
    // before snapping the scroll to the top of the nav (where
    // the pull down menu will slide in from)
    requestAnimationFrame(() => {
      navRef.current?.scrollIntoView();
    });
  }, [menuOpen]);

  const toggleShowMenu = () => {
    setShowMenu((state) => !state);
  };

  return [menuOpen, toggleShowMenu] as const;
};

const mapLinks = (
  links: Array<
    Link & {
      children?: Array<Link>;
    }
  >,
  pathname: string | undefined
): [number | null, number | null] => {
  // iterate backwards to find the most specific selection.
  // an example of this is something like /ev vs /ev/business
  // going through the list backwards will select /ev/business
  // and this is the case throughout the rest of the nav
  for (let i = links.length - 1; i >= 0; i--) {
    const currentParentLink = links[i];

    if (currentParentLink.children) {
      for (let j = currentParentLink.children.length - 1; j >= 0; j--) {
        const currentChildLink = currentParentLink.children[j];

        const selected = pathname?.startsWith(currentChildLink.link);
        if (selected) return [i, j];
      }
    }

    if (pathname !== "/" && pathname?.startsWith(currentParentLink.link)) {
      // set selection, but continue to check for a more specific selection
      // from a child link
      return [i, null];
    }
  }
  return [null, null];
};

export const createNavObject = (
  pathname: string | undefined,
  isMobile: boolean
) => {
  const nav = getConfig();
  const [firstLevelSelection, secondLevelSelection] = mapLinks(
    nav.primaryLinks,
    pathname
  );
  const [secondaryLinksSelection] = mapLinks(nav.secondaryLinks, pathname);

  if (secondaryLinksSelection !== null) {
    if (isMobile) {
      nav.primaryLinks[0].selected = true;
    } else {
      nav.secondaryLinks[secondaryLinksSelection].selected = true;
      nav.primaryLinks[0].children = [];
    }
  } else if (firstLevelSelection === null) {
    nav.primaryLinks[0].selected = true;
  } else {
    nav.primaryLinks[firstLevelSelection].selected = true;

    if (secondLevelSelection !== null) {
      nav.primaryLinks[firstLevelSelection].children[
        secondLevelSelection
      ].selected = true;
    }
  }

  return nav;
};

export const Navigation = () => {
  const navRef = React.useRef<HTMLDivElement>(null);
  const [menuOpen, toggleMenu] = useMobileMenu(navRef);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  useFocusTrap(navRef, menuOpen && isMobile);

  const [pathname, setPathname] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  const nav = createNavObject(pathname, isMobile);

  return (
    <NavWrapper
      data-id="unauth-nav"
      data-comment="Nav"
      ref={navRef}
      showMenu={menuOpen && isMobile}
    >
      <MenuBar
        logoLinkHref="/"
        toggleMenu={toggleMenu}
        showMenu={menuOpen}
        nav={nav}
      />
    </NavWrapper>
  );
};
