import * as React from "react";

const nativeInstallPromptEventName = "beforeinstallprompt";

export const SuppressAndroidInstallPrompt: React.FunctionComponent<
  any
> = () => {
  React.useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
    };
    window.addEventListener(
      nativeInstallPromptEventName,
      beforeInstallPromptHandler
    );

    return () =>
      window.removeEventListener(
        nativeInstallPromptEventName,
        beforeInstallPromptHandler
      );
  }, []);

  return null;
};
