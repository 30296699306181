import { INavigationItem } from "@origin-digital/navigation-client-sdk";

export const CAMPAIGN_ID_PROD = 30143130421;
export const CAMPAIGN_ID_TEST = 30142350221;
const PARENT_TARGET = "plans-properties";
const CHILD_TARGET = "changePlan";
const OPTI_EXP_B = "Exp B";

export const isActiveTest = (variation: string | undefined) =>
  variation === OPTI_EXP_B;

/**
 * TEMP FILTER ONLY
 * Filter out the CHILD_TARGET of PARENT_TARGET if isTestExp is true
 * @param navData
 * @param isTestExp
 */
export const filterChangePlanNavItem = (
  navData: INavigationItem[],
  isTestExp: boolean
): INavigationItem[] => {
  // test is active and its a list
  if (isTestExp && Array.isArray(navData)) {
    return navData.map((navLink) => {
      if (navLink.name === PARENT_TARGET) {
        const filteredChildren = navLink.children?.filter(
          (child) => child.name !== CHILD_TARGET
        );
        return { ...navLink, children: filteredChildren };
      }
      return navLink;
    });
  }
  return navData;
};
