import { FullScreenMessage, ModalMessage } from "@braze/web-sdk";

import { markdownBasic } from "../utils/markdownBasic";

export const overwriteMessage = (
  inAppMessage: ModalMessage | FullScreenMessage
) => {
  const { message } = inAppMessage;
  const targetNode = document.querySelector(".ab-message-text");

  if (!message || !targetNode) return;

  // Convert the markdown message to HTML and insert it
  const htmlString = markdownBasic(message);

  if (htmlString) {
    const heading = targetNode.querySelector("h1");
    // Clear existing content
    targetNode.textContent = "";

    // Add back the heading
    if (heading) {
      targetNode.appendChild(heading);
    }
    // Insert the new content
    targetNode.insertAdjacentHTML("beforeend", htmlString);
  }
};
