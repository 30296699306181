import { logger } from "@origin-digital/reporting-client";
import {
  auth0EnvConfigs,
  OriginSpaAuthClient,
  getCookie,
  getCachedJwtEntry,
  IAuth0Config,
  verifyJwt,
  decodeJwt,
} from "@origin-digital/origin-auth";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { IFetchAccessToken } from "@origin-digital/event-dispatcher";

const SAP_TOKEN_COOKIE = "user-token";
const SAP_TIMEOUT_COOKIE = "is-logged-in";

const handleFailedSilentAuth = (auth0Params: IAuth0Config) => (e: Error) => {
  // These are temporary to help identify/debug the root cause of failed silent auth
  const hasCookie = !!getCookie(SAP_TOKEN_COOKIE);
  const sapTokenTimeout = getCookie(SAP_TIMEOUT_COOKIE);
  const isSapTokenExpired = sapTokenTimeout
    ? Date.now() > parseInt(sapTokenTimeout, 10)
    : true;

  const cachedJwt = getCachedJwtEntry(auth0Params);
  let isJwtValid = !!cachedJwt;

  try {
    if (cachedJwt) {
      verifyJwt(decodeJwt(cachedJwt.body.access_token), auth0Params);
    }
  } catch (error) {
    isJwtValid = false;
    logger.error("[Silent Auth]: Invalid JWT token", error);
  }

  logger.error("[Silent Auth]: Error getting jwt silently", {
    ...e,
    hasCookie,
    isSapTokenExpired,
    hasJwt: !!cachedJwt,
    isJwtValid,
  });

  // TO-DO: Check for error code "login_required" and redirect to login page
  // SY-1629
  return Promise.reject(new Error("error getting jwt silently"));
};

// This code is designed to be called in web only
// Odin jwt handled via native-featu
export const getJwt = (env: EnvironmentNames): IFetchAccessToken["result"] => {
  const auth0Params = auth0EnvConfigs[env];
  if (!auth0Params) {
    return Promise.reject(
      new Error(`auth0 config missing for environment: ${env}`)
    );
  }
  const originSpaAuthClient = new OriginSpaAuthClient(auth0Params);

  return originSpaAuthClient
    .getJwtSilently()
    .then((accessToken) => ({ accessToken }))
    .catch(handleFailedSilentAuth(auth0Params));
};
