import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const evChargingScheduleList_KRAKEN: IMcAppType = {
  name: "evChargingScheduleList_KRAKEN",
  path: "/my/service/ev/charger/:chargerId/schedules",
  parameters: {
    required: ["chargerId"],
    properties: { chargerId: CommonParameters.chargerId },
  },
  description: "EV Charger schedule list",
  navFlow: NavFlow.STANDARD,
  title: "Charging schedule",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evChargerSchedulingList",
    customerType: CustomerType.kraken,
  },
};

export const evChargingScheduleAdd_KRAKEN: IMcAppType = {
  name: "evChargingScheduleAdd_KRAKEN",
  path: "/my/service/ev/charger/:chargerId/schedules/add",
  parameters: {
    required: ["chargerId"],
    properties: { chargerId: CommonParameters.chargerId },
  },
  description: "EV Charger schedule add",
  navFlow: NavFlow.FOCUS,
  title: "Add charging schedule",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evChargerSchedulingAdd",
    customerType: CustomerType.kraken,
  },
};

export const evChargingScheduleEdit_KRAKEN: IMcAppType = {
  name: "evChargingScheduleEdit_KRAKEN",
  path: "/my/service/ev/charger/:chargerId/schedules/edit/:scheduleId",
  parameters: {
    required: ["chargerId", "scheduleId"],
    properties: {
      chargerId: CommonParameters.chargerId,
      scheduleId: CommonParameters.scheduleId,
    },
  },
  description: "EV Charger schedule edit",
  navFlow: NavFlow.FOCUS,
  title: "Edit charging schedule",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evChargerSchedulingEdit",
    customerType: CustomerType.kraken,
  },
};
