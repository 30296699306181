export const AUTH0_LOGIN_REQUIRED_ERROR = "login_required";
export const AUTH0_INTERACTION_REQUIRED_ERROR = "interaction_required";
export const ADOBE_ANALYTICS_LOGIN_TYPE = "adobeAnalyticsLoginType";
export const ADOBE_LOGIN_TYPE_MANUAL = "manual";
export const ADOBE_LOGIN_TYPE_AUTO = "auto";
export const REMEMBER_ME = "remember_me";
export const ERROR_PAGE_PATH = "/auth/#/error";
export const ERROR_PAGE_PATH_KEY = "ERROR_PAGE_PATH";
export const MASQUERADE_ID_CLAIM_KEY =
  "https://originenergy.com.au/masqueradeId";

export const errors = {
  AUTH0_CHECK_SESSION: {
    errorCode: "origin-auth:auth0_check_session",
    body: "AUTH0-LOGIN-ERROR-03::Auth0 error in checking session for access token and its expiration date",
  },
  AUTH0_DECODE_HASH: {
    errorCode: "origin-auth:auth0_decode_hash",
    body: "AUTH0-LOGIN-ERROR-04::Auth0 library unable to decode hash",
  },
  AUTH0_INVALID_AUTH_RESULT: {
    errorCode: "origin-auth:auth0_invalid_auth_result",
    body: "AUTH0-LOGIN-ERROR-05::Auth0 invalid auth result",
  },
  AUTH0_BLOCKED: {
    errorCode: "origin-auth:auth0_blocked",
    body: "AUTH0-LOGIN-ERROR-06::Auth0 unauthorized",
  },
  AUTH0_TIMEOUT: {
    errorCode: "origin-auth:auth0_timeout",
    body: "AUTH0-LOGIN-ERROR-07::Auth0 login timeout",
  },
};
