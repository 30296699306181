import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

const title = "Help and support";

export const helpAndSupport: IMcAppType = {
  name: "helpAndSupport",
  description: "Help & Support Landing Page",
  path: "/help-support",
  parameters: {
    properties: {
      context: {
        // this should be an enum
        type: "string",
        description: "Customer Context (unauth or auth)",
      },
    },
  },
  navFlow: NavFlow.TAB,
  parent: Tab.HELP,
  title,
};

export const helpAndSupportDeepLink: IMcAppType = {
  name: "helpAndSupportDeepLink",
  description: "Help & Support Deep link (not landing page)",
  path: "/help-support/:category/:subCategory?/:title?/:articleId?",
  parameters: {
    required: ["category"],
    properties: {
      context: {
        // this should be an enum
        type: "string",
        description: "Customer Context (unauth or auth)",
      },
      category: {
        description: "Help & Support Category",
        type: "string",
      },
      subCategory: {
        description: "Help & SubCategory",
        type: "string",
      },
      title: {
        description:
          "Title of the given article, this will be derived if different from article",
        type: "string",
      },
      articleId: {
        description: "ID number of an article",
        type: "string",
      },
    },
  },
  navFlow: NavFlow.FOCUS,
  parent: Tab.HELP,
  title,
};
