/* eslint-disable no-bitwise */
import { match } from "path-to-regexp";
import { IMcAppType } from "./mcApp.types";

export const findMcAppByPath = (
  mcAppList: IMcAppType[],
  path: string
): IMcAppType | undefined => {
  if (
    ~path.indexOf(".com.au/") ||
    ~path.indexOf("?") ||
    ~path.indexOf("http://") ||
    ~path.indexOf("https://")
  ) {
    throw new Error(
      `[@od/mcapp-reg] findMcAppByPath requires a path only, received '${path}'`
    );
  }
  if (path.indexOf("/") === -1) {
    throw new Error(
      `[@od/mcapp-reg] findMcAppByPath expects path to begin with '/', received '${path}'`
    );
  }
  const mcApp = mcAppList.find((app) => {
    if (app.ignorePathMapping) {
      return false;
    } else {
      // removes trailing / from match expression, this is requred for
      // legacy routes with trailing / in their path such as '/feedback/'
      const matchPath = app.path.endsWith("/")
        ? app.path.slice(0, -1)
        : app.path;
      return match(matchPath)(path);
    }
  });
  return mcApp;
};
