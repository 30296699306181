import { IAuth0Config } from "../interfaces";

export const auth0ConfigProd: IAuth0Config = {
  scope: "openid email read:api all",
  auth0host: "https://originenergy.au.auth0.com",
  auth0Tenant: "originenergy",
  clientId: "yOHRT97N3yH85jzTDlqN2A7Cf2D0cmQe",
  clientPassword: "",
  grantType: "http://auth0.com/oauth/grant-type/password-realm",
  realm: "MyAccountUsers",
  apiPath: "https://www.originenergy.com.au/auth/api",
  apiKey: "RTkOXSBckW5e8FYrU3vOp6KgJeUBasuI274eBAAz",
  audience: "https://digitalapi",
  domain: "originenergy.au.auth0.com",
  customDomain: "id.originenergy.com.au",
  responseType: "token id_token",
  ruleNamespace: "https://originenergy.com.au",
  assetPath: "https://www.originenergy.com.au/identity",
  environmentId: "prod",
  krakenApiPath: "https://api.rx.originenergy.com.au",
};
