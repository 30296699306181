import { CustomerType } from "@origin-digital/platform-enums";

import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const concessions: IMcAppType = {
  name: "concessions_KRAKEN",
  description: "Add or edit concessions",
  title: "Concessions",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  navFlow: NavFlow.STANDARD,
  path: "/my/concessions",
  conditions: {
    intent: "concessions",
    customerType: CustomerType.kraken,
  },
};
