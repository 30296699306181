import { CacheEntry, CachePayload, IAuth0Config } from "../../interfaces";
import { verifyJwt, decodeJwt } from "../jwtUtils";

export const DEFAULT_SCOPE = "openid profile email"; // as defined by auth0 spa sdk

// implementation without requiring a polyfill
const dedupe = (arr: string[]) =>
  arr.filter((item, index) => arr.indexOf(item) === index);

/**
 * This is to replicate the scope functionality
 * used in the auth0 spa sdk lib. This scope is used
 * as part of the localstorage key so it must match.
 * @param scopes
 */
export const getUniqueScopes = (...scopes: string[]) => {
  return dedupe(scopes.join(" ").split(/\s+/)).join(" ");
};

/**
 * This matches the key functionality in the
 * auth0 spa sdk lib
 */
export const createKey = (
  clientId: string,
  audience: string,
  scope: string
) => {
  const keyScope = getUniqueScopes(DEFAULT_SCOPE, scope);
  return `@@auth0spajs@@::${clientId}::${audience}::${keyScope}`;
};

export const wrapCacheEntry = (entry: CacheEntry): CachePayload => {
  const expiresInTime = Math.floor(Date.now() / 1000) + entry.expires_in;
  return {
    body: entry,
    expiresAt: expiresInTime,
  };
};

export const entryNotExpired = (expiresAtSeconds: number): boolean => {
  const leewaySeconds = 60;
  const nowSeconds = Math.floor(Date.now() / 1000);
  return expiresAtSeconds > nowSeconds + leewaySeconds;
};

/**
 * Stores the jwt using the same key & format as the auth0 spa sdk.
 * This allows us to continue using auth0 spa to retrieve our
 * own jwt.
 * @param jwt
 */
export const storeJwt = (jwt: string, auth0Config: IAuth0Config): void => {
  const decoded = decodeJwt(jwt);
  verifyJwt(decoded, auth0Config);
  const entry: CacheEntry = {
    access_token: jwt,
    expires_in: Math.floor(decoded.payload.exp - Date.now() / 1000), // how long til expiry in seconds
    audience: auth0Config.audience,
    scope: getUniqueScopes(DEFAULT_SCOPE, auth0Config.scope),
    client_id: auth0Config.clientId,
  };
  const cacheKey = createKey(
    auth0Config.clientId,
    auth0Config.audience,
    auth0Config.scope
  );
  const payload = wrapCacheEntry(entry);

  window.localStorage.setItem(cacheKey, JSON.stringify(payload));
};

/**
 * Util method to get the jwt without requiring the Auth0Client
 * to reduce the size of the bundle.
 */
export const getCachedJwtEntry = (
  auth0Config: IAuth0Config
): CachePayload | undefined => {
  const cacheEntryString = window.localStorage.getItem(
    createKey(auth0Config.clientId, auth0Config.audience, auth0Config.scope)
  );
  if (cacheEntryString) {
    const cacheEntry: CachePayload = JSON.parse(cacheEntryString);
    return cacheEntry;
  }
  return undefined;
};
