import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const servicePlanV2: IMcAppType = {
  name: "servicePlanV2_KRAKEN",
  path: "/my/servicev2/:serviceType/:agreementId/plan",
  description: "Plan details",
  parameters: {
    required: ["serviceType", "agreementId"],
    properties: {
      agreementId: CommonParameters.agreementId,
      serviceType: CommonParameters.serviceType,
    },
  },
  navFlow: NavFlow.STANDARD,
  title: "Plan details",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "servicePlanV2",
    customerType: CustomerType.kraken,
  },
};
