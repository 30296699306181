import { logger } from "@origin-digital/reporting-client";
import { isNative } from "@origin-digital/platform-helpers";
import { StorageKeys } from "@origin-digital/platform-enums";
import { IntentName } from "@origin-digital/event-dispatcher";
import { IMeshStorage, INavToData } from "../mesh.types";
import { queryString, windowExists } from "./helpers";

const APP_VERSION_KEY = "version";
const APP_VERSION = "2";

const storage = (): Storage =>
  isNative() ? window.localStorage : window.sessionStorage;

export const readStorage = (): IMeshStorage => {
  if (windowExists) {
    const json = storage().getItem(StorageKeys.mesh);
    if (json) {
      const storageObj = JSON.parse(json);
      const version = parseInt(storageObj[APP_VERSION_KEY] || 0, 10);
      if (version >= parseInt(APP_VERSION, 10)) {
        return storageObj;
      }
    }
  }
  return { navTo: [] };
};

export const writeStorage = (obj: Partial<IMeshStorage>): void => {
  if (windowExists) {
    const oldStorage = readStorage();
    try {
      storage().setItem(
        StorageKeys.mesh,
        JSON.stringify({
          ...oldStorage,
          ...obj,
          [APP_VERSION_KEY]: APP_VERSION,
        })
      );
    } catch (error) {
      logger.error("[od/mesh]: cannot write to storage", error);
    }
  } else {
    logger.error(
      "[od/mesh]: cannot write to session storage when window does not exist."
    );
  }
};

export const addNavToReferrerPath = (
  navToKey: IntentName,
  referrerPath?: string
): void => {
  const oldStorage = readStorage();
  oldStorage.navTo.push({
    navToKey,
    resolvable: false,
    referrerPath: referrerPath || window.location.href,
  });
  writeStorage(oldStorage);
};

export const addNavToPromise = (navTo: INavToData): void => {
  const oldStorage = readStorage();
  // remove duplicates currently in storage for the same key
  oldStorage.navTo = oldStorage.navTo.filter(
    (navObj) => navObj.navToKey !== navTo.navToKey
  );
  oldStorage.navTo.push(navTo);
  writeStorage(oldStorage);
};

export const updateNavToPromise = (
  navToKey: IntentName,
  update: Partial<INavToData>
): void => {
  const oldStorage = readStorage();
  oldStorage.navTo.map((data: INavToData, index: number) => {
    if (data.navToKey === navToKey) {
      oldStorage.navTo[index] = { ...data, ...update };
    }
    return data;
  });
  writeStorage(oldStorage);
};

export const deleteNavToPromise = (navToKey: string): void => {
  const oldStorage = readStorage();
  const updatedNavTo = oldStorage.navTo.filter(
    (navToData: INavToData) => navToData.navToKey !== navToKey
  );
  writeStorage({ navTo: updatedNavTo });
};

export const getNavToPromise = (
  appName?: IntentName
): INavToData | undefined => {
  const oldStorage = readStorage();
  if (!appName && oldStorage.navTo.length) {
    return oldStorage.navTo[oldStorage.navTo.length - 1];
  }

  return oldStorage.navTo.find(
    ({ navToKey }: INavToData) => navToKey === appName
  );
};

/**
  Include reslut as query param in return url
*/
export const getNavToReferralPath = (): {
  referrerPath: INavToData["referrerPath"];
  navToKey: INavToData["navToKey"];
} | void => {
  const navTo = getNavToPromise();
  if (navTo) {
    const { result, navToKey, navStatus, referrerPath } = navTo;
    const status = navStatus || "cancel";
    const params =
      typeof result === "object"
        ? { ...result, navStatus: status }
        : { navStatus: status };
    const resultQueryString = queryString(params, referrerPath.includes("?"));
    return {
      navToKey,
      referrerPath: `${referrerPath}${resultQueryString}`,
    };
  } else {
    return undefined;
  }
};
