import { StorageKeys } from "@origin-digital/platform-enums";
import { logger } from "@origin-digital/reporting-client";
import { windowExists } from "./helper";

export interface IChatStorage {
  userHash?: string;
  lastUpdatedTime?: number;
}

const KEY = StorageKeys.chat;

const storage = (isNative: boolean): Storage =>
  isNative ? window.localStorage : window.sessionStorage;

export const readStorage = (isNative: boolean): IChatStorage => {
  if (windowExists) {
    const json = storage(isNative).getItem(KEY);
    if (json) {
      return JSON.parse(json);
    }
  }
  return { userHash: undefined };
};

export const writeStorage = (
  userHash: IChatStorage,
  isNative: boolean
): void => {
  if (windowExists) {
    try {
      storage(isNative).setItem(KEY, JSON.stringify(userHash));
    } catch (error) {
      logger.error("[od/chat]: cannot write to storage", error as Error);
    }
  } else {
    logger.error(
      "[od/chat]: cannot write to session storage when window does not exist."
    );
  }
};
