/* eslint-disable @typescript-eslint/no-use-before-define */
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { IOriginSpaAuthClient } from "../interfaces";
import { auth0EnvConfigs } from "../config";
import { OriginSpaAuthClient } from "./spa-auth-client/OriginSpaAuthClient";

type IsLoggedInOptions = {
  auth0Environment: EnvironmentNames;
  originSpaAuthClient?: IOriginSpaAuthClient;
};

// Note: Needed to seperate this function to its own
// file as it was causing issues with tree shaking (even
// when this function was not used, bug?)
// (Due to the initialization of OriginSpaAuthClient, tree shaking was
// bailing out when looking at auth0spa sdk required by OriginSpaAuthClient,
// thus unnecessarily pulling in all of auth0spa code)
export function isLoggedIn(options: IsLoggedInOptions): Promise<boolean> {
  const { auth0Environment } = options;

  if (!auth0Environment) {
    throw new Error("auth0Environment should be configured");
  }

  const auth0Params = auth0EnvConfigs[auth0Environment];

  if (!auth0Params) {
    throw new Error(
      `Can't receive configuration for "${auth0Environment}" Auth0 environment`
    );
  }

  try {
    const { originSpaAuthClient = new OriginSpaAuthClient(auth0Params) } =
      options;
    return originSpaAuthClient
      .getJwtSilently()
      .then((token) => {
        return Boolean(token);
      })
      .catch(() => {
        return false;
      });
  } catch (e) {
    return Promise.reject(e);
  }
}
