import { logger } from "@origin-digital/reporting-client";
import { isNative } from "@origin-digital/platform-helpers";
import {
  IAppInfoResponse,
  FeatureDetectionType,
  INativeFeatures,
  FEATURES,
  FeaturesType,
} from "./features.type";
import { postMessage } from "./postMessage";

export const defaultNativeFeatures: FeatureDetectionType = Object.keys(
  FEATURES
).reduce((obj, key) => {
  obj[key] = false;
  return obj;
}, {} as any);

export const receiveMessage = (event: MessageEvent): INativeFeatures | void => {
  if (event.data) {
    let message: IAppInfoResponse = event.data;
    if (typeof event.data === "string") {
      message = JSON.parse(event.data);
    } else {
      message = event.data;
    }
    const features: FeatureDetectionType = (message.features || []).reduce(
      (obj, key): FeaturesType => {
        obj[key] = true;
        return obj;
      },
      {} as any
    );
    const appInfo = message.appInfo;
    return { features, appInfo };
  }
  return undefined;
};

export const fetchNativeFeatures = (): Promise<INativeFeatures> => {
  return new Promise<INativeFeatures>((resolve, reject): void => {
    try {
      if (!isNative()) {
        reject(
          new Error(
            "[@od/natives-features]: fetchNativeFeatures is not supported outside of native"
          )
        );
      }
      //process message event
      const messageEventHandler = (event: Event): void => {
        const nativeFeatures = receiveMessage(event as MessageEvent);
        if (nativeFeatures) {
          //remove message event listener
          document.removeEventListener("message", messageEventHandler);
          resolve(nativeFeatures);
        } else {
          reject(
            new Error(
              "[@od/natives-features]: did not receive any message from native"
            )
          );
        }
      };
      //add message event listener
      document.addEventListener("message", messageEventHandler);
      //request app info
      postMessage({ action: FEATURES.requestAppInfo });
    } catch (error) {
      logger.error("[@od/natives-features] fetchNativeFeatures:", error);
      reject(
        new Error(`[@od/natives-features]: fetchNativeFeatures: ${error}`)
      );
    }
  });
};
