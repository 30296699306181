import React from "react";
import { trackingProviderCaptureClick } from "@origin-digital/reporting-client";
import {
  OriginThemeProvider,
  TrackingProvider,
} from "@origin-digital/ods-core";
import { LinkComponentProps } from "@origin-digital/ods-types";
import { parseMarkdownEvent } from "@origin-digital/event-dispatcher";
import { UserContextProvider } from "@origin-digital/user-context-provider";
import {
  FontStyles,
  ResetStyles,
  IconFontStyles,
  BrazeStyles,
} from "../styles/global";

type Props = {
  setupFonts?: boolean;
  children: React.ReactNode;
};

const MeshLink = React.forwardRef<any, LinkComponentProps>((props, ref) => {
  const { href, onClick } = parseMarkdownEvent(props.href);
  const { children, ...rest } = props;
  return (
    <a
      ref={ref}
      {...rest}
      href={href}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        return rest.onClick && rest.onClick(e);
      }}
    >
      {children}
    </a>
  );
});

export default ({ setupFonts = true, children }: Props) => {
  return (
    <>
      {setupFonts && (
        <>
          <FontStyles />
          <ResetStyles />
          <IconFontStyles />
          <BrazeStyles />
        </>
      )}

      <OriginThemeProvider
        //@ts-ignore fails when updating to ts 4
        linkComponent={MeshLink}
        generatorClassNameOptions={{ seed: "tal" }}
      >
        <UserContextProvider>
          <TrackingProvider onTrackingCapture={trackingProviderCaptureClick}>
            {children}
          </TrackingProvider>
        </UserContextProvider>
      </OriginThemeProvider>
    </>
  );
};
