import React from "react";

import styled from "styled-components";
import { Text } from "@origin-digital/ods-core";
import { Breadcrumbs } from "@mui/material";
import { ChevronRight } from "../Svg/ChevronRight";
import { COLOURS } from "../../consts/style";

// -----------------------------------------
// Desktop
// -----------------------------------------

export const Chevron = styled(ChevronRight)`
  width: 16px;
  color: ${COLOURS.GREY.GREY_80};
  fill: ${COLOURS.GREY.GREY_80};

  display: none;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: block;
  }
`;

export const DesktopBreadcrumbs = styled(Breadcrumbs)`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    padding-top: 2rem;
    display: block;
  }
`;

// -----------------------------------------
// Mobile
// -----------------------------------------

const MobileChevron = styled(ChevronRight)`
  width: 24px;
  color: ${COLOURS.PRIMARY.GREY};
  fill: ${COLOURS.PRIMARY.GREY};
  transform: rotate(180deg);
`;

const MobileBreadcrumbsLink = styled.a`
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  padding: 16px 16px 16px 0;
  margin-left: 16px;
  align-items: start;

  text-decoration: none;
  line-height: 24px;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    margin-left: 24px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

export type BreadcrumbType = {
  title: string;
  link: string;
};

export const MobileBreadcrumb = ({ title, link }: BreadcrumbType) => (
  <MobileBreadcrumbsLink href={link}>
    <MobileChevron />
    <Text variant="body">
      <span>{title}</span>
    </Text>
  </MobileBreadcrumbsLink>
);
