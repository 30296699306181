import React from "react";
import {
  IconBilling,
  IconDashboard,
  IconDescription,
  IconHelpOutline,
  IconProfile,
} from "@origin-digital/ods-icons";

export const icons = {
  IconDashboard: <IconDashboard />,
  IconBilling: <IconBilling />,
  IconDescription: <IconDescription />,
  IconProfile: <IconProfile />,
  IconHelpOutline: <IconHelpOutline />,
};

export const getIcon = (icon?: string) => {
  if (icon) {
    return icons[icon as keyof typeof icons] || <IconDashboard />;
  } else {
    return undefined;
  }
};
