import { requestImmediateDataFlush, logCustomEvent } from "@braze/web-sdk";
import { IGeolocation } from "@origin-digital/event-dispatcher";

export const syncBrazeSdkUserGeolocation = (
  geolocation: IGeolocation
): boolean => {
  const isEventLogged = logCustomEvent(
    "web::trigger::geolocation::updated",
    geolocation
  );
  requestImmediateDataFlush();
  return isEventLogged;
};
