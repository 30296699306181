import React from "react";
import { AlertFooter, Button, Stack, Text } from "@origin-digital/ods-core";
import { TrustPilotCurrentRating } from "./TrustPilotCurrentRating";
import { RatingResponse } from "./review-summary-service";

interface TrustPilotAlertViewProps {
  open: boolean;
  loading: boolean;
  rating: RatingResponse | null;
  onClose: () => void;
  onCtaClick: () => void;
  onCtaClickCapture: () => void;
}

export const TrustPilotAlertView = ({
  open,
  loading,
  rating,
  onClose,
  onCtaClick,
  onCtaClickCapture,
}: TrustPilotAlertViewProps) => (
  <AlertFooter
    open={open}
    onClose={onClose}
    title="Nice work! Did you find that easy?"
  >
    <Stack space={["medium", "large"]}>
      <Text>If you can spare a moment, we’d love a review.</Text>
      <Button
        data-id="trustpilot-review"
        size="small"
        spinner={loading}
        onClickCapture={onCtaClickCapture}
        onClick={onCtaClick}
      >
        Review us on Trustpilot
      </Button>
      <TrustPilotCurrentRating rating={rating} />
    </Stack>
  </AlertFooter>
);
