import {
  eventDispatcher,
  IAlertBanner,
  IAppInfo,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { EnvironmentNames, StorageKeys } from "@origin-digital/platform-enums";
import { auth0EnvConfigs } from "@origin-digital/origin-auth";

export const browserNav = (url: string): void => window.location.assign(url);

type Cb = () => void;

export const onPopStateCallback = (callback: any): void =>
  window.addEventListener("popstate", callback);

export const removePopStateCallback = (callback: Cb): void =>
  window.removeEventListener("popstate", callback);

/** returns a function to remove listener */
export const onStorageChangeCallback = (callback: Cb): Cb => {
  const listener = (event: StorageEvent): void => {
    const { key } = event;
    if (key === StorageKeys.mesh) {
      callback();
    }
  };
  window.addEventListener("storage", listener);
  return () => window.removeEventListener("storage", listener);
};

export const browserPushState = (state: any, title: string): void =>
  window.history.pushState(state, title);

export const windowExists = typeof window !== "undefined";

export const isNavComplete = (): boolean => {
  return windowExists && window.history.state
    ? window.history.state.complete === true
    : false;
};

export const openInNewTab = (url: string): void => {
  const win = window.open(url, "_blank");
  if (win) win.focus();
};

export const isLocalOrCIBuild = (): boolean => {
  const reg = new RegExp(`[?&]env=([^&#]*)`, "i");
  const env = reg.exec(window.location.href);
  return !!env && ["local", "ci"].includes(env[1]);
};

export const windowLocation = (): string => window.location.href;

export const historyBack = (): void => window.history.back();

export const showAlertBanner = (payload: IAlertBanner["payload"]) =>
  eventDispatcher.dispatch({
    topic: TOPICS.ALERT_BANNER_OPEN,
    payload,
  });

const _isVersionGreaterThan = (
  versionA: number[],
  versionB: number[],
  index: number = 0
): boolean => {
  if (!versionA[index] || !versionB[index]) return false;
  if (versionA[index] > versionB[index]) return true;
  if (versionA[index] === versionB[index]) {
    index++;
    return _isVersionGreaterThan(versionA, versionB, index);
  }
  return false;
};

export const isVersionGreaterThan = (
  versionA: string,
  versionB: string
): any => {
  return _isVersionGreaterThan(
    versionA.split(".").slice(0, 3).map(Number),
    versionB.split(".").slice(0, 3).map(Number)
  );
};

// TODO fix so it does not append /? if not needed
//      fix so that it only contains a key once
export const queryString = (
  pairs: Record<string, string | string[] | boolean | undefined>,
  append: boolean = false
): string => {
  const q = Object.keys(pairs).reduce<string>((str: string, key: string) => {
    const value = pairs[key];
    if (value == null) return str;
    const param = `${str.length > 0 ? "&" : ""}${encodeURIComponent(
      key
    )}=${encodeURIComponent(Array.isArray(value) ? value.join() : value)}`;
    return `${str}${param}`;
  }, "");

  return append ? `&${q}` : `/?${q}`;
};

export const getOdinCallbackUri = (
  appInfo: IAppInfo,
  env: EnvironmentNames
): string => {
  const { bundleId, platform } = appInfo;
  const auth0Domain = auth0EnvConfigs[env].customDomain;
  return `${bundleId.toLowerCase()}${
    platform === "ios" ? ".auth0" : ""
  }://${auth0Domain}/${platform}/${bundleId.toLowerCase()}/callback`;
};

export const appendURLHost = (url: string) => {
  const fullUrl = url.startsWith("http")
    ? url
    : `${window.location.origin}${url}`;
  return fullUrl;
};

export const replaceQueryParam = (
  url: string,
  pairs: Record<string, string>,
  append: boolean = false
): string => {
  let urlWithParams = url;
  Object.keys(pairs).forEach((key) => {
    const value = pairs[key];
    const replacement = new RegExp(`${key}=[^&]+`, "g");
    const urlHasQueryParam = urlWithParams.match(replacement);
    const isFirstQueryParam = urlWithParams.match(/\?/g) !== null;
    if (urlHasQueryParam) {
      urlWithParams = urlWithParams.replace(
        replacement,
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    } else if (append) {
      urlWithParams =
        urlWithParams + queryString({ [key]: value }, isFirstQueryParam);
    }
  });
  return urlWithParams;
};
