import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const communityBatteryBillsPayments: IMcAppType = {
  name: "communityBatteryBillsPayments",
  path: "/my/service/community-battery/bills-payments/:accountNumber",
  parameters: {
    required: ["accountNumber"],
    properties: { accountNumber: CommonParameters.accountNumber },
  },
  description: "Community Battery - Bills & payments page",
  navFlow: NavFlow.STANDARD,
  title: "Bills & payments",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "communityBatteryBillsPayments",
  },
};
