import * as React from "react";
import { Button, Dialog, Heading, Stack, Text } from "@origin-digital/ods-core";
import { coreMuiTheme } from "@origin-digital/ods-themes";
import { IconStar } from "@origin-digital/ods-icons";
import {
  IInteractionData,
  interaction,
  navigation,
} from "@origin-digital/reporting-client";

export const buttonAnalytics: IInteractionData = {
  componentType: "button",
  id: "trustpilot-review",
  parentContainer: "",
  appName: "trustpilot-review",
  label: "Review us on Trustpilot",
};

export const TRUSTPILOT_LOCAL_STORAGE_KEY = "trustpilot-review-shown";

export const TrustPilotModal = () => {
  const [showModal, setShowModal] = React.useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  const onButtonClickCapture = () => {
    interaction("click", buttonAnalytics);
  };

  React.useEffect(() => {
    const listener = (event: MessageEvent) => {
      const alreadyShown = localStorage.getItem(TRUSTPILOT_LOCAL_STORAGE_KEY);
      const areAnyModalsShowing =
        document.querySelectorAll(`[class*="MuiDialog-root"]`).length ||
        document.getElementsByClassName("usabilla__overlay").length;

      const onDesktop = innerWidth > coreMuiTheme.breakpoints.values.md;

      if (
        event.data === '{"gacomm":"chat-sent"}' &&
        onDesktop &&
        !areAnyModalsShowing &&
        !alreadyShown
      ) {
        setShowModal(true);
        localStorage.setItem(TRUSTPILOT_LOCAL_STORAGE_KEY, "true");
        navigation("screen", {
          currentUri: window.location.href,
          friendlyUri: `${window.location.href}/trustpilot-modal`,
        });
      }
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, []);
  return (
    <Dialog id="trustpilot-dialog" title="" open={showModal} onClose={onClose}>
      <Stack space={["large", "xlarge"]} alignX="center">
        <Stack alignX="center">
          <Heading variant="h2" align="center">
            <IconStar size="inherit" />
            <IconStar size="inherit" />
            <IconStar size="inherit" />
            <IconStar size="inherit" />
            <IconStar size="inherit" />
          </Heading>
          <Heading variant="h3">How did we go?</Heading>
          <Text align="center">
            If you’ve got a minute to tell us what you think of us on
            Trustpilot, we’d really appreciate it.
          </Text>
        </Stack>

        <Button
          id="trustpilot-review"
          data-id="trustpilot-review"
          href="https://au.trustpilot.com/evaluate/www.originenergy.com.au"
          target="_blank"
          onClickCapture={onButtonClickCapture}
        >
          Review us on Trustpilot
        </Button>
        <img
          src="http://www.originenergy.com.au/content/dam/origin/shared/logo/trustpilot_logo.svg"
          alt="Trustpilot"
          height="24"
        />
      </Stack>
    </Dialog>
  );
};
