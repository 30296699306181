import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumbers } = CommonParameters;

export const paymentSettingsSetUp: IMcAppType = {
  name: "paymentSettingsSetUp",
  path: "/my/payment-settings/setup",
  parameters: {
    properties: { accountNumbers },
  },
  description: "Setup direct debit for a set of given accounts numbers",
  navFlow: NavFlow.FOCUS,
  title: "Set up direct debit",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentSettingsSetUp",
    customerType: CustomerType.kraken,
  },
};
