import React, { useEffect, useState } from "react";
import {
  eventListener,
  IOriginEvents,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { getInititalShowState, handleDismiss } from "./utils";
import {
  getTrustpilotRatingData,
  RatingResponse,
} from "./TrustPilotAlertFooter/review-summary-service";
import { TrustPilotAlertFooter } from "./TrustPilotAlertFooter/TrustPilotAlertFooter";
import { NativeAppAlertFooter } from "./NativeAppAlertFooter/NativeAppAlertFooter";

export const TAL_REVIEWS_MBOX = "tal-reviews-mbox";
export const CLOSE_DISMISS_DURATION = 60;
export const CTA_DISMISS_DURATION = 120;

export enum ComponentTypeEnum {
  Trustpilot = "trustpilot",
  NativeAppIos = "native-app-ios",
  NativeAppAndroid = "native-app-android",
}

interface ReviewsContent {
  componentType: ComponentTypeEnum;
  journey: string;
}

interface ReviewsOffer {
  content: ReviewsContent[];
}

const isAlertAlreadyShowing = (open: boolean) =>
  open ||
  document.querySelectorAll(`[class*="MuiDialog-root"]`).length ||
  document.getElementsByClassName("usabilla__overlay").length;

export const ReviewAlertFooterService = () => {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState<RatingResponse | null>(null);
  const [componentType, setComponentType] = useState<string>();
  const [journey, setJourney] = useState<string>();

  const onCtaClick = () => handleDismiss(componentType, CTA_DISMISS_DURATION);
  const onClose = () => {
    setOpen(false);
    handleDismiss(componentType, CLOSE_DISMISS_DURATION);
  };

  const listener = async (topic: IOriginEvents) => {
    if (topic.topic === TOPICS.NAV_COMPLETE) {
      if (isAlertAlreadyShowing(open)) {
        return;
      }
      window.adobe?.target?.getOffer({
        mbox: TAL_REVIEWS_MBOX,
        success: async (offers: ReviewsOffer[]) => {
          const offerContent = offers?.[0]?.content?.[0];
          const offerComponentType = offerContent?.componentType;
          const offerJourney = offerContent?.journey;
          if (getInititalShowState(offerComponentType)) {
            setComponentType(offerComponentType);
            setJourney(offerJourney);
            if (offerComponentType === ComponentTypeEnum.Trustpilot) {
              const ratingData = await getTrustpilotRatingData();
              setRating(ratingData);
            }
            setOpen(true);
          }
        },
        error: () => {},
      });
    }
  };

  useEffect(() => eventListener.addListener(TOPICS.NAV_COMPLETE, listener), []);

  switch (componentType) {
    case ComponentTypeEnum.Trustpilot: {
      return (
        <TrustPilotAlertFooter
          open={open}
          rating={rating}
          journey={journey}
          onClose={onClose}
          onCtaClick={onCtaClick}
        />
      );
    }
    case ComponentTypeEnum.NativeAppAndroid:
    case ComponentTypeEnum.NativeAppIos: {
      return (
        <NativeAppAlertFooter
          open={open}
          type={componentType}
          onClose={onClose}
          onCtaClick={onCtaClick}
        />
      );
    }
    default:
      return null;
  }
};
