import * as React from "react";
import styled from "styled-components";
import { TrackedLink, TrackedLinkProps } from "@origin-digital/ods-core";
import { getNodeText } from "../utils";

export interface LinkProps
  extends Omit<TrackedLinkProps, "trackingLabel" | "trackingType"> {
  trackingLabel?: string;
  trackingType?: string;
}
const linkDisplayName = "Link";

const Link = ({
  children,
  trackingLabel,
  trackingType,
  ...other
}: LinkProps) => {
  return (
    <TrackedLink
      trackingLabel={trackingLabel || getNodeText(children)}
      trackingType={trackingType || linkDisplayName}
      {...other}
    >
      {children}
    </TrackedLink>
  );
};

Link.displayName = linkDisplayName;

const StyledLink = styled(Link)<LinkProps>`
  color: ${(p) => p.theme.colors.grey400};
  text-decoration: none;
  font-size: 14px;
  &:hover {
    color: ${(p) => p.theme.colors.primary};
  }

  &:hover > svg {
    fill: ${(p) => p.theme.colors.primary};
  }
`;

export { Link, StyledLink };
