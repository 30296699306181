// NOTE: The only intended use of this function is to parse Braze content
// if you need a more robust markdown parser, consider using ODS MarkdownLite

// **very** lightweight markdown parser for bold and italic - assumes one level of nesting only
export const markdownBasic = (input: string): string => {
  // Escape HTML special characters to avoid XSS
  input = input
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

  // Replace bold within italic, ensuring there's content between the markers
  input = input.replace(/(\*|_)(\*\*|__)(.+?)\2\1/g, "<i><b>$3</b></i>");
  // Replace italic within bold, ensuring there's content between the markers
  input = input.replace(/(\*\*|__)(\*|_)(.+?)\2\1/g, "<b><i>$3</i></b>");

  // Replace remaining standalone bold and italic, ensuring there's content between the markers
  input = input.replace(/(\*\*|__)(.+?)\1/g, "<b>$2</b>");
  input = input.replace(/(\*|_)(.+?)\1/g, "<i>$2</i>");

  return input;
};
