import { IGeolocation } from "@origin-digital/event-dispatcher";

const GEOLOCATION_KEY = "@od/geo-targeting.geolocation";
const storage = (): Storage => window.localStorage;

export const readLastGeolocation = (): {
  geolocation: IGeolocation;
  date: number;
} => JSON.parse(storage().getItem(GEOLOCATION_KEY) ?? "{}");

export const writeLastGeolocation = (geolocation: IGeolocation) =>
  storage().setItem(
    GEOLOCATION_KEY,
    JSON.stringify({ geolocation, date: Date.now() })
  );
