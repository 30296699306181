import React from "react";
import { Text, Inline, Strong, Box } from "@origin-digital/ods-core";
import { TrustPilotSvg } from "./TrustpilotSvg";
import { RatingResponse } from "./review-summary-service";

interface TrustPilotCurrentRatingProps {
  rating?: RatingResponse | null;
}

export const TrustPilotCurrentRating = ({
  rating,
}: TrustPilotCurrentRatingProps) =>
  rating ? (
    <Inline space="xsmall" data-id="trustpilot-current-rating">
      <Text variant="body-small" weight="medium">
        {rating.starsString}
      </Text>
      <svg width={70} height={20}>
        <image xlinkHref={rating.starsImageUrl} width="70" height="20" />
      </svg>

      <Inline>
        <Box marginRight="xxsmall">
          <Text variant="body-small">
            <Strong>{rating.numberOfReviews}</Strong> reviews on
          </Text>
        </Box>
        <TrustPilotSvg />
      </Inline>
    </Inline>
  ) : null;
