import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Box, Column, Columns, Hidden } from "@origin-digital/ods-core";
import { logger } from "@origin-digital/reporting-client";
import { fetchVariation } from "@origin-digital/optimizely";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { NavigationProvider } from "@origin-digital/navigation-client-sdk";

import SmallerLogo from "./SmallerLogo";
import { IKrakenAuthNavProps } from "./interfaces";
import { MobileNav } from "./Mobile/MobileNav";
import { CollapsibleNavLink } from "./CollapsibleNavLink";
import { authNavRoutePrefix } from "./helpers/consts";
import { DesktopNav } from "./Desktop/DesktopNav";
import { helpLink } from "./helpConfig";
import {
  CAMPAIGN_ID_PROD,
  CAMPAIGN_ID_TEST,
  isActiveTest,
} from "./helpers/tempChangePlanFilter";

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.grey["200"]}`,
}));

export const KrakenAuthNav: React.FC<IKrakenAuthNavProps> = ({
  desktopVariant,
}) => {
  const showFullHeader = desktopVariant !== "slim";
  const menuDataId = showFullHeader ? "kraken-auth-nav" : "no-nav-header";

  // Temp AB Test to "test" turning off Change My Plan - MAR-513
  // Active ~13 August 2024 - should be removed ~ Oct/Nov 2024

  const [isTestExp, setIsTestExp] = useState<boolean>(false);

  useEffect(() => {
    const OPTI_ID =
      (window?.oetal?.env?.name as EnvironmentNames) === "prod"
        ? CAMPAIGN_ID_PROD
        : CAMPAIGN_ID_TEST;
    (async () => {
      try {
        const variation = await fetchVariation(OPTI_ID);
        setIsTestExp(isActiveTest(variation));
      } catch (error) {
        logger.error("[Optimizely] Tal-Client error fetching AB Test", error);
      }
    })();
  }, []);

  return (
    <StyledBox
      backgroundColor="white"
      data-id={menuDataId}
      component="nav"
      aria-label="Main"
    >
      <Columns space={["medium", "large"]}>
        <Column width="content">
          <SmallerLogo
            id="origin-logo"
            href={authNavRoutePrefix}
            desktopVariant={desktopVariant}
          />
        </Column>
        {showFullHeader ? (
          <>
            <Column width="flex" alignX={["left", "center"]}>
              <NavigationProvider>
                <>
                  <Hidden below="lg">
                    <DesktopNav isTestExp={isTestExp} />
                  </Hidden>
                  <Hidden above="lg">
                    <MobileNav isTestExp={isTestExp} />
                  </Hidden>
                </>
              </NavigationProvider>
            </Column>
            <Column width="content">
              <CollapsibleNavLink key={helpLink.name} linkConfig={helpLink} />
            </Column>
          </>
        ) : null}
      </Columns>
    </StyledBox>
  );
};
