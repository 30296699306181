import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const lpgPaymentSchedule: IMcAppType = {
  name: "lpgPaymentSchedule",
  path: "/my/payment-settings/schedule",
  description: "Payment Schedule",
  navFlow: NavFlow.FOCUS,
  title: "Payment Schedule",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "lpgPaymentSchedule",
    customerType: CustomerType.kraken,
  },
};
