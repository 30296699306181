import { logger } from "@origin-digital/reporting-client";
import { IEventHandler, IOriginEvents, Multicast } from "./eventRegistry";

type Cb = () => void;

const windowExists = typeof window !== "undefined";

export const MULTICAST_EVENT_STORAGE_KEY = "MULTICAST_EVENT";

const storage = (): Storage => window.localStorage;

//exported for testing
export const readFromLocalStorage = (
  key: string
): IOriginEvents | undefined => {
  if (windowExists) {
    const json = storage().getItem(key);
    if (json) {
      return JSON.parse(json);
    }
  } else {
    logger.error(
      "[od/event-reg]: cannot read from local storage when window does not exist."
    );
  }
  return undefined;
};

//multicast event to other tabs by writing it to local storage
const multicastEvent = (event: IOriginEvents): void => {
  if (windowExists) {
    try {
      storage().setItem(MULTICAST_EVENT_STORAGE_KEY, JSON.stringify(event));
      setTimeout(() => {
        //clean up local storage after the handlers have triggered
        storage().removeItem(MULTICAST_EVENT_STORAGE_KEY);
        // if we call this too soon, it wont work on native
      }, 1000);
    } catch (error) {
      logger.error(
        "[od/event-reg]: multicastEvent() cannot write MULTICAST_EVENT to storage",
        error
      );
    }
  } else {
    logger.error(
      "[od/event-reg]: multicastEvent() cannot write to local storage when window does not exist."
    );
  }
};

//listen to storage event to multicast to other tabs on dispatch
//returns function to unsubscribe storage listener
export const multicastStorageHandler = (callback: IEventHandler<any>): Cb => {
  const listener = (event: StorageEvent): void => {
    const { key } = event;
    if (key === MULTICAST_EVENT_STORAGE_KEY) {
      const json = readFromLocalStorage(MULTICAST_EVENT_STORAGE_KEY);
      if (json) {
        callback(json);
      }
    }
  };
  window.addEventListener("storage", listener);
  return () => window.removeEventListener("storage", listener);
};

//handle multicast, defaults to Multicast.NONE
export const multicastEventHandler = (event: IOriginEvents): boolean => {
  const multicast = "multicast" in event ? event.multicast : Multicast.NONE;
  switch (multicast) {
    case Multicast.ALL_TABS:
    case Multicast.OTHER_TABS:
      multicastEvent(event);
      return true;
    case Multicast.NONE:
    default:
      return false;
  }
};
