import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const evIChargeSignup: IMcAppType = {
  name: "evIChargeSignup",
  path: "/my/service/ev-icharge/signup",
  description: "Power Up - Signup process",
  navFlow: NavFlow.FOCUS,
  title: "Sign up for Power Up",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evIChargeSignup",
  },
};

/** @deprecated - use evIChargeSignupVendorRedirect instead */
export const evIChargeSignupRedirect: IMcAppType = {
  name: "evIChargeSignupRedirect",
  path: "/my/service/ev-icharge/signup/redirect",
  description: "Power Up - Signup process redirect",
  navFlow: NavFlow.FOCUS,
  title: "EV Power Up",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evIChargeSignupRedirect",
  },
};

export const evIChargeSignupVendorRedirect: IMcAppType = {
  name: "evIChargeSignupVendorRedirect",
  path: "/my/service/ev-icharge/signup/:vendor/redirect",
  parameters: {
    required: ["vendor"],
    properties: { vendor: CommonParameters.vendor },
  },
  description: "Power Up - Signup process redirect with vendor",
  navFlow: NavFlow.FOCUS,
  title: "EV Power Up",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evIChargeSignupVendorRedirect",
  },
};
