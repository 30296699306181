/* eslint-disable babel/new-cap */
import { browserPushState, onPopStateCallback } from "./helpers";

export interface INavigationState {
  returnTo: string;
}

export interface IStateComplete {
  complete: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const attachOnPopStateCallback = (cb: Function): void => {
  onPopStateCallback((event: PopStateEvent) => {
    if (event.state && event.state.complete) {
      cb();
    }
  });
};

export const recordNavComplete = (): void => {
  //push state twice so we don't lose 'complete' state on back
  browserPushState({ complete: true }, "");
  browserPushState({ complete: true }, "");
};
