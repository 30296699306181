import * as React from "react";
import styled from "styled-components";

import { Link } from "./nav-config";
import { NavLink } from "./NavLinks";

export const gridAreaName = "secondary";
const AccountNavContainer = styled.div`
  grid-area: ${gridAreaName};
  /* flex gap still not supported by some of our supported browsers (I think it's IOS).
  We can use grid gap as a drop in replacement instead */
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto 1fr;
  gap: 40px;
  height: 60px;

  border-top: 1px solid ${(p) => p.theme.colors.grey200};
  ${(p) => p.theme.breakpoints.up("lg")} {
    border-top: none;
  }

  & > * {
    margin: auto 0 auto auto;
  }
`;

type Props = {
  links: Link[];
  showMenu: boolean;
};
export const SecondaryNav = ({ links, showMenu }: Props) => {
  return (
    <AccountNavContainer>
      {links.map((link) => (
        <NavLink key={link.title} link={link} tabable={showMenu} />
      ))}
    </AccountNavContainer>
  );
};
